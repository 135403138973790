import React, { useCallback } from 'react';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
import HTMLEditor from '.';
export default function useHTMLEditor() {
    const show = useCallback(({ onSubmit, onClose, ...rest }) => {
        overlayHelperViewActions.show({
            component: (React.createElement(HTMLEditor, { ...rest, onClose: () => {
                    overlayHelperViewActions.hide();
                    onClose && onClose();
                }, onSubmit: newValue => {
                    overlayHelperViewActions.hide();
                    onSubmit(newValue);
                } })),
        });
    }, []);
    useSubscription('/helper-views/html-editor/show', show);
}
export const htmlEditorHelperActions = {
    show(data) {
        SE.publish('/helper-views/html-editor/show', data);
    },
};
