const WarningMessage = ({}) => {
    // const [{theme}] = useTheme();
    return null;
    // return (
    //   <Message iconMarginTop={2} mb={theme.spacing.standard.md}>
    //     <Typography flex>
    //       Please use the Application wisely to help yourself and others
    //     </Typography>
    //   </Message>
    // );
};
export default WarningMessage;
