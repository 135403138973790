import React, { useState } from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import TopicTag from './TopicTag';
import Tag from '../Tag';
const TopicTagList = ({ topics, maxVisible, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const [showAll, setShowAll] = useState(false);
    const visibleTopics = typeof maxVisible === 'number' && topics.length > maxVisible && !showAll
        ? topics.slice(0, maxVisible)
        : topics;
    const cropCount = topics.length - visibleTopics.length;
    return (React.createElement(Row, { wrap: true, spacing: theme.spacing.standard.xxs, style: { marginBottom: theme.spacing.standard.xxs }, ...rowProps },
        visibleTopics.map(topic => (React.createElement(TopicTag, { key: topic }, topic))),
        !showAll && cropCount > 0 && (React.createElement(Tag, { onPress: () => setShowAll(true), bg: theme.palette.background.darker, textColor: theme.palette.typography.link }, `+${cropCount} more`))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default TopicTagList;
