import { Linking, Platform } from 'react-native';
import { launchCamera } from 'react-native-image-picker';
import { PERMISSIONS, request, RESULTS } from 'react-native-permissions';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { getMediaType, handleImagePickerError, handleImagePickerResponse, } from '../utils';
const useCameraPicker = () => {
    return {
        async pick(options) {
            const result = await request(Platform.select({
                ios: PERMISSIONS.IOS.CAMERA,
                android: PERMISSIONS.ANDROID.CAMERA,
            }));
            if (result !== RESULTS.GRANTED) {
                snackbarHelperActions.show({
                    variant: 'NEUTRAL',
                    message: 'Please provide permission for capturing image(s) using Camera',
                    action: {
                        title: 'SETTINGS',
                        onPress: Linking.openSettings,
                    },
                });
                return null;
            }
            const mediaType = getMediaType(options.allowedTypes);
            if (!mediaType) {
                if (__DEV__) {
                    throw new Error(`Cannot pick fileType: ${options.allowedTypes} using Camera`);
                }
                return null;
            }
            else {
                try {
                    const response = await launchCamera({
                        mediaType,
                        quality: 0.8,
                        presentationStyle: 'fullScreen',
                    });
                    return handleImagePickerResponse(response);
                }
                catch (error) {
                    return handleImagePickerError(error);
                }
            }
        },
    };
};
export default useCameraPicker;
