import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_ADD_REPLY_MUTATION = gql `
  mutation Community_addReply($data: CommunityAddReplyInput!) {
    community_addReply(data: $data) {
      id
      data {
        reply
      }
      ctime
      utime
      author {
        id
        details {
          avatarURL
          firstName
          lastName
          userName
        }
      }
    }
  }
`;
export function useCommunityAddReplyMutation(options) {
    return useGQLMutation(COMMUNITY_ADD_REPLY_MUTATION, {
        ...options,
        update(cache, { data }, { context }) {
            const { parentNodeType, parentNodeID } = context ?? {};
            if (parentNodeType && parentNodeID) {
                cache.modify({
                    id: cache.identify({
                        id: parentNodeID,
                        __typename: parentNodeType,
                    }),
                    fields: {
                        replies(existingRepliesRef) {
                            return [...existingRepliesRef, data?.community_addReply];
                        },
                    },
                });
            }
        },
    });
}
