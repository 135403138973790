import React from 'react';
import { View } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import SwipeableCard from 'src/components/10-atoms/swipeable-card/Swipeable';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import usePreIncludedOptions from '../../hooks/usePreIncludedOptions';
import FilterViewLayout from './Layout';
const FilterViewView = ({ onDelete, onPress, filterView, listType, ...filterViewLayoutProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const preIncludeOptions = usePreIncludedOptions();
    const canDelete = listType === 'my-list' && !preIncludeOptions.ids.includes(filterView.id);
    const renderAction = () => (React.createElement(React.Fragment, null,
        React.createElement(FeatherIcon, { name: "trash-2", color: theme.palette.negative.contrast }),
        React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, color: theme.palette.negative.contrast }, translate('Delete'))));
    return canDelete ? (React.createElement(SwipeableCard, { style: styleUtils.containerWidth, onCardPress: onPress, onLeftAction: canDelete ? onDelete : undefined, leftActionColor: theme.palette.negative.main, renderLeftAction: canDelete ? renderAction : undefined, equilizeActions: true },
        React.createElement(View, { style: styles.wrapper },
            React.createElement(FilterViewLayout, { type: filterView.type, listType: listType, title: filterView.title, topics: filterView.filters.tags, ...filterViewLayoutProps })))) : (React.createElement(FilterViewLayout, { type: filterView.type, listType: listType, title: filterView.title, topics: filterView.filters.tags, onPress: onPress, ...filterViewLayoutProps }));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    wrapper: { backgroundColor: theme.palette.background.main },
}));
export default FilterViewView;
