export var ToolbarAction;
(function (ToolbarAction) {
    ToolbarAction[ToolbarAction["BOLD"] = 1] = "BOLD";
    ToolbarAction[ToolbarAction["ITALIC"] = 2] = "ITALIC";
    ToolbarAction[ToolbarAction["UNORDERED_LIST"] = 3] = "UNORDERED_LIST";
    ToolbarAction[ToolbarAction["ORDERED_LIST"] = 4] = "ORDERED_LIST";
    ToolbarAction[ToolbarAction["IMAGE"] = 5] = "IMAGE";
    ToolbarAction[ToolbarAction["FORMULA"] = 6] = "FORMULA";
    ToolbarAction[ToolbarAction["STRIKE_THROUGH"] = 7] = "STRIKE_THROUGH";
})(ToolbarAction || (ToolbarAction = {}));
