import React from 'react';
import Badge from 'src/components/10-atoms/badge/Badge';
import BadgeIcon from 'src/components/10-atoms/badge/BadgeIcon';
import BadgeText from 'src/components/10-atoms/badge/BadgeText';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const NeedHelpBadge = badgeProps => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Badge, { bg: theme.palette.background.dark, ...badgeProps },
        React.createElement(BadgeIcon
        // @ts-ignore
        , { 
            // @ts-ignore
            Icon: FeatherIcon, name: "help-circle", color: theme.palette.typography.secondary }),
        React.createElement(BadgeText, { color: theme.palette.typography.secondary }, translate('Needs Help'))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default NeedHelpBadge;
