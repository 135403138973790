import { useState } from 'react';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
export default function useHiddenView() {
    const [state, setState] = useState({
        component: null,
    });
    useSubscription('helper-views/hidden-view/render', (component) => {
        setState({
            component,
        });
    });
    useSubscription('helper-views/hidden-view/clear', () => {
        setState({
            component: null,
        });
    });
    return [state];
}
export const hiddenViewHelperActions = {
    render(component) {
        SE.publish('helper-views/hidden-view/render', component);
    },
    clear() {
        SE.publish('helper-views/hidden-view/clear');
    },
};
