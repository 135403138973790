import { useCallback, useState } from 'react';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
const getInitialState = () => ({
    visible: false,
    images: [],
    initialIndex: 0,
});
export default function useImageViewer() {
    const [state, setState] = useState(getInitialState());
    const show = useCallback((imageViewerData) => {
        setState({
            ...imageViewerData,
            visible: true,
            initialIndex: 0,
        });
    }, []);
    const hide = useCallback(() => {
        setState(getInitialState());
    }, []);
    useSubscription('/helper-views/image-viewer/show', show);
    useSubscription('/helper-views/image-viewer/hide', hide);
    const actions = {
        show,
        hide,
    };
    return [state, actions];
}
export const imageViewHelperActions = {
    show(data) {
        SE.publish('/helper-views/image-viewer/show', data);
    },
    hide() {
        SE.publish('/helper-views/image-viewer/hide');
    },
};
