import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_EDIT_ANSWER_MUTATION = gql `
  mutation Community_editAnswer(
    $id: String!
    $answer: String!
    $format: String!
  ) {
    community_editAnswer(id: $id, answer: $answer, format: $format) {
      id
      data {
        format
        answer
        accepted
        votes
      }
      utime
    }
  }
`;
export function useCommunityEditAnswerMutation(options) {
    return useGQLMutation(COMMUNITY_EDIT_ANSWER_MUTATION, options);
}
