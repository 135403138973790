import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const HiddenView = ({ children }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Column, { style: styles.container }, children);
};
const stylesGenerator = createThemedStyle(({ dimensions }) => ({
    container: {
        position: 'absolute',
        top: 0,
        transform: [
            {
                translateY: dimensions.device.height * 3,
            },
        ],
        width: dimensions.viewPort.width,
        height: dimensions.viewPort.height,
    },
}));
export default HiddenView;
