// import {
//   communityTabsLinking,
//   communityStackLinking,
// } from 'src/modules/community/communityLinking';
import { examPreparationStackLinking, examPreparationTabsLinking, } from 'src/modules/exam-preparation/examPreparationLinking';
import { notificationStackLinking, notificationTabsLinking, } from 'src/modules/notification/notificationLinking';
import { reviewPipelineStackLinking } from 'src/modules/review-pipeline/reviewPipelineLinking';
import { userStackLinking, userTabsLinking } from 'src/modules/user/userLinking';
import { DLPrefix } from './constants';
import { FallbackRouteName } from '../config';
const linking = {
    prefixes: [
        DLPrefix,
        'https://app.marinesamraj.com',
        'https://marinesamraj.page.link',
    ],
    config: {
        initialRouteName: 'Tabs',
        screens: {
            Tabs: {
                screens: {
                    // ...communityTabsLinking.screens,
                    ...userTabsLinking.screens,
                    ...notificationTabsLinking.screens,
                    ...examPreparationTabsLinking.screens,
                    [FallbackRouteName]: '*',
                },
            },
            // ...communityStackLinking.screens,
            ...examPreparationStackLinking.screens,
            ...userStackLinking.screens,
            ...notificationStackLinking.screens,
            ...reviewPipelineStackLinking.screens,
        },
    },
};
export default linking;
