import React, { useEffect, useRef } from 'react';
import MoreOptionsButton from 'src/components/10-atoms/dropdown-menu/MoreOptionsButton';
import Column from 'src/components/10-atoms/layout/Column';
import ConfirmDeleteDrawer from 'src/components/20-helper-views/bottom-drawer/common-drawers/ConfirmDelete';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { useHighlightContext } from 'src/modules/community/screens/QuestionDetails/context/highlight';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { ICaseEntityType, ICaseType } from 'src/modules/ugc-policy/graphql/types';
import useUGCPolicy from 'src/modules/ugc-policy/hooks/useUGCPolicy';
import BlockedContentMessage from 'src/modules/ugc-policy/components/BlockedContentMessage';
import ReplyEditor from '../Editor';
import ReplyLayout from '../Layout';
import useData from './useData';
import useMenuItems from 'src/modules/community/screens/QuestionDetails/hooks/useMenuItems';
import isTranslatedDoc from 'src/modules/translations/utils/isTranslatedDoc';
const ReplyContainer = ({ reply, ...columnProps }) => {
    const [state, actions] = useData(reply);
    const { translate } = useI18n();
    const menuItems = useMenuItems();
    const handleDelete = () => {
        helperViewActions.bottomDrawer.show({
            component: (React.createElement(ConfirmDeleteDrawer, { title: translate('Delete Reply'), msg: translate('Are you sure you want to delete this reply ?'), onCancel: helperViewActions.bottomDrawer.hide, onDelete: () => {
                    helperViewActions.bottomDrawer.hide();
                    actions.deleteReplyMutation.submit({
                        variables: {
                            replyID: reply.id,
                        },
                    });
                } })),
        });
    };
    const handleUpdate = (updatedReply) => {
        actions.editReplyMutation.update({
            variables: {
                id: reply.id,
                reply: updatedReply,
            },
        });
    };
    const replyRef = useRef(null);
    const highlightContext = useHighlightContext();
    useEffect(() => {
        if (state.highlight && replyRef.current && highlightContext) {
            highlightContext.onHighlightRef(replyRef);
        }
    }, [state.highlight]);
    const [ugcPolicyState, ugcPolicyActions] = useUGCPolicy({
        entity: {
            id: reply.id,
            type: ICaseEntityType.COMMUNITY_REPLY,
        },
        reportAbuseType: ICaseType.COMMUNITY_ABSUIVE_CONTENT,
        entityAuthor: reply.author.id,
        contentBlockList: reply.blockedBy,
    });
    const isBlocked = ugcPolicyState.isContentBlocked || ugcPolicyState.isUserBlocked;
    return isBlocked ? (React.createElement(BlockedContentMessage, { message: ugcPolicyState.isContentBlocked
            ? translate('You have blocked this Reply')
            : translate('You have blocked the Author of this Reply'), unblockingInProgress: ugcPolicyState.unblockContent.loading ||
            ugcPolicyState.unblockUser.loading, onUnblockPress: ugcPolicyState.isContentBlocked
            ? ugcPolicyActions.unblockContent
            : ugcPolicyActions.unblockUser })) : (React.createElement(Column, { ...columnProps }, state.editMode ? (React.createElement(ReplyEditor, { variant: "edit", initialValue: reply.data.reply, onSubmit: handleUpdate, submitInProgress: state.editReplyMutation.loading, onCancel: actions.editMode.off })) : (React.createElement(ReplyLayout, { ref: replyRef, highlight: state.highlight, author: reply.author, ctime: reply.ctime, reply: reply.data.reply, isAuthor: state.isAuthor, onEdit: actions.editMode.on, onDelete: handleDelete, deleteInProgress: state.deleteReplyMutation.loading, isTranslated: isTranslatedDoc(reply.translationInfo), renderMoreOptions: () => !state.isAuthor && (React.createElement(MoreOptionsButton, { options: [
                menuItems.REPORT_ABUSE(ugcPolicyActions.reportAbuse),
                menuItems.BLOCK_CONTENT(ugcPolicyActions.blockContent),
                menuItems.BLOCK_AUTHOR(ugcPolicyActions.blockUser),
            ] })) }))));
};
export default ReplyContainer;
