import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import Quotes from 'src/components/10-atoms/Quotes';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import TutorialNavigation from '../../components/Navigation';
const WhatIsCommunity = ({ height, onSkip, onNext, isActive, style, ...columnProps }) => {
    const [{ theme, dimensions: { safeAreaInsets }, },] = useTheme(stylesGenerator);
    const animation = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        if (isActive) {
            Animated.timing(animation, {
                toValue: 1,
                duration: 500,
                delay: 500,
                easing: Easing.out(Easing.ease),
                useNativeDriver: true,
            }).start();
        }
    }, [isActive]);
    const opacity = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
    });
    const yPos = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [20, 0],
    });
    return (React.createElement(Animated.View, { style: [
            { height, paddingBottom: safeAreaInsets.bottom },
            styleUtils.deviceWidth,
            { opacity, transform: [{ translateY: yPos }] },
            style,
        ], ...columnProps },
        React.createElement(Column, { flex: true },
            React.createElement(RowCenter, { pv: theme.spacing.standard.sm },
                React.createElement(Typography, { variant: TypographyVariant.SUB_HEADING },
                    'What is ',
                    React.createElement(Typography, { variant: TypographyVariant.SUB_HEADING, color: theme.palette.primary.dark }, "Marine Community"),
                    "?")),
            React.createElement(Quotes, { author: "Helen Keller", quotation: 'Alone we can do so little,\nTogether we can do so much.' }),
            React.createElement(Typography, { style: {
                    paddingHorizontal: theme.spacing.page,
                    paddingVertical: theme.spacing.standard.sm,
                } }, 'Each Mariner possesses different skills, knowledge and experience.\nWhen combined together, they can create an aura of knowledge.\nHence Marine Community strives at establishing the connection between Mariners to solve problems and also make it available for others.')),
        React.createElement(TutorialNavigation, { isFirst: true, isLast: false, onSkip: onSkip, onPrev: () => { }, onNext: onNext, onGetStarted: () => { } })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default WhatIsCommunity;
