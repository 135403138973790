import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import RowCenter from './layout/RowCenter';
import Typography from './typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const SectionHeader = ({ children, style, ...rowCenterProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { style: [styles.container, style], ...rowCenterProps },
        React.createElement(Typography, { variant: TypographyVariant.BODY_TITLE }, children)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        backgroundColor: theme.palette.background.darker,
        paddingHorizontal: theme.spacing.page,
        minHeight: 48,
    },
}));
export default SectionHeader;
