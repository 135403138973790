import ImageResizer from '@bam.tech/react-native-image-resizer';
export async function resizeImage(path, options) {
    try {
        const result = await ImageResizer.createResizedImage(path, options.maxWidth, options.maxHeight, 'PNG', 100, 0, undefined, false, {
            mode: 'contain',
            onlyScaleDown: true,
        });
        return result;
    }
    catch (error) {
        if (__DEV__) {
            console.warn('Failed to resize the image:', error);
        }
        return null;
    }
}
