import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
const BadgeList = ({ ...rowProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { spacing: theme.spacing.standard.xxs, flex: true, wrap: true, style: styles.container, ...rowProps }));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        marginBottom: theme.spacing.standard.xxs,
    },
}));
export default BadgeList;
