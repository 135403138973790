import jwtDecode from 'jwt-decode';
export const decodeJWT = (token) => {
    try {
        const payload = jwtDecode(token);
        return payload;
    }
    catch (error) {
        if (__DEV__) {
            throw new Error(`Failed to decode JWT Token: ${token}`);
        }
        return null;
    }
};
