import React from 'react';
import { ActivityIndicator } from 'react-native';
import DeleteIcon from 'src/components/10-atoms/icons/DeleteIcon';
import EditIcon from 'src/components/10-atoms/icons/EditIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import TypographyBuilder from 'src/components/10-atoms/typography/TypographyBuilder';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import TranslatedIndicator from 'src/modules/translations/components/TranslatedIndicator';
import useDateUtils from 'src/utils/date/useDateUtils';
const ReplyLayout = React.forwardRef(({ author, ctime, reply, isAuthor, onEdit, onDelete, deleteInProgress = false, style, highlight = false, isTranslated = false, renderMoreOptions, ...colProps }, ref) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const dateUtils = useDateUtils();
    return (React.createElement(Column, { ref: ref, pv: theme.spacing.standard.xs, style: [highlight && styles.highlight, style], ...colProps },
        React.createElement(Row, { yAxis: "flex-start", spacing: theme.spacing.standard.xxs },
            isTranslated && React.createElement(TranslatedIndicator, { style: { marginTop: 2 } }),
            React.createElement(Row, { xAxis: "space-between", flex: true, spacing: theme.spacing.standard.xxs },
                React.createElement(TypographyBuilder, { flex: true, variant: TypographyVariant.CAPTION, builder: [
                        {
                            text: reply,
                        },
                        {
                            text: '—',
                            color: theme.palette.typography.secondary,
                        },
                        {
                            text: `@${isAuthor ? 'YOU' : author.details.userName}`,
                            color: theme.palette.typography.link,
                        },
                        {
                            text: '•',
                            color: theme.palette.typography.secondary,
                        },
                        {
                            text: dateUtils.getReadableDate(ctime, 'relative'),
                            color: theme.palette.typography.secondary,
                        },
                    ] }),
                renderMoreOptions())),
        isAuthor && (React.createElement(Row, { style: styles.authorActions, spacing: theme.spacing.standard.sm, xAxis: "flex-end" },
            !!onEdit && React.createElement(EditIcon, { size: theme.iconSize.sm, onPress: onEdit }),
            !!onDelete && deleteInProgress ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(DeleteIcon, { size: theme.iconSize.sm, onPress: onDelete }))))));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {},
    highlight: {
        backgroundColor: theme.palette.background.highlight,
    },
    authorActions: {
        paddingVertical: theme.spacing.standard.xxxs,
    },
}));
export default ReplyLayout;
