import React from 'react';
import { View } from 'react-native';
import FormattedDate from 'src/components/10-atoms/date/FormattedDate';
import HTMLRenderer from 'src/components/10-atoms/form/inputs/editor/html/HTMLRenderer';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import BackdropLoader from 'src/components/10-atoms/loaders/BackdropLoader';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import AnswerUtils from 'src/modules/exam-preparation/graphql/answer/utils';
import TranslatedBadge from 'src/modules/translations/components/TranslatedBadge';
import isTranslatedDoc from 'src/modules/translations/utils/isTranslatedDoc';
import AnswerContributors from './AnswerContributors';
import ImproveAnswer from './ImproveAnswer';
const AnswerItem = ({ question, updateAnswerLoading, onEdit, onShowUpdates, }) => {
    const answer = question.answer;
    const [{ theme }] = useTheme();
    const currentAnswer = AnswerUtils.getCurrentAnswer(answer);
    const answerHTML = currentAnswer?.answer ?? '';
    const isTranslated = isTranslatedDoc(answer.translationInfo);
    return (React.createElement(Column, { bbw: 1 },
        React.createElement(Row, { ph: theme.spacing.page, xAxis: "space-between" },
            isTranslated ? React.createElement(TranslatedBadge, null) : React.createElement(View, null),
            React.createElement(FormattedDate, { mode: "relative", date: currentAnswer?.ctime ?? new Date(), variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary })),
        React.createElement(HTMLRenderer, { html: answerHTML, ph: theme.spacing.page, pb: theme.spacing.standard.md }),
        React.createElement(AnswerContributors, { answer: answer, onHistoryPress: onShowUpdates }),
        React.createElement(ImproveAnswer, { answer: answer, onEdit: onEdit }),
        updateAnswerLoading && React.createElement(BackdropLoader, null)));
};
export default AnswerItem;
