import { useState } from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useEffectRange from 'src/hooks/useEffectUtils/useEffectRange';
import { useFollowQuestionMutation } from '../../graphql/question/follow';
import { useCommunityQuestionDetailsQuery } from '../../graphql/question/get';
import { useUnfollowQuestionMutation } from '../../graphql/question/unfollow';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function useData(questionID) {
    const { translate } = useI18n();
    const [questionDetailsQueryState, questionDetailsQueryActions] = useCommunityQuestionDetailsQuery({
        variables: {
            questionID,
        },
    });
    useEffectRange([1], () => {
        questionDetailsQueryActions.fetch({
            questionID,
        });
    }, [questionID]);
    const [showNewAnswerInput, setShowNewAnswerInput] = useState(false);
    const [followMutationState, followMutationActions] = useFollowQuestionMutation({
        onCompleted() {
            helperViewActions.snackbar.show({
                variant: 'POSITIVE',
                message: translate('You will be notified for updates in this Question'),
            });
        },
    });
    const [unfollowMutationState, unfollowMutationActions] = useUnfollowQuestionMutation({
        onCompleted() {
            helperViewActions.snackbar.show({
                variant: 'NEUTRAL',
                message: translate('You will not be notified for updates in this Question'),
            });
        },
    });
    const state = {
        isFollowing: questionDetailsQueryState.data?.community_questionDetails.isFollowing ??
            false,
        questionDetailsQuery: questionDetailsQueryState,
        showNewAnswerInput,
        followMutation: followMutationState,
        unfollowMutation: unfollowMutationState,
    };
    const actions = {
        questionDetailsQuery: questionDetailsQueryActions,
        setShowNewAnswerInput,
        followMutation: followMutationActions,
        unfollowMutation: unfollowMutationActions,
    };
    return [state, actions];
}
