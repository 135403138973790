import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { useCreateFilterViewMutation } from '../../module/filter-views/graphql/create';
import { useDeleteFilterViewMutation } from '../../module/filter-views/graphql/delete';
import { useUpdateFilterViewMutation } from '../../module/filter-views/graphql/update';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function useData(flow, filterView) {
    const { translate } = useI18n();
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            title: getFieldState(filterView?.title ?? ''),
            tags: getFieldState(filterView?.filters.tags ?? []),
        },
    }, {
        fieldValidations: {
            title({ value }) {
                if (value.length === 0) {
                    return translate('Please enter a valid Title');
                }
                return true;
            },
            tags({ value }) {
                if (value.length === 0) {
                    return translate('You must select atleast one Topic');
                }
                return true;
            },
        },
    });
    const [createMutationState, createMutationActions] = useCreateFilterViewMutation();
    const [updateMutationState, updateMutationActions] = useUpdateFilterViewMutation();
    const [deleteMutationState, deleteMutationActions] = useDeleteFilterViewMutation({ animateOnSuccess: true });
    const validateForm = () => {
        const hasErrors = formHelpers.hasErrors();
        if (hasErrors || !actions.form.validateForm()) {
            helperViewActions.snackbar.show({
                variant: 'NEGATIVE',
                duration: 'MEDIUM',
                message: translate('Please check the errors in the form'),
            });
            return false;
        }
        return true;
    };
    const getFormData = () => {
        return {
            id: filterView?.id,
            title: state.form.fields.title.value,
            filters: {
                tags: state.form.fields.tags.value,
            },
        };
    };
    function handleSubmit() {
        const isValid = validateForm();
        if (!isValid) {
            return;
        }
        const formData = getFormData();
        switch (flow) {
            case 'new': {
                createMutationActions.submit({
                    variables: {
                        data: formData,
                    },
                });
                break;
            }
            case 'edit': {
                updateMutationActions.update({
                    variables: {
                        data: formData,
                    },
                });
                break;
            }
            default:
                if (__DEV__) {
                    throw new Error(`handleSubmit not defined for TopicsGroupForm flow: ${flow}`);
                }
        }
    }
    const state = {
        form: formState,
        createMutation: createMutationState,
        updateMutation: updateMutationState,
        deleteMutation: deleteMutationState,
    };
    const actions = {
        form: {
            ...formActions,
            submit: handleSubmit,
        },
        deleteMutation: deleteMutationActions,
    };
    return [state, actions];
}
