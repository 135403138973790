import React from 'react';
import AnswersBadge from '../badges/AnswersBadge';
import IMOBadge from '../badges/IMOBadge';
import NeedHelpBadge from '../badges/NeedsHelp';
export const getBadgesForQuestion = (question) => {
    const badges = [];
    if (question.data.IMO) {
        badges.push(React.createElement(IMOBadge, { key: "imo", IMO: question.data.IMO }));
    }
    const answersCount = question.answers?.length ?? question.answersCount;
    if (answersCount === 0) {
        badges.push(React.createElement(NeedHelpBadge, { key: "needs_help" }));
    }
    else {
        // When a User visits QuestionDetails page, question.answers
        // will be present and we should treat that as the source of truth
        // instead of hasAcceptedAnswer because when the user changes the
        // acceptedAnswer only the cache is updated and hence using
        // the derived hasAcceptedAnswer will keep the state upto date
        const hasAcceptedAnswer = question.answers?.some(answer => answer.data.accepted) ??
            question.hasAcceptedAnswer;
        badges.push(React.createElement(AnswersBadge, { key: "answered_status", answersCount: answersCount, hasAcceptedAnswer: hasAcceptedAnswer }));
    }
    return badges;
};
