import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import MenuItem from './MenuItem';
import MenuSection from './MenuSection';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
const MenuDrawer = ({ title = 'Menu', sections }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const shouldShowSections = sections.length > 1;
    const handleMenuItemPress = (onPressCb) => {
        return () => {
            helperViewActions.bottomDrawer.hide();
            onPressCb();
        };
    };
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, { spacing: theme.spacing.itemSeparation }, sections.map((section, i) => shouldShowSections ? (React.createElement(MenuSection, { key: section.title ?? i }, section.options.map(option => (React.createElement(MenuItem, { ...option, onPress: handleMenuItemPress(option.onPress) }))))) : (section.options.map(option => (React.createElement(MenuItem, { ...option, onPress: handleMenuItemPress(option.onPress) }))))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default MenuDrawer;
