import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import config from 'react-native-ultimate-config';
import RootView from './RootView';
import setupBaseStyle from './web/baseStyle';
import initFirebase from './web/firebase';
import initSentry from './web/sentry';
initFirebase();
initSentry();
setupBaseStyle();
const App = () => {
    return (React.createElement(GoogleOAuthProvider, { clientId: config.GOOGLE_OAUTH_WEB_CLIENT_ID },
        React.createElement(RootView, null)));
};
export default App;
