import { deviceStorage } from '../storage';
import { getFTUEKey, isFTUEKey } from './utils';
const FTUEStorage = {
    UNSAFE_clearAll() {
        deviceStorage
            .getAllKeys()
            .filter(isFTUEKey)
            .forEach(ftueKey => {
            deviceStorage.delete(ftueKey);
        });
    },
    markComplete(key) {
        deviceStorage.set(getFTUEKey(key), true);
    },
    isComplete(key) {
        return deviceStorage.get(getFTUEKey(key)) ?? false;
    },
};
export default FTUEStorage;
