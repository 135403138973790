import { Linking } from 'react-native';
import { openComposer } from 'react-native-email-link';
import config from 'react-native-ultimate-config';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { convertSelfSchemeToWebScheme, getQueryString, isSelfDomain, isSelfScheme, } from './utils';
import { isWeb } from 'src/utils/platform';
import Crashlytics from 'src/lib/crashlytics';
const DeepLink = {
    async open(deeplink, { errorMsg }) {
        if (isWeb) {
            // if the deeplink is: marinesamraj://review-pipeline, then
            // convert it to https link and open it
            if (isSelfScheme(deeplink)) {
                window.location.href = convertSelfSchemeToWebScheme(deeplink);
                // if the deeplink is: https://marinesamraj.com/review-pipeline, then
                // open such links within the same tab
            }
            else if (isSelfDomain(deeplink)) {
                window.location.href = deeplink;
            }
        }
        try {
            await Linking.openURL(deeplink);
        }
        catch (error) {
            Crashlytics.error(error, 'NotificationDLOpenFailed');
            helperViewActions.snackbar.show({
                message: errorMsg,
                variant: 'NEGATIVE',
                duration: 'LONG',
            });
        }
    },
    openWhatsapp(initialText) {
        const deeplink = `whatsapp://send?${getQueryString({
            text: initialText,
            phone: config.WA_PHONE_NUMBER,
        })}`;
        return Linking.openURL(deeplink);
    },
    async openComposeMail(options = {}) {
        openComposer({
            to: options.to ?? config.CONTRIBUTION_MAIL_ID,
            body: options.body,
            subject: options.subject,
        });
    },
    async openTalkToUs(regarding) {
        try {
            await DeepLink.openWhatsapp(regarding);
        }
        catch (error) {
            DeepLink.openComposeMail({
                subject: regarding ?? 'I have a Query/Suggestion about Marine Samraj',
            });
        }
    },
};
export default DeepLink;
