import murmur from 'murmurhash-js';
import { sessionStorage } from 'src/lib/storage';
import StorageKeys from 'src/constants/storageKeys';
const extractRequestID = (config) => {
    const uniqRequestParams = {
        url: config.url,
        params: config.params,
    };
    const hash = murmur.murmur3(JSON.stringify(uniqRequestParams), 718423);
    return hash;
};
const getKey = (config) => {
    const requestID = extractRequestID(config);
    return `${StorageKeys.requestETag}/${requestID}`;
};
const getSavedResponse = (config) => {
    return sessionStorage.get(getKey(config));
};
const saveResponse = (config, response) => {
    sessionStorage.set(getKey(config), {
        etag: response.headers.etag,
        response: response.data,
    });
};
const ETagUtils = {
    getETagForRequest(config) {
        if (config.bypassETag) {
            return;
        }
        const storedRequest = getSavedResponse(config);
        return storedRequest?.etag;
    },
    getSavedResponse,
    saveResponse,
};
export default ETagUtils;
