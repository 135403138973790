import AuthHelperViewActions from 'src/modules/authentication/helperViewActions';
import { topicPickerHelperViewActions } from 'src/components/10-atoms/form/inputs/topics-input/TopicPickerOverlay/useTopicPicker';
import { bottomDrawerHelperActions } from './bottom-drawer/useBottomDrawer';
import { imageViewHelperActions } from './image-viewer/useImageViewer';
import { overlayHelperViewActions } from './overlay/useOverlay';
import { snackbarHelperActions } from './snackbar/useSnackbar';
import { htmlEditorHelperActions } from '../10-atoms/form/inputs/editor/html/HTMLEditor/useHTMLEditor';
import { promptHelperActions } from './prompt/helperActions';
import { hiddenViewHelperActions } from './hidden-view/useHiddenView';
import { playlistPlayerHelperViewActions } from 'src/lib/playlist/hooks/usePlaylistPlayer';
const helperViewActions = {
    snackbar: snackbarHelperActions,
    bottomDrawer: bottomDrawerHelperActions,
    imageViewer: imageViewHelperActions,
    auth: AuthHelperViewActions,
    htmlEditor: htmlEditorHelperActions,
    topicPicker: topicPickerHelperViewActions,
    overlay: overlayHelperViewActions,
    prompt: promptHelperActions,
    hiddenView: hiddenViewHelperActions,
    playlistPlayer: playlistPlayerHelperViewActions,
};
export default helperViewActions;
