import React from 'react';
import { ActivityIndicator } from 'react-native';
import Author from 'src/components/10-atoms/author/Author';
import Button from 'src/components/10-atoms/button/Button';
import FormattedDate from 'src/components/10-atoms/date/FormattedDate';
import Divider from 'src/components/10-atoms/Divider';
import CheckIcon from 'src/components/10-atoms/icons/CheckIcon';
import DeleteIcon from 'src/components/10-atoms/icons/DeleteIcon';
import EditIcon from 'src/components/10-atoms/icons/EditIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Votes from 'src/components/10-atoms/votes/Votes';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import AcceptedAnswerBadge from '../badges/AcceptedAnswerBadge';
import TranslatedBadge from 'src/modules/translations/components/TranslatedBadge';
import BadgeList from 'src/components/10-atoms/badge/BadgeList';
import HTMLRenderer from 'src/components/10-atoms/form/inputs/editor/html/HTMLRenderer';
const AnswerLayout = React.forwardRef(({ accepted, ctime, author, answer, votes, hasVoted, votingInProgress, onDownVote, onUpVote, isAuthor, onEdit, editInProgress = false, onDelete, deleteInProgress = false, isQuestionAuthor, acceptingInProgress, onMarkAsAccepted, onRemoveAccepted, renderMoreOptions, highlight = false, isTranslated = false, style, ...columnProps }, ref) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Column, { ref: ref, spacing: theme.spacing.standard.sm, style: [highlight && styles.highlight, style], ...columnProps },
        React.createElement(Row, { xAxis: "space-between", yAxis: "center", spacing: 24 },
            React.createElement(BadgeList, null,
                accepted && React.createElement(AcceptedAnswerBadge, null),
                isTranslated && React.createElement(TranslatedBadge, null)),
            React.createElement(Row, { yAxis: "center", xAxis: "flex-end", spacing: theme.spacing.standard.sm },
                !!ctime && (React.createElement(FormattedDate, { date: ctime, mode: "relative", variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary })),
                renderMoreOptions())),
        !!answer && React.createElement(HTMLRenderer, { html: answer }),
        React.createElement(Row, { xAxis: "space-between", yAxis: "center", spacing: theme.spacing.itemSeparation },
            author && React.createElement(Author, { flex: true, author: author }),
            typeof votes === 'number' && (React.createElement(Votes, { votes: votes, votingInProgress: votingInProgress, hasVoted: hasVoted, onUpVote: !isAuthor ? onUpVote : undefined, onDownVote: !isAuthor ? onDownVote : undefined })),
            isAuthor && (React.createElement(Row, { spacing: theme.spacing.standard.sm },
                React.createElement(Divider, { orientation: "vertical" }),
                onEdit && editInProgress ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(EditIcon, { onPress: onEdit })),
                onDelete && deleteInProgress ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(DeleteIcon, { onPress: onDelete }))))),
        isQuestionAuthor && !accepted && onMarkAsAccepted && (React.createElement(Button, { LeftIcon: () => (React.createElement(CheckIcon, { color: theme.palette.positive.contrast })), onPress: onMarkAsAccepted, colorScheme: "positive", loading: acceptingInProgress }, translate('Mark as Accepted'))),
        isQuestionAuthor && accepted && onRemoveAccepted && (React.createElement(Button, { variant: "outline", onPress: onRemoveAccepted }, translate('Remove Accepted')))));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    highlight: {
        backgroundColor: theme.palette.background.highlight,
    },
}));
export default AnswerLayout;
