import React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollView } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import { getFontWeightStyle } from 'src/components/10-atoms/typography/utils';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import extractHTMLContent from 'src/utils/html';
import styleUtils from 'src/utils/styles';
import addMathFieldWrapper from '../../common/common-utils/mathField';
import getRootStyles from '../../common/common-utils/rootStyles';
import { WEB_VARIANT } from '../../common/types';
import prettifyHtml from '../../common/utils/prettifyHtml';
import Toolbar from '../Toolbar';
import useData from './useData';
const WebHTMLEditor = ({ title, html, placeholder, onClose, onSubmit, ...columnProps }) => {
    const [{ theme, styles, deviceType }] = useTheme(stylesGenerator);
    const [state, actions] = useData();
    const handleSubmit = useCallbackWithCtx(ctx => {
        try {
            const htmlContent = extractHTMLContent(ctx.state.htmlRendererRef);
            onSubmit(prettifyHtml(htmlContent));
        }
        catch (error) {
            console.error('Failed to get HTML Editor Content:', error);
        }
    }, { state });
    return (React.createElement(Column, { flex: true, ...columnProps },
        React.createElement(OverlayLayout.FormHeader, { title: title, onSubmit: handleSubmit, onClose: onClose }),
        React.createElement(Column, { flex: true, 
            // This was a hack for web because the styles are converted to classes
            // in web, so that will be applied to all the child DOM elements
            style: [
                // @ts-ignore
                theme.typography[TypographyVariant.READER].style,
                // @ts-ignore
                getFontWeightStyle(TypographyFontWeight.REGULAR),
                // @ts-ignore
                styles.font,
            ], ...columnProps },
            React.createElement(ScrollView, { style: styleUtils.containerHeight, contentContainerStyle: [
                    styleUtils.pagePadding,
                    { paddingBottom: '50vh' },
                ] },
                React.createElement(Helmet, null,
                    React.createElement("style", null, getRootStyles(theme, deviceType, WEB_VARIANT.WEB_RENDERER))),
                React.createElement("div", { ref: state.htmlRendererRef, contentEditable: true, style: { minHeight: '100%', outline: 'none' }, placeholder: placeholder, dangerouslySetInnerHTML: {
                        __html: `${addMathFieldWrapper(html)}`,
                    } })),
            !state.mathField.isMFFocused && (React.createElement(Toolbar, { onActionPress: actions.handleActionPress })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    webview: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.main,
    },
    font: {
        color: theme.palette.typography.primary,
    },
}));
export default WebHTMLEditor;
