import React, { useEffect, useRef } from 'react';
import MoreOptionsButton from 'src/components/10-atoms/dropdown-menu/MoreOptionsButton';
import Column from 'src/components/10-atoms/layout/Column';
import ConfirmDeleteDrawer from 'src/components/20-helper-views/bottom-drawer/common-drawers/ConfirmDelete';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { useHighlightContext } from 'src/modules/community/screens/QuestionDetails/context/highlight';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { ICaseEntityType, ICaseType } from 'src/modules/ugc-policy/graphql/types';
import useUGCPolicy from 'src/modules/ugc-policy/hooks/useUGCPolicy';
import ReplyList from '../../reply/list';
import AnswerLayout from '../Layout';
import useData from './useData';
import useMenuItems from 'src/modules/community/screens/QuestionDetails/hooks/useMenuItems';
import isTranslatedDoc from 'src/modules/translations/utils/isTranslatedDoc';
import BlockedContentMessage from 'src/modules/ugc-policy/components/BlockedContentMessage';
const AnswerContainer = ({ question, answer, style, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const menuItems = useMenuItems();
    const [state, actions] = useData(question, answer);
    const handleDelete = () => {
        helperViewActions.bottomDrawer.show({
            component: (React.createElement(ConfirmDeleteDrawer, { title: translate('Delete Answer'), msg: translate('Are you sure you want to delete this answer?'), onCancel: helperViewActions.bottomDrawer.hide, onDelete: () => {
                    helperViewActions.bottomDrawer.hide();
                    actions.deleteAnswerMutation.delete({
                        variables: {
                            answerID: answer.id,
                        },
                    });
                } })),
        });
    };
    const handleMarkAsAccepted = () => {
        actions.answerAcceptMutation.update({
            variables: {
                answerID: answer.id,
            },
            context: {
                questionID: question.id,
            },
        });
    };
    const handleAnswerSubmit = (updatedAnswer) => {
        actions.editAnswerMutation.update({
            variables: {
                id: answer.id,
                answer: updatedAnswer,
                format: 'HTML_V1',
            },
        });
    };
    const showAnswerInput = () => {
        helperViewActions.htmlEditor.show({
            title: translate('Edit Answer'),
            placeholder: translate('Enter Answer'),
            html: answer.data.answer,
            onSubmit(newAnswer) {
                if (!newAnswer.trim()) {
                    helperViewActions.snackbar.show({
                        variant: 'NEGATIVE',
                        message: translate('Please enter an Answer'),
                    });
                    return;
                }
                handleAnswerSubmit(newAnswer);
            },
        });
    };
    const answerRef = useRef(null);
    const highlightContext = useHighlightContext();
    useEffect(() => {
        if (state.highlight && answerRef.current && highlightContext) {
            highlightContext.onHighlightRef(answerRef);
        }
    }, [state.highlight]);
    const [ugcPolicyState, ugcPolicyActions] = useUGCPolicy({
        entity: {
            id: answer.id,
            type: ICaseEntityType.COMMUNITY_ANSWER,
        },
        reportAbuseType: ICaseType.COMMUNITY_ABSUIVE_CONTENT,
        entityAuthor: answer.author.id,
        contentBlockList: answer.blockedBy,
    });
    const isBlocked = ugcPolicyState.isContentBlocked || ugcPolicyState.isUserBlocked;
    return isBlocked ? (React.createElement(BlockedContentMessage, { style: [styles.container, style], message: ugcPolicyState.isContentBlocked
            ? translate('You have blocked this Answer')
            : translate('You have blocked the Author of this Answer'), unblockingInProgress: ugcPolicyState.unblockContent.loading ||
            ugcPolicyState.unblockUser.loading, onUnblockPress: ugcPolicyState.isContentBlocked
            ? ugcPolicyActions.unblockContent
            : ugcPolicyActions.unblockUser })) : (React.createElement(Column, { style: [styles.container, style], ...columnProps },
        React.createElement(AnswerLayout, { ref: answerRef, highlight: state.highlight, style: styles.answer, answer: answer.data.answer, ctime: answer.ctime, utime: answer.utime, accepted: answer.data.accepted, author: answer.author, votes: answer.data.votes, votingInProgress: state.castVoteMutation.loading, hasVoted: answer.data.hasVoted, onUpVote: actions.castVoteMutation.upVote, onDownVote: actions.castVoteMutation.downVote, isAuthor: state.isAuthor, onEdit: showAnswerInput, editInProgress: state.editAnswerMutation.loading, onDelete: handleDelete, deleteInProgress: state.deleteAnswerMutation.loading, isQuestionAuthor: state.isQuestionAuthor, acceptingInProgress: state.answerAcceptMutation.loading, onMarkAsAccepted: handleMarkAsAccepted, isTranslated: isTranslatedDoc(answer.translationInfo), renderMoreOptions: () => !state.isAuthor && (React.createElement(MoreOptionsButton, { options: [
                    menuItems.REPORT_ABUSE(ugcPolicyActions.reportAbuse),
                    menuItems.BLOCK_CONTENT(ugcPolicyActions.blockContent),
                    menuItems.BLOCK_AUTHOR(ugcPolicyActions.blockUser),
                ] })) }),
        React.createElement(ReplyList, { style: styles.replyList, replies: answer.replies, context: { parentNodeType: 'CommunityAnswer', parentNodeID: answer.id } })));
};
export const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderWidth: 1,
        borderColor: theme.themeID === 'light'
            ? theme.palette.border.dark
            : theme.palette.border.main,
        borderRadius: theme.borderRadius.standard.lg,
        overflow: 'hidden',
        backgroundColor: theme.palette.background.main,
    },
    answer: {
        paddingHorizontal: theme.spacing.standard.sm,
        paddingVertical: theme.spacing.itemVerticalPadding,
    },
    replyList: {
        paddingHorizontal: theme.spacing.page,
        borderBottomLeftRadius: theme.borderRadius.standard.lg,
        borderBottomRightRadius: theme.borderRadius.standard.lg,
    },
}));
export default AnswerContainer;
