import { useMemo, useRef, useState } from 'react';
import { addEditorWrapper } from '../../common/native-web-utils';
import useRN2WebCom from '../../common/useRN2WebCom';
import useToolbarActions from './useToolbarActions';
import useActionMessageHandler from './useActionMessageHandler';
import useTheme from 'src/customizations/themes/hooks/useTheme';
export default function useData(html, placeholder) {
    const [{ theme, deviceType }] = useTheme();
    const webviewRef = useRef(null);
    const [isMFFocused, setIsMFFocused] = useState(false);
    const actionMessageHandler = useActionMessageHandler({
        onMathFieldBlurred() {
            setIsMFFocused(false);
        },
        onMathFieldFocused() {
            setIsMFFocused(true);
        },
    });
    const rn2WebCom = useRN2WebCom({
        webviewRef,
        onActionMessage: actionMessageHandler,
    });
    const handleToolbarAction = useToolbarActions({
        executeAction: rn2WebCom.sendAction,
    });
    const source = useMemo(() => ({
        html: addEditorWrapper(theme, deviceType, html, { placeholder }),
    }), [html, theme]);
    const state = {
        webviewRef,
        isMFFocused,
        source,
    };
    const actions = {
        handleToolbarAction,
        rn2WebCom,
    };
    return [state, actions];
}
