import { DevSettings } from 'react-native';
import StorageKeys from 'src/constants/storageKeys';
import Analytics from 'src/lib/analytics';
import Crashlytics from 'src/lib/crashlytics';
import { getDeviceInfo } from 'src/lib/device';
import FTUEStorage from 'src/lib/ftue/storage';
import { graphqlClient } from 'src/lib/graphql';
import { UNSAFE_clearAllStorages, sessionStorage } from 'src/lib/storage';
import isShallowEqual from 'src/utils/object/isShallowEqual';
import deviceInfoStorage from '../authentication/storage/device/storage';
import tokenStorage from '../authentication/storage/token/storage';
import userStorage from '../authentication/storage/user/storage';
import userPreferencesStorage from '../preferences/storage';
import updateUserDeviceInfo from './utils/updateDeviceInfo';
import { isWeb } from 'src/utils/platform';
const appEvents = {
    addCustomDevSettings() {
        if (__DEV__ && !isWeb) {
            DevSettings.addMenuItem('Reset FTUE', () => {
                FTUEStorage.UNSAFE_clearAll();
            });
            DevSettings.addMenuItem('Clear Storage', () => {
                UNSAFE_clearAllStorages();
            });
        }
    },
    async onBoot() {
        const token = tokenStorage.getToken();
        if (token) {
            // Logged in user
            const storedDeviceInfo = deviceInfoStorage.getDeviceInfo();
            if (!storedDeviceInfo) {
                appEvents.onLogout();
                return;
            }
            appEvents.addCustomDevSettings();
            const currentDeviceInfo = await getDeviceInfo();
            const hasChanged = !isShallowEqual(currentDeviceInfo, storedDeviceInfo);
            if (hasChanged) {
                await updateUserDeviceInfo();
            }
        }
    },
    async onLoginSuccess(data) {
        try {
            userStorage.setUserDetails(data.userInfo);
            deviceInfoStorage.setDeviceInfo(data.deviceInfo);
            tokenStorage.setToken(data.token);
            userPreferencesStorage.setPrefs(data.preferences);
            Analytics.login(data.userInfo.id, {
                email: data.userInfo.email,
            });
            Crashlytics.login(data.userInfo.id, {
                email: data.userInfo.email,
            });
            appEvents.addCustomDevSettings();
            await updateUserDeviceInfo();
        }
        catch (error) {
            console.error('failed to save user session details:', error);
        }
    },
    // Register any logout events in here
    // so that it will called when a User is logged out
    onLogout() {
        Analytics.logout();
        graphqlClient.clearStore();
        sessionStorage.delete(StorageKeys.userAuthToken);
        sessionStorage.clearAll();
    },
};
export default appEvents;
