import FTUEKeys from 'src/lib/ftue/keys';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import { useCommunityCastVoteMutation } from 'src/modules/community/graphql/common/castVote';
import { CommunityVoteType } from 'src/modules/community/graphql/common/castVote/types';
import QuestionVotingGuidelinesDrawer from 'src/modules/community/tutorials/guidelines/QuestionVotingGuidelines';
import { showGuidelinesDrawer } from 'src/modules/community/tutorials/utils';
export default function useData(question) {
    const [currUser] = useUserStorage();
    const [castVoteMutationState, castVoteMutationActions] = useCommunityCastVoteMutation();
    const state = {
        isAuthor: currUser?.id === question.author.id,
        castVoteMutation: castVoteMutationState,
    };
    const actions = {
        castVoteMutation: {
            async upVote() {
                await showGuidelinesDrawer(QuestionVotingGuidelinesDrawer, FTUEKeys.COMMUNITY_QUESTION_VOTING_GUIDELINES);
                castVoteMutationActions.submit({
                    variables: {
                        nodeID: question.id,
                        type: CommunityVoteType.UP,
                    },
                    context: {
                        parentNodeID: question.id,
                        parentNodeType: 'CommunityQuestion',
                    },
                });
            },
            async downVote() {
                await showGuidelinesDrawer(QuestionVotingGuidelinesDrawer, FTUEKeys.COMMUNITY_QUESTION_VOTING_GUIDELINES);
                castVoteMutationActions.submit({
                    variables: {
                        nodeID: question.id,
                        type: CommunityVoteType.DOWN,
                    },
                    context: {
                        parentNodeID: question.id,
                        parentNodeType: 'CommunityQuestion',
                    },
                });
            },
        },
    };
    return [state, actions];
}
