import { useAddRecommendedFilterView } from '../../graphql/add';
import { useDeleteFilterViewMutation } from '../../graphql/delete';
export default function useData() {
    const [deleteMutationState, deleteMutationActions] = useDeleteFilterViewMutation({ animateOnSuccess: true });
    const [addMutationState, addMutationActions] = useAddRecommendedFilterView({
        animateOnSuccess: true,
    });
    const state = {
        addMutation: addMutationState,
        deleteMutation: deleteMutationState,
    };
    const actions = {
        addMutation: addMutationActions,
        deleteMutation: deleteMutationActions,
    };
    return [state, actions];
}
