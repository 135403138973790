import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import WarningMessage from '../components/WarningMessage';
import useI18n from 'src/modules/translations/hooks/useI18n';
const QuestionGuidelinesDrawer = ({ onConfirm, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Question Guidelines')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(ULItem, null, translate('Elaborate your Question clearly')),
            React.createElement(ULItem, null, translate('Add relevant images (if needed)')),
            React.createElement(ULItem, null, translate('Add all the Topics that this Question covers.\nFor ex: You can add "Main Engine" Topic for a Question related to Main Engine.\nThis helps us identify Mariners that would be interested in your Question')),
            React.createElement(ULItem, null, translate('Since this is a Public forum, please be gentle, respectful and avoid using abusive words & content'))),
        React.createElement(WarningMessage, null),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { enableAfter: 3, flex: true, onPress: onConfirm }, translate('I Agree')))));
};
export default QuestionGuidelinesDrawer;
