import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ScrollView } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import SectionHeader from 'src/components/10-atoms/SectionHeader';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QuestionContainer from '../../components/question/container';
import { questionDetailsPageAnalytics } from '../../screens/QuestionDetails';
import { IQuestionVariant } from '../QuestionList/types';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const SimilarQuestionList = ({ tags, exclude, }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const navigation = useNavigation();
    const [state] = useData(tags, exclude);
    const handleQuestionPress = (question) => {
        questionDetailsPageAnalytics
            .setWidget('SimilarQuestionList')
            .setTarget('Question')
            .click();
        navigation.push('Community_QuestionDetails', {
            questionID: question.id,
        });
    };
    return (state.similarQuestionListQuery.data?.list.length ?? 0) > 0 ? (React.createElement(Column, null,
        React.createElement(SectionHeader, null, translate('SIMILAR QUESTIONS')),
        React.createElement(ScrollView, { horizontal: true, showsHorizontalScrollIndicator: false, contentContainerStyle: styles.container },
            React.createElement(Row, null, state.similarQuestionListQuery.data?.list.map(question => (React.createElement(QuestionContainer, { key: question.id, pressableStyle: styles.question, variant: IQuestionVariant.SUGGESTION_LIST_ITEM, question: question, onPress: () => handleQuestionPress(question) }))))))) : null;
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingVertical: theme.spacing.itemVerticalPadding,
        paddingHorizontal: theme.spacing.page,
    },
    suggestSearch: {
        width: 128,
        maxWidth: 128,
        padding: theme.spacing.standard.sm,
    },
    question: {
        borderWidth: 1,
        alignSelf: 'stretch',
        borderColor: theme.palette.border.main,
        borderRadius: theme.borderRadius.standard.lg,
        paddingHorizontal: theme.spacing.page,
        paddingVertical: theme.spacing.itemVerticalPadding,
        width: theme.viewPort.width -
            theme.spacing.page -
            theme.spacing.itemSeparation -
            (theme.spacing.page + 10),
        marginRight: theme.spacing.itemSeparation,
    },
}));
export default SimilarQuestionList;
