function readFromBlob(blob) {
    return new Promise(resolve => {
        var reader = new FileReader();
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.readAsText(blob);
    });
}
export async function getHTMLContentFromClipboard() {
    const clipboardItems = await navigator.clipboard.read();
    let html = '';
    for (const item of clipboardItems) {
        for (const type of item.types) {
            if (type === 'text/html') {
                const blob = await item.getType(type);
                // console.log(await readFromBlob(blob));
                html += await readFromBlob(blob);
            }
        }
    }
    return html;
}
