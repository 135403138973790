import React, { useCallback } from 'react';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
import TopicPickerOverlay from '.';
export default function useTopicPicker() {
    const show = useCallback(({ onChange, ...rest }) => {
        overlayHelperViewActions.show({
            component: (React.createElement(TopicPickerOverlay, { ...rest, onClose: overlayHelperViewActions.hide, onChange: newTopics => {
                    overlayHelperViewActions.hide();
                    onChange(newTopics);
                } })),
        });
    }, []);
    useSubscription('/helper-views/topic-picker/show', show);
}
export const topicPickerHelperViewActions = {
    show(data) {
        SE.publish('/helper-views/topic-picker/show', data);
    },
};
