import StorageKeys from 'src/constants/storageKeys';
import { deviceStorage } from 'src/lib/storage';
import { DEFAULT_STATE } from './constants';
export const ttsPreferencesStorage = {
    set(state) {
        deviceStorage.set(StorageKeys.ttsPreferences, state);
    },
    get() {
        return deviceStorage.get(StorageKeys.ttsPreferences) || DEFAULT_STATE;
    },
    setProp(prop, value) {
        const state = ttsPreferencesStorage.get();
        ttsPreferencesStorage.set({
            ...state,
            [prop]: value,
        });
    },
    getProp(prop) {
        const state = ttsPreferencesStorage.get();
        return state[prop];
    },
    setVoiceId(voiceId) {
        ttsPreferencesStorage.setProp('voiceId', voiceId);
    },
    getVoiceId() {
        return ttsPreferencesStorage.getProp('voiceId');
    },
    setRate(rate) {
        ttsPreferencesStorage.setProp('rate', rate);
    },
    getRate() {
        return ttsPreferencesStorage.getProp('rate');
    },
};
