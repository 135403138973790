import { WEB_VARIANT } from '../types';
import { isIOS, isWeb } from 'src/utils/platform';
export default function getRootStyles(theme, deviceType, variant) {
    return `
  ${![WEB_VARIANT.WEB_RENDERER].includes(variant)
        ? `
    html, body {
      padding: 0;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      background-color: ${theme.palette.background.main};
      color: ${theme.palette.typography.primary};
      width: 100%;
      overflow-x: hidden;
      ${variant === WEB_VARIANT.RENDERER ? 'overflow-y: hidden;' : ''}
    }
    `
        : ''}

  ${!isWeb
        ? `body {
    --keycap-height: 24px;
    --keycap-font-size: 20px;
    --keycap-shift-font-size: 9px;
    --keycap-small-font-size: 9px;
    --keycap-extra-small-font-size: 9px;
    --keyboard-toolbar-font-size: 16px;
    --keycap-gap: 4px;
    ${variant === WEB_VARIANT.RENDERER
            ? `padding-bottom: ${theme.spacing.standard.md}px;`
            : 'padding-bottom: 96px'};
  }`
        : ''}

/* Diff Styles */
ins {
  background-color: ${theme.palette.background.positive};
}

ins, del {
  display: block;
  text-decoration: none;
  padding: 0 ${theme.spacing.standard.xs}px; 
}

ins {
  padding-bottom: 12px;
}

del {
  background-color: ${theme.palette.background.negative};
}

.d2h-diff-table {
  font-size: ${isIOS ? '7px' : '14px'};
  line-height: ${isIOS ? '14px' : '24px'};
  letter-spacing: 0.5px;
}

img {
  display:block; 
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: ${theme.borderRadius.standard.md}px;
  margin: 12px 0;
  background-color: ${theme.palette.background.dark}
}

.image-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: ${theme.palette.background.dark};
  border-radius: ${theme.borderRadius.standard.md}px;
}

#editor {
  outline: none;
  padding: ${theme.spacing.standard.sm}px ${theme.spacing.page}px;
}

math-field:not(:defined) {
  display: none;
}
math-field::part(menu-toggle) {
  display: none;
}

math-field {
  color: ${theme.palette.typography.primary};
  background: ${theme.palette.background.dark};
  border-color: ${theme.palette.border.dark};
  border-radius: ${theme.borderRadius.standard.sm}px;
  --contains-highlight-background-color: ${theme.palette.background.darker};
}

math-field:focus {
  background: ${theme.palette.background.main};
}

span.ML__latex {
  font-size: 17px;
  letter-spacing: 0.8px;
}

span[data-latex] {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 36px;
  margin: 4px 0;
  align-items: center;
}

.math-expr {
  position: relative;
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  margin-right: 8px;
}

.math-expr .math-expr-close {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 16px;
  background-color: ${theme.palette.background.darker};
  color: ${theme.palette.typography.secondary};
  font-size: 12px;
  line-height: 14px;
}



.math-expr-bkup {
  display:block; 
  padding:8px 12px; 
  border-radius: 4px; 
  background-color: ${theme.palette.background.dark};
}

#editor[placeholder]:empty::before, #editor[placeholder]:empty:focus::before { 
  content: attr(placeholder); 
  color: ${theme.palette.typography.placeholder};
  display: block;
}

`;
}
