import React from 'react';
import useTopicPicker from '../10-atoms/form/inputs/topics-input/TopicPickerOverlay/useTopicPicker';
import BottomDrawer from './bottom-drawer/BottomDrawer';
import useBottomDrawer from './bottom-drawer/useBottomDrawer';
import helperViewActions from './helperViewActions';
import ImageViewer from './image-viewer';
import useImageViewer from './image-viewer/useImageViewer';
import OverlayHelperView from './overlay';
import useOverlay from './overlay/useOverlay';
import Snackbar from './snackbar/Snackbar';
import useSnackbar from './snackbar/useSnackbar';
import useHTMLEditor from '../10-atoms/form/inputs/editor/html/HTMLEditor/useHTMLEditor';
import HiddenView from './hidden-view';
import useHiddenView from './hidden-view/useHiddenView';
import PlaylistController from 'src/lib/playlist/components/PlaylistController';
import usePlaylistPlayer from 'src/lib/playlist/hooks/usePlaylistPlayer';
const HelperViews = ({}) => {
    const [snackbarState] = useSnackbar();
    const [bottomDrawerState] = useBottomDrawer();
    const [imageViewerState] = useImageViewer();
    const [overlayState] = useOverlay();
    const [hiddenViewState] = useHiddenView();
    const [playlistPlayerState, playlistPlayerActions, playlistPlayerHelpers] = usePlaylistPlayer();
    useTopicPicker();
    useHTMLEditor();
    return (React.createElement(React.Fragment, null,
        React.createElement(HiddenView, null, hiddenViewState.component),
        React.createElement(PlaylistController, { state: playlistPlayerState.player.state, mode: playlistPlayerState.mode, onModeChange: playlistPlayerActions.setMode, title: playlistPlayerState.player.item?.playerInput?.title, prevDisabled: !playlistPlayerHelpers.playlist.hasPrev(), onPrev: playlistPlayerActions.playlist.gotoPrev, nextDisabled: !playlistPlayerHelpers.playlist.hasNext(), onNext: playlistPlayerActions.playlist.gotoNext, onPause: playlistPlayerActions.player.pause, onResume: playlistPlayerActions.player.resume, onStop: playlistPlayerActions.player.stop, openDisabled: playlistPlayerHelpers.player.isOpenDisabled(), onOpen: playlistPlayerActions.player.openItem }),
        React.createElement(OverlayHelperView, { ...overlayState }),
        React.createElement(BottomDrawer, { ...bottomDrawerState, onClose: helperViewActions.bottomDrawer.hide }),
        React.createElement(Snackbar, { ...snackbarState }),
        React.createElement(ImageViewer, { ...imageViewerState, onClose: helperViewActions.imageViewer.hide })));
};
export default HelperViews;
