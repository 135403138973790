import { useMemo } from 'react';
import { Dimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isAndroid } from 'src/utils/platform';
const deviceWidth = Dimensions.get('window').width;
const deviceHeight = isAndroid
    ? require('react-native-extra-dimensions-android').get('REAL_WINDOW_HEIGHT')
    : Dimensions.get('window').height;
export default function useDimensions() {
    const safeAreaInsets = useSafeAreaInsets();
    const dimensions = useMemo(() => ({
        device: {
            width: deviceWidth,
            height: deviceHeight,
        },
        viewPort: {
            height: deviceHeight,
            width: Math.min(deviceWidth, 768),
        },
        safeAreaInsets,
    }), [safeAreaInsets]);
    return [dimensions];
}
