import useTabs from 'src/components/10-atoms/tabs/useTabs';
import StorageKeys from 'src/constants/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
import isNot from 'src/utils/array/isNot';
import { useExamLevelDetailsQuery } from '../../graphql/exam-level/getDetails';
import { useCommunityDetailsQuery } from '../../graphql/community/getDetails';
import { useRPPendingCountQuery } from 'src/modules/review-pipeline/graphql/pendingCount';
import { ReviewEntityType } from 'src/modules/review-pipeline/graphql/types';
import useAuthToken from 'src/modules/authentication/storage/token/hook';
export default function useData() {
    const [selectedExamLevel, setSelectedExamLevel] = usePersistedState('session', StorageKeys.epSelectedExamLevel);
    const { translate } = useI18n();
    const [communityDetailsQueryState, communityDetailsQueryActions] = useCommunityDetailsQuery({
        lazy: true,
    });
    useEffectWithCtx(ctx => {
        if (communityDetailsQueryState.data) {
            if (communityDetailsQueryState.data.ep_communityDetails.id ===
                ctx.selectedExamLevel?.community.id) {
                ctx.setSelectedExamLevel({
                    ...ctx.selectedExamLevel,
                    community: communityDetailsQueryState.data.ep_communityDetails,
                });
            }
        }
    }, { selectedExamLevel, setSelectedExamLevel }, [communityDetailsQueryState.data]);
    const [examLevelDetailsQueryState, examLevelDetailsQueryActions] = useExamLevelDetailsQuery({
        lazy: true,
    });
    useEffectWithCtx(ctx => {
        if (examLevelDetailsQueryState.data) {
            if (examLevelDetailsQueryState.data.ep_examLevelDetails.id ===
                ctx.selectedExamLevel?.examLevel?.id) {
                ctx.setSelectedExamLevel({
                    ...ctx.selectedExamLevel,
                    examLevel: examLevelDetailsQueryState.data.ep_examLevelDetails,
                });
            }
        }
    }, { selectedExamLevel, setSelectedExamLevel }, [examLevelDetailsQueryState.data]);
    useEffectWithCtx(ctx => {
        if (selectedExamLevel?.community &&
            selectedExamLevel.community.id !==
                communityDetailsQueryState.data?.ep_communityDetails.id) {
            ctx.communityDetailsQueryActions.fetch({
                communityID: selectedExamLevel.community.id,
            });
        }
    }, { communityDetailsQueryActions }, [selectedExamLevel?.community, communityDetailsQueryState.data]);
    useEffectWithCtx(ctx => {
        if (selectedExamLevel?.examLevel &&
            selectedExamLevel.examLevel.id !==
                examLevelDetailsQueryState.data?.ep_examLevelDetails.id) {
            ctx.examLevelDetailsQueryActions.fetch({
                examLevelID: selectedExamLevel.examLevel.id,
            });
        }
    }, { examLevelDetailsQueryActions }, [selectedExamLevel?.examLevel, examLevelDetailsQueryState.data]);
    const [tabsState, tabsActions] = useTabs();
    useEffectWithCtx(ctx => {
        if (selectedExamLevel?.examLevel) {
            const config = selectedExamLevel?.examLevel.config;
            const shouldShowWritten = config?.EXAM_LEVEL?.written?.enable ?? true;
            const shouldShowOrals = config?.EXAM_LEVEL?.orals?.enable ?? false;
            ctx.tabsActions.setTabs([
                shouldShowWritten && {
                    id: 'WRITTEN',
                    label: translate('Written'),
                },
                shouldShowOrals && {
                    id: 'ORALS',
                    label: translate('Orals'),
                },
            ].filter(isNot(false)));
            ctx.tabsActions.setActiveIndex(0);
        }
    }, { tabsActions }, [selectedExamLevel]);
    const [, , authTokenHelpers] = useAuthToken();
    const isSuperAdmin = authTokenHelpers.getIsSuperAdmin();
    const [reviewPendingCountQueryState, reviewPendingCountQueryActions] = useRPPendingCountQuery({
        skip: !isSuperAdmin,
        variables: {
            types: [
                ReviewEntityType.EP_ORALS_ANSWER,
                ReviewEntityType.EP_WRITTEN_ANSWER,
            ],
        },
        pollInterval: 5 * 60000,
    });
    const state = {
        isSuperAdmin,
        reviewPendingCountQuery: reviewPendingCountQueryState,
        selectedExamLevel,
        tabs: tabsState,
    };
    const actions = {
        reviewPendingCountQuery: reviewPendingCountQueryActions,
        setSelectedExamLevel,
        tabs: tabsActions,
    };
    return [state, actions];
}
