import { useMemo } from 'react';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function usePreIncludedOptions() {
    const { translate } = useI18n();
    return useMemo(() => {
        const allOption = {
            id: 'ALL',
            label: translate('All'),
        };
        const myQeustionOption = {
            id: 'AUTHORED',
            label: translate('My Questions'),
        };
        return {
            options: {
                all: allOption,
                myQuestion: myQeustionOption,
            },
            ids: [allOption.id, myQeustionOption.id],
        };
    }, []);
}
