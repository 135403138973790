import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_CREATE_QUESTION = gql `
  mutation Community_createQuestion($data: CommunityCreateQuestionInput!) {
    community_createQuestion(data: $data) {
      id
    }
  }
`;
export function useCommunityCreateQuestionMutation(options) {
    return useGQLMutation(COMMUNITY_CREATE_QUESTION, options);
}
