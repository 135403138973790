import { useRef } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
export default function useCaretPosition(containerRef) {
    const caretPosRef = useRef();
    useEffectWithCtx(() => {
        const container = containerRef.current;
        if (!container) {
            return;
        }
        container.addEventListener('focusout', () => {
            if (window.getSelection) {
                // IE9 and non-IE
                const selection = window.getSelection();
                if (selection?.getRangeAt && selection?.rangeCount) {
                    caretPosRef.current = selection.getRangeAt(0);
                }
            }
        });
    }, {}, []);
    const focusAtLastCaretPos = useCallbackWithCtx(() => {
        if (!containerRef.current) {
            return;
        }
        containerRef.current.focus();
        if (window.getSelection) {
            if (caretPosRef.current) {
                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(caretPosRef.current);
            }
        }
    }, {});
    return [caretPosRef, { focusAtLastCaretPos }];
}
