export function addMathFieldListeners(mathExprNode, events = {}) {
    // Close button listeners
    mathExprNode
        .getElementsByClassName('math-expr-close')[0]
        .addEventListener('click', function () {
        mathExprNode.parentElement?.remove();
    });
    // MathField listeners
    const mf = mathExprNode.getElementsByTagName('math-field')[0];
    mf.mathModeSpace = '\\:';
    mf.keybindings = [];
    mf.inlineShortcuts = [];
    mf.addEventListener('focus', () => {
        events.onFocus?.();
    });
    mf.addEventListener('blur', () => {
        events.onBlur?.();
    });
    mf.addEventListener('keydown', (ev) => {
        switch (ev.key) {
            case 'Backspace': {
                ev.preventDefault();
                mf.executeCommand(['deleteBackward']);
                break;
            }
            case 'ArrowLeft': {
                ev.preventDefault();
                mf.executeCommand(['moveToPreviousChar']);
                break;
            }
            case 'ArrowRight': {
                ev.preventDefault();
                mf.executeCommand(['moveToNextChar']);
                break;
            }
            case 'ArrowDown': {
                ev.preventDefault();
                mf.executeCommand(['moveDown']);
                break;
            }
            case 'ArrowUp': {
                ev.preventDefault();
                mf.executeCommand(['moveUp']);
                break;
            }
        }
    }, { capture: true });
}
