import { diffLines } from 'diff';
import prettifyHtml from '../common/utils/prettifyHtml';
// import sanitizeHTML from 'sanitize-html';
// function removeUnwantedTags(html: string) {
//   return sanitizeHTML(html, {
//     allowedTags: ['b', 'i', 'em', 'strong', 'a', 'img', 'video'],
//   });
// }
function diff2HTML(diff, options) {
    const { showOnlyDiff } = options;
    return diff.reduce((result, item) => {
        // const content = removeUnwantedTags(item.value);
        const content = item.value;
        if (item.added) {
            result += `<ins>${content}</ins>`;
        }
        else if (item.removed) {
            result += `<del>${content}</del>`;
        }
        else if (!showOnlyDiff) {
            result += item.value;
        }
        return result;
    }, '');
}
export function getDiffHTML(oldHTML, newHTML, options = {}) {
    const { showOnlyDiff = true } = options;
    const diff = diffLines(prettifyHtml(oldHTML), prettifyHtml(newHTML));
    return prettifyHtml(diff2HTML(diff, {
        showOnlyDiff,
    }));
}
