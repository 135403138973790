import useFilePicker from 'src/components/10-atoms/file-picker/hooks/useFilePicker';
import { FileType } from 'src/components/10-atoms/file-picker/types';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useSeq from 'src/hooks/useSeq';
import uploadFile from 'src/lib/file-storage/uploadFile';
import delay from 'src/utils/delay';
import { isExternalLink } from 'src/utils/url';
import useCaretPosition from './useCaretPosition';
export default function useUploadImageHandler(htmlRendererRef) {
    const [, caretPosActions] = useCaretPosition(htmlRendererRef);
    const [{ theme }] = useTheme();
    const filePicker = useFilePicker();
    const getId = useSeq();
    const upload = useCallbackWithCtx(async (ctx) => {
        const files = await ctx.filePicker.pick({
            allowedTypes: FileType.IMAGES,
        });
        await delay(700);
        caretPosActions.focusAtLastCaretPos();
        if (files && files[0]) {
            const [pickedFile] = files;
            const isExternal = isExternalLink(pickedFile.uri);
            if (isExternal) {
                document.execCommand('insertImage', false, pickedFile.uri);
                document.execCommand('insertHTML', false, '<br />');
            }
            else {
                const imageLoaderId = String(getId());
                document.execCommand('insertHTML', false, `
          <div class="image-loader" id="${imageLoaderId}">
            <svg viewbox="0 0 64 64" width="64" height="64">
              <circle cx="28" cy="28" r="24" fill="none" stroke="${theme.palette.background.darker}" stroke-width="4px" />
              <circle cx="28" cy="28" r="24" fill="none" stroke="${theme.palette.primary.main}" stroke-width="4px" stroke-linecap="round" stroke-dasharray="0 150.7964473723" stroke-dashoffset="37.6991118431" />
            </svg>
          </div>
        `);
                const progressContainer = document.getElementById(imageLoaderId);
                if (!progressContainer) {
                    return;
                }
                try {
                    const response = await uploadFile(pickedFile, {
                        onProgress(percentage) {
                            const circumference = 150.7964473723;
                            const cover = circumference * (percentage / 100);
                            const gap = circumference - cover;
                            const progressCircle = progressContainer.getElementsByTagName('circle')[1];
                            progressCircle.setAttribute('stroke-dasharray', `${cover} ${gap}`);
                        },
                    });
                    if (response?.data?.publicURL) {
                        const imgSrc = response.data.publicURL;
                        const imageContainer = document.createElement('div');
                        const img = document.createElement('img');
                        img.src = imgSrc;
                        imageContainer.append(img);
                        progressContainer.replaceWith(imageContainer);
                        document.execCommand('insertHTML', false, '<br />');
                    }
                    else {
                        progressContainer.remove();
                        snackbarHelperActions.show({
                            message: 'Failed to upload image',
                            variant: 'NEGATIVE',
                        });
                    }
                }
                catch (error) {
                    progressContainer.remove();
                    snackbarHelperActions.show({
                        message: 'Failed to upload image',
                        variant: 'NEGATIVE',
                    });
                }
            }
        }
    }, { filePicker });
    return [{}, { upload }];
}
