import React, { useEffect, useRef } from 'react';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import CustomFlatList from 'src/components/10-atoms/list-views/CustomFlatList';
import isDeepEqual from 'src/utils/object/isDeepEqual';
import styleUtils from 'src/utils/styles';
import useListState from './useListState';
import { renderNamedSlot } from 'src/utils/component';
function GQLList({ ListEmptyComponent, LoaderComponent, ErrorComponent, renderItem, field, queryState, queryActions, variables, style, ...listProps }) {
    const prevVariables = useRef();
    useEffect(() => {
        if (!isDeepEqual(variables, prevVariables.current)) {
            queryActions.fetch(variables);
            prevVariables.current = variables;
        }
    }, [variables]);
    const handleRetry = () => {
        queryActions.fetch(variables);
    };
    const handleRefresh = () => {
        queryActions.fetch(variables);
    };
    const renderListItem = ({ item, ...props }) => {
        switch (item.type) {
            case 'list_loading':
                return renderNamedSlot(LoaderComponent);
            case 'list_empty':
                return renderNamedSlot(ListEmptyComponent);
            case 'list_error':
                return renderNamedSlot(ErrorComponent || (React.createElement(GenericServerError, { error: queryState.error, onRetry: handleRetry })));
            case 'list_data':
                return renderItem ? renderItem({ item: item.data, ...props }) : null;
            default:
                return null;
        }
    };
    const [listState] = useListState(queryState, field);
    return (React.createElement(CustomFlatList, { initialRenderDelay: 50, data: listState, style: [styleUtils.containerHeight, style], keyExtractor: item => item.type !== 'list_data'
            ? item.type
            : item.data.id, renderItem: renderListItem, refreshing: queryState.refetching, onRefresh: handleRefresh, ...listProps }));
}
export default GQLList;
