import React, { useEffect } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import Form from 'src/components/10-atoms/form/Form';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import TopicInput from 'src/components/10-atoms/form/inputs/topics-input';
import DeleteIcon from 'src/components/10-atoms/icons/DeleteIcon';
import Message from 'src/components/10-atoms/info/Message';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import ConfirmDeleteDrawer from 'src/components/20-helper-views/bottom-drawer/common-drawers/ConfirmDelete';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { IFilterViewType, } from '../../module/filter-views/graphql/types';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const TopicsGroupForm = ({ navigation, route }) => {
    const { flow = 'new', filterView } = route.params ?? {};
    const [{ dimensions }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData(flow, filterView);
    useEffect(() => {
        if (!state.createMutation.loading) {
            if (state.createMutation.data) {
                helperViewActions.snackbar.show({
                    variant: 'POSITIVE',
                    duration: 'SHORT',
                    message: translate('Topics Group has been created successfully'),
                });
                navigation.goBack();
            }
        }
    }, [state.createMutation.data, state.createMutation.loading]);
    useEffect(() => {
        if (!state.updateMutation.loading) {
            if (state.updateMutation.data) {
                helperViewActions.snackbar.show({
                    variant: 'POSITIVE',
                    duration: 'SHORT',
                    message: translate('Topics Group has been updated successfully'),
                });
                navigation.goBack();
            }
        }
    }, [state.updateMutation.data, state.updateMutation.loading]);
    useEffect(() => {
        if (state.deleteMutation.data) {
            navigation.goBack();
        }
    }, [state.deleteMutation.data]);
    const isNewFlow = flow === 'new';
    const isFormDisabled = filterView && filterView.type !== IFilterViewType.USER_DEFINED;
    const handleDelete = () => {
        if (filterView) {
            helperViewActions.bottomDrawer.show({
                component: (React.createElement(ConfirmDeleteDrawer, { title: translate('Delete Group'), msg: translate('Are you sure you want to delete "%title" Group ?', {
                        title: filterView.title,
                    }), onCancel: helperViewActions.bottomDrawer.hide, onDelete: () => {
                        helperViewActions.bottomDrawer.hide();
                        actions.deleteMutation.delete({
                            variables: {
                                filterViewID: filterView.id,
                            },
                        });
                    } })),
            });
        }
    };
    return (React.createElement(Page, null,
        React.createElement(CustomScrollView, { renderCollapsibleHeader: () => (React.createElement(PageNavbar, { title: isNewFlow ? translate('New Group') : translate('Edit Group'), backType: "back", onBackPress: navigation.goBack, actions: isNewFlow
                    ? []
                    : [
                        {
                            Icon: DeleteIcon,
                            title: translate('Delete'),
                            onPress: handleDelete,
                        },
                    ] })), collapsibleHeaderHeight: NAVBAR_HEIGHT, contentContainerStyle: {
                paddingBottom: dimensions.safeAreaInsets.bottom,
            }, enableAutomaticScroll: true },
            React.createElement(Column, null,
                isFormDisabled && (React.createElement(Message, null, translate('Cannot edit a Recommended Group'))),
                React.createElement(Form, null,
                    React.createElement(Field, { label: translate('Title'), error: state.form.fields.title.errors[0]?.message },
                        React.createElement(TextInput, { disabled: isFormDisabled, value: state.form.fields.title.value, onChange: actions.form.fields.title.setValue, placeholder: translate('Engineering, Navigation etc') })),
                    React.createElement(Field, { label: translate('Topics'), error: state.form.fields.tags.errors[0]?.message },
                        React.createElement(TopicInput, { disabled: isFormDisabled, org: "ms-community", entityID: "ms-community", placeholder: translate('Click to add Topics for the Group'), maxSelectedTopics: 20, value: state.form.fields.tags.value, onChange: actions.form.fields.tags.setValue })),
                    React.createElement(Button, { disabled: isFormDisabled, loading: state.createMutation.loading || state.updateMutation.loading, colorScheme: isNewFlow ? 'positive' : 'normal', onPress: actions.form.submit }, isNewFlow
                        ? translate('Create Group')
                        : translate('Update Group')))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default TopicsGroupForm;
