import React from 'react';
import { ScrollView } from 'react-native';
import Button from 'src/components/10-atoms/button/Button';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from '../BottomDrawerLayout';
const ConfirmDeleteDrawer = ({ title, msg, onDelete, onCancel, }) => {
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(ScrollView, null,
            React.createElement(BottomDrawerLayout.Body, null,
                React.createElement(Typography, null, msg))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { onPress: onCancel, variant: "text" }, "Cancel"),
            React.createElement(Button, { onPress: onDelete, colorScheme: "negative" }, "Delete"))));
};
export default ConfirmDeleteDrawer;
