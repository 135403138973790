import StorageKeys from 'src/constants/storageKeys';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
const fallbackColors = {
    eng: '#ffae004f',
    nav: '#2697ed4f',
    reg: '#32a8524f',
};
export default function useCategoryColor() {
    const [{ theme }] = useTheme();
    const [state, setState] = usePersistedState('device', StorageKeys.communityCategoriesColor, {});
    const actions = {
        getColor(prefix) {
            return (state[prefix] ??
                fallbackColors[prefix] ??
                theme.palette.primary.lightest);
        },
        setColor(prefix, color) {
            setState({
                ...state,
                [prefix]: color,
            });
        },
    };
    return actions;
}
