import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const UPDATE_FILTER_VIEW_MUTATION = gql `
  mutation Community_updateFilterView($data: CommunityEditFilterViewInput!) {
    community_updateFilterView(data: $data) {
      id
      title
      filters {
        tags
      }
      ctime
      scope
      type
    }
  }
`;
export function useUpdateFilterViewMutation(options) {
    return useGQLMutation(UPDATE_FILTER_VIEW_MUTATION, options);
}
