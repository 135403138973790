import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import PlaylistIntroDrawer from '../components/PlaylistIntroDrawer';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
export default function usePlaylistIntro(options) {
    const [isFTUE, ftueActions] = useFTUE(FTUEKeys.PLAYLIST_INTRODUCTION);
    useEffectWithCtx(ctx => {
        if (isFTUE) {
            helperViewActions.bottomDrawer.show({
                component: (React.createElement(PlaylistIntroDrawer, { onCancel: () => {
                        helperViewActions.bottomDrawer.hide();
                        ftueActions.markComplete();
                        ctx.options.onCancel();
                    }, onGetStarted: () => {
                        helperViewActions.bottomDrawer.hide();
                        ftueActions.markComplete();
                        ctx.options.onGetStarted();
                    } })),
            });
        }
    }, { options }, []);
}
