import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from './layout/Column';
import Typography from './typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const Quotes = ({ quotation, author, ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { bg: theme.palette.background.dark, ph: theme.spacing.page, pv: theme.spacing.standard.sm, spacing: theme.spacing.standard.xxs, ...columnProps },
        React.createElement(Typography, null, quotation),
        React.createElement(Typography, { variant: TypographyVariant.CAPTION, style: styles.author, color: theme.palette.typography.secondary },
            "- ",
            author)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    author: { fontStyle: 'italic', alignSelf: 'flex-end' },
}));
export default Quotes;
