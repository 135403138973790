import { sessionStorage } from 'src/lib/storage';
import { DEVICE_INFO_STORAGE_KEY } from './constants';
const deviceInfoStorage = {
    setDeviceInfo(deviceInfo) {
        sessionStorage.set(DEVICE_INFO_STORAGE_KEY, deviceInfo);
    },
    getDeviceInfo() {
        return sessionStorage.get(DEVICE_INFO_STORAGE_KEY);
    },
};
export default deviceInfoStorage;
