import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useI18n from 'src/modules/translations/hooks/useI18n';
const QuestionCreateSuccessDrawer = ({ onDismiss, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Body, { spacing: 16, xAxis: "center" },
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, translate('Successfully submitted your Question!')),
            React.createElement(FitImage, { source: {
                    uri: AppAssets.sendToCommunty,
                } }),
            React.createElement(Typography, { style: { textAlign: 'center' } }, translate('Sit back and relax! We’ll notify you once your Question is answered by the Community'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { style: { flex: 1 }, onPress: onDismiss }, translate('OK')))));
};
export default QuestionCreateSuccessDrawer;
