import { GoogleSignin } from '@react-native-google-signin/google-signin';
import React from 'react';
import { Image } from 'react-native';
import config from 'react-native-ultimate-config';
import Column from 'src/components/10-atoms/layout/Column';
import ColumnCenter from 'src/components/10-atoms/layout/ColumnCenter';
import Page from 'src/components/templates/page/Page.template';
import palette from 'src/customizations/themes/10-parts/color-schemes/palette';
import ThemeProvider from 'src/customizations/themes/context/ThemeProvider';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { isIOS, isWeb } from 'src/utils/platform';
import AppleSigninBtn from '../../components/AppleSinginBtn';
import GoogleSigninBtn from '../../components/GoogleSiginBtn';
import GuestSigninBtn from '../../components/GuestSigninBtn';
import SigninTnC from '../../components/SigninTnC';
import useAppleSignin from '../../hooks/useAppleSignin';
import useGoogleSignin from '../../hooks/useGoogleSignin';
import useGuestSignin from '../../hooks/useGuestSignin';
GoogleSignin.configure({
    webClientId: config.GOOGLE_OAUTH_WEB_CLIENT_ID,
    offlineAccess: true,
});
const bootSplashLogo = isWeb
    ? require('src/assets/images/bootsplash_logo/bootsplash_logo')
    : require('src/assets/images/bootsplash_logo/bootsplash_logo.png');
const LoginPage = ({}) => {
    const [{ styles, theme, deviceType }] = useTheme(stylesGenerator);
    const [googleSigninState, googleSigninActions] = useGoogleSignin();
    const [appleSigninState, appleSigninActions] = useAppleSignin();
    const [guestSigninState, guestSigninActions] = useGuestSignin();
    return (React.createElement(ThemeProvider, { theme: "light" },
        React.createElement(Page, { xAxis: "center", yAxis: "center", sab: true },
            React.createElement(Column, { flex: true, bwd: !deviceType.isPhone ? 1 : 0, brd: theme.borderRadius.standard.md, ph: theme.spacing.page, pv: theme.spacing.standard.md, mv: theme.spacing.standard.md, style: !deviceType.isPhone && { maxHeight: 700 } },
                React.createElement(ColumnCenter, { flex: true },
                    React.createElement(Image, { resizeMode: "cover", source: isWeb ? bootSplashLogo.default : bootSplashLogo, style: { minWidth: 250, minHeight: 250 } })),
                React.createElement(Column, { style: styles.footer, spacing: theme.spacing.standard.md },
                    React.createElement(SigninTnC, null),
                    React.createElement(GuestSigninBtn, { loading: guestSigninState.guestSigninAPI.loading, onPress: guestSigninActions.continueAsGuest }),
                    isIOS && (React.createElement(AppleSigninBtn, { loading: appleSigninState.appleSigninAPI.loading, onPress: appleSigninActions.signinWithApple })),
                    React.createElement(GoogleSigninBtn, { loading: googleSigninState.googleSigninAPI.loading, onPress: googleSigninActions.signinWithGoogle }))))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {},
    footer: {
        alignSelf: 'stretch',
        paddingBottom: theme.spacing.standard.md,
    },
    page: {},
    appleLoginBtn: {
        backgroundColor: palette.grey[900],
    },
}));
export default LoginPage;
