import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import WarningMessage from '../components/WarningMessage';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AnswerGuidelinesDrawer = ({ onConfirm, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Answer Guidelines')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(ULItem, null, translate("Read Other's Answers (if present) and if none works, then Add your Answer")),
            React.createElement(ULItem, null, translate('Elaborate your Answer')),
            React.createElement(ULItem, null, translate('Add relevant images (if needed) for easier understanding')),
            React.createElement(ULItem, null, translate("DO NOT ask doubts about the Question in here, instead use Question's Reply section to do so.")),
            React.createElement(ULItem, null, translate('Since this is a Public forum, please be gentle, respectful and avoid using abusive words & content'))),
        React.createElement(WarningMessage, null),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { enableAfter: 3, flex: true, onPress: onConfirm }, translate('I Agree')))));
};
export default AnswerGuidelinesDrawer;
