import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AnswerVotingGuidelinesDrawer = ({ onConfirm, }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Answer Voting Guidelines')),
        React.createElement(BottomDrawerLayout.Body, { spacing: theme.spacing.itemSeparation },
            React.createElement(Column, null,
                React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, translate('Like the Answer if:')),
                React.createElement(ULItem, null, translate('This Answer addresses the Question correctly')),
                React.createElement(ULItem, null, translate('This Answer solved your Problem')),
                React.createElement(ULItem, null, translate('Author has explained the Answer very well'))),
            React.createElement(Column, null,
                React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, translate('Dislike the Answer if:')),
                React.createElement(ULItem, null, translate('This Answer is irrelevant to the Question')))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { enableAfter: 3, flex: true, onPress: onConfirm }, translate('I Agree')))));
};
export default AnswerVotingGuidelinesDrawer;
