import { useNavigation } from '@react-navigation/native';
import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import { IQuestionVariant } from 'src/modules/community/containers/QuestionList/types';
import { questionDetailsPageAnalytics } from 'src/modules/community/screens/QuestionDetails';
import { QuestionFormFlow } from 'src/modules/community/screens/QuestionForm';
import styleUtils from 'src/utils/styles';
import ReplyList from '../../reply/list';
import QuestionLayout from '../Layout';
import { getBadgesForQuestion } from '../utils';
import useData from './useData';
import isTranslatedDoc from 'src/modules/translations/utils/isTranslatedDoc';
const QuestionContainer = withPress(({ variant, question, style, ...columnProps }) => {
    const navigation = useNavigation();
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(question);
    const handleEdit = () => {
        questionDetailsPageAnalytics
            .setWidget('Question')
            .setTarget('Edit')
            .click();
        navigation.navigate('Community_QuestionForm', {
            flow: QuestionFormFlow.EDIT,
            question,
        });
    };
    return (React.createElement(Column, { style: style, ...columnProps }, (() => {
        switch (variant) {
            case IQuestionVariant.LIST_ITEM:
            case IQuestionVariant.SEARCH_LIST_ITEM:
                return (React.createElement(QuestionLayout, { style: styles.listItem, badges: getBadgesForQuestion(question), ctime: question.ctime, title: question.data.title, shortDescription: question.data.shortDescription, tags: question.data.tags, author: question.author, votes: question.data.votes, isTranslated: isTranslatedDoc(question.translationInfo) }));
            case IQuestionVariant.SUGGESTION_LIST_ITEM:
                return (React.createElement(QuestionLayout, { badges: getBadgesForQuestion(question), ctime: question.ctime, title: question.data.title, shortDescription: question.data.shortDescription, tags: question.data.tags, author: question.author, votes: question.data.votes, isTranslated: isTranslatedDoc(question.translationInfo) }));
            case IQuestionVariant.DETAILS:
                return (React.createElement(Column, { spacing: theme.spacing.standard.xs },
                    React.createElement(QuestionLayout, { style: styles.details, badges: getBadgesForQuestion(question), ctime: question.ctime, title: question.data.title, description: question.data.description, tags: question.data.tags, author: question.author, votes: question.data.votes, hasVoted: question.data.hasVoted, votingInProgress: state.castVoteMutation.loading, onUpVote: actions.castVoteMutation.upVote, onDownVote: actions.castVoteMutation.downVote, isAuthor: state.isAuthor, onEdit: handleEdit, isTranslated: isTranslatedDoc(question.translationInfo) }),
                    React.createElement(ReplyList, { replies: question.replies, context: {
                            parentNodeType: 'CommunityQuestion',
                            parentNodeID: question.id,
                        } })));
            default:
                if (__DEV__) {
                    throw new Error(`Component hasn't been defined for Question Variant: ${variant}`);
                }
                return null;
        }
    })()));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    listItem: {
        paddingHorizontal: theme.spacing.page,
        paddingVertical: theme.spacing.itemVerticalPadding,
    },
    details: {
        ...styleUtils.pagePadding,
    },
}));
export default QuestionContainer;
