import StorageKeys from 'src/constants/storageKeys';
import { deviceStorage } from 'src/lib/storage';
import { getDefaultDebugSettingsState } from './utils';
const debugSettingsStorage = {
    getSettings() {
        return (deviceStorage.get(StorageKeys.debugSettings) ??
            getDefaultDebugSettingsState());
    },
    setSettings(settings) {
        deviceStorage.set(StorageKeys.debugSettings, settings);
    },
};
export default debugSettingsStorage;
