import React, { useImperativeHandle } from 'react';
import WebView from 'react-native-webview';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useData from './useData';
import { WEB_HANDLER } from '../../common/types';
import { ActivityIndicator } from 'react-native';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
const NativeHTMLRenderer = React.forwardRef(({ html, onRenderComplete, variant, style, ...columnProps }, ref) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(html, { variant }, {
        onRenderComplete,
    });
    useImperativeHandle(ref, () => ({
        async getTTSContent() {
            const ttsContent = await actions.rn2WebCom.sendAction(WEB_HANDLER.EXTRACT_TTS_CONTENT, {});
            return ttsContent;
        },
    }), [actions.rn2WebCom.sendAction]);
    return (React.createElement(Column, { style: [
            {
                height: state.height === -1 ? undefined : state.height,
            },
            style,
        ], ...columnProps },
        React.createElement(WebView, { ref: state.webviewRef, style: styles.webview, source: state.source, originWhitelist: ['*'], scalesPageToFit: true, startInLoadingState: true, renderLoading: () => (React.createElement(RowCenter, { pv: theme.spacing.standard.xl },
                React.createElement(ActivityIndicator, null))), onMessage: actions.rn2WebCom.handleMessage })));
});
const stylesGenerator = createThemedStyle(({}) => ({
    webview: {
        width: '100%',
        height: '100%',
    },
}));
export default NativeHTMLRenderer;
