import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { FilterViewsConfig } from '../config';
import { IFilterViewScope } from '../graphql/types';
import useI18n from 'src/modules/translations/hooks/useI18n';
const CreateNewGroupBtn = ({ myList, ...btnProps }) => {
    const { translate } = useI18n();
    const navigation = useNavigation();
    const handlePress = useCallback(() => {
        const authoredFilterView = myList?.filter(filterView => filterView.scope === IFilterViewScope.PRIVATE) ?? [];
        if (authoredFilterView.length >= FilterViewsConfig.MAX_FILTER_VIEWS_PER_USER) {
            helperViewActions.snackbar.show({
                variant: 'NEGATIVE',
                message: translate('You cannot create more than %maxLimit Groups', {
                    maxLimit: FilterViewsConfig.MAX_FILTER_VIEWS_PER_USER,
                }),
            });
            return;
        }
        navigation.navigate('Community_TopicsGroupForm', {
            flow: 'new',
        });
    }, [myList]);
    return (React.createElement(Button, { variant: "float", onPress: handlePress, ...btnProps }, translate('Create New Group')));
};
export default CreateNewGroupBtn;
