import React from 'react';
import { FLOATING_BTN_BOTTOM_OFFSET } from 'src/components/10-atoms/button/Button';
import Divider from 'src/components/10-atoms/Divider';
import Column from 'src/components/10-atoms/layout/Column';
import EmptyList from 'src/components/templates/EmptyList.template';
import AppAssets from 'src/constants/appAssets';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import FilterViewLoader from '../../components/FilterView/Loader';
import CreateNewGroupBtn from '../CreateNewGroupBtn';
import FilterViewContainer from '../FilterView';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const variables = {};
const RecommendedList = ({ ...gqlPaginatedListProps }) => {
    const [state, actions] = useData();
    const { translate } = useI18n();
    return (React.createElement(Column, { flex: true },
        React.createElement(GQLPaginatedList, { queryState: state.recommendedListQuery, queryActions: actions.recommendedistQuery, variables: variables, ItemSeparatorComponent: Divider, contentContainerStyle: {
                paddingBottom: FLOATING_BTN_BOTTOM_OFFSET,
            }, renderItem: ({ item }) => (React.createElement(FilterViewContainer, { filterView: item, myList: state.myListQuery.data?.community_getAllMyFilterViews, listType: "recommendation" })), LoaderComponent: React.createElement(FilterViewLoader, null), ListEmptyComponent: React.createElement(EmptyList, { image: {
                    type: 'image',
                    source: {
                        uri: AppAssets.emptyRecommendations,
                    },
                }, message: translate('No Recommendations found right now\nPlease check later on') }), ...gqlPaginatedListProps }),
        React.createElement(CreateNewGroupBtn, { myList: state.myListQuery.data?.community_getAllMyFilterViews })));
};
export default RecommendedList;
