import React from 'react';
import Badge from 'src/components/10-atoms/badge/Badge';
import BadgeIcon from 'src/components/10-atoms/badge/BadgeIcon';
import BadgeText from 'src/components/10-atoms/badge/BadgeText';
import BoatIcon from 'src/components/10-atoms/icons/BoatIcon';
import { withOpacity } from 'src/customizations/themes/10-parts/color-schemes/utils';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const IMOBadge = ({ IMO, ...badgeProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Badge, { bg: withOpacity(theme.palette.primary.darker, 1), ...badgeProps },
        React.createElement(BadgeIcon, { Icon: BoatIcon, color: theme.palette.primary.contrast }),
        React.createElement(BadgeText, { color: theme.palette.primary.contrast }, IMO)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default IMOBadge;
