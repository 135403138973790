import { useAllMyFilterViewsQuery } from '../../graphql/allMyList';
export default function useData() {
    const [listQueryState, listQueryActions] = useAllMyFilterViewsQuery({
        fetchPolicy: 'cache-first',
    });
    const state = {
        listQuery: listQueryState,
    };
    const actions = {
        listQuery: listQueryActions,
    };
    return [state, actions];
}
