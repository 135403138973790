import React from 'react';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import UserRegisterDrawer from './container/UserRegisterDrawer';
import userStorage from './storage/user/storage';
import { isRegisteredUser } from './storage/user/utils';
const AuthHelperViewActions = {
    async getRegisteredUser(title) {
        const currUserDetails = userStorage.getUserDetails();
        if (isRegisteredUser(currUserDetails)) {
            return currUserDetails;
        }
        return new Promise((resolve, reject) => {
            const handleCancel = () => {
                bottomDrawerHelperActions.hide();
                const err = new Error('User Registration cancelled');
                err.name = 'USER_REGISTRATION_CANCELLED';
                reject(err);
            };
            const handleSuccess = () => {
                bottomDrawerHelperActions.hide();
                const userDetails = userStorage.getUserDetails();
                resolve(userDetails);
            };
            bottomDrawerHelperActions.show({
                component: (React.createElement(UserRegisterDrawer, { title: title, onCancel: handleCancel, onSuccess: handleSuccess })),
            });
        });
    },
};
export default AuthHelperViewActions;
