export function getWrappedMathField(options = {}) {
    return `<div class="math-expr-container" contenteditable="false">
    <div ${options.containerId ? `id="${options.containerId}"` : ''} contenteditable="false" class="math-expr">
      <button class="math-expr-close" contenteditable="false">x</button>
      <math-field style="font-size: 1.2em;" smart-mode>${options.expr ?? ''}</math-field>
    </div>
  </div>`;
}
export default function addMathFieldWrapper(html) {
    return html.replace(/\${1,2}([^$]+)\${1,2}/g, (matchedStr, captured, matchIndex) => {
        const hasMoreContent = html.length > matchIndex + matchedStr.length;
        return getWrappedMathField({
            expr: captured,
            addLineBreak: !hasMoreContent,
        });
    });
}
export function formatMathExpr(html) {
    // return html;
    return html.replace(/\${1,2}([^$]+)\${1,2}/g, (matched, captured) => `$$${captured}$$`);
}
