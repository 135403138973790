export const PERMISSIONS = {
    IOS: {
        PHOTO_LIBRARY: 1,
        CAMERA: 2,
    },
    ANDROID: {
        CAMERA: 1,
    },
};
export const request = () => { };
export const RESULTS = {
    GRANTED: 1,
};
