import addMathFieldWrapper, { formatMathExpr } from '../common-utils/mathField';
import getRootStyles from '../common-utils/rootStyles';
import getRootScript from './rootScript';
import { HTMLRendererVariant, WEB_VARIANT } from '../types';
function getFontLinks() {
    return `
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap" rel="stylesheet">
    `;
}
function getMathLiveScripts() {
    return '<script defer src="https://assets.marinesamraj.com/app-assets/webview/html-renderer_editor/mathlive.min.js"></script>';
}
function getMathLiveRenderScript() {
    return `
    <script>
      window.addEventListener('DOMContentLoaded', 
        () => MathLive.renderMathInDocument()
      );
    </script>
  `;
}
export function addRendererWrapper(theme, deviceType, content, variant = HTMLRendererVariant.READING) {
    return `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
    ${getFontLinks()}
    ${getMathLiveScripts()}
    ${getMathLiveRenderScript()}
    <style>
    ${getRootStyles(theme, deviceType, WEB_VARIANT.RENDERER)}
    </style>
  </head>
  <body>
    <div id="renderer">
      ${variant === HTMLRendererVariant.TTS ? addMathFieldWrapper(content) : formatMathExpr(content)}
    </div>
    ${getRootScript(theme)}
  </body>
</html>
`;
}
export function addEditorWrapper(theme, deviceType, content, options) {
    return `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
    ${getFontLinks()}
    <style>
    ${getRootStyles(theme, deviceType, WEB_VARIANT.EDITOR)}
    </style>
  </head>
  <body>
    <div contenteditable="true" id="editor" autofocus placeholder="${options.placeholder}">
      ${addMathFieldWrapper(content)}
    </div>
    ${getMathLiveScripts()}
    ${getRootScript(theme)}
  </body>
</html>
`;
}
