import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import Tab from 'src/components/10-atoms/tabs/Tab';
import TabHeader from 'src/components/10-atoms/tabs/TabHeader';
import Tabs from 'src/components/10-atoms/tabs/Tabs';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import MyList from '../../module/filter-views/containers/MyList';
import RecommendedList from '../../module/filter-views/containers/RecommendedList';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const TopicsGroups = ({ navigation }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    const renderHeader = () => (React.createElement(Column, null,
        React.createElement(PageNavbar, { title: translate('Groups'), backType: "back", onBackPress: navigation.goBack }),
        React.createElement(Column, { flex: true },
            React.createElement(TabHeader, { ph: theme.spacing.page, ...state.tabs, style: styles.header, onChange: actions.tabs.setActiveIndex }))));
    return (React.createElement(Page, null,
        React.createElement(Tabs, { activeTab: state.tabs.tabs[state.tabs.activeIndex].id },
            React.createElement(Tab, { id: "MY_LIST" },
                React.createElement(MyList, { renderCollapsibleHeader: renderHeader, collapsibleHeaderHeight: NAVBAR_HEIGHT })),
            React.createElement(Tab, { id: "RECOMMENDED" },
                React.createElement(RecommendedList, { renderCollapsibleHeader: renderHeader, collapsibleHeaderHeight: NAVBAR_HEIGHT })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    header: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border.main,
    },
}));
export default TopicsGroups;
