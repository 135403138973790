import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { ALL_MY_FILTER_VIEWS_QUERY } from './allMyList';
import { IFilterViewScope } from './types';
const ADD_RECOMMENDED_FILTER_VIEW_MUTATION = gql `
  mutation Community_addFilterView($filterViewID: String!) {
    community_addFilterView(filterViewID: $filterViewID)
  }
`;
export function useAddRecommendedFilterView(options) {
    return useGQLMutation(ADD_RECOMMENDED_FILTER_VIEW_MUTATION, {
        ...options,
        update(cache, {}, { context }) {
            if (context?.filterView) {
                cache.updateQuery({ query: ALL_MY_FILTER_VIEWS_QUERY }, (data) => {
                    const { community_getAllMyFilterViews: list } = data ?? {
                        community_getAllMyFilterViews: [],
                    };
                    return {
                        ...data,
                        community_getAllMyFilterViews: [
                            ...list,
                            {
                                // For some reason, scope wasn't present in the filterView passed,
                                // hence adding it manually
                                // @ts-ignore
                                scope: IFilterViewScope.PUBLIC,
                                ...context.filterView,
                            },
                        ],
                    };
                });
            }
        },
    });
}
