import StorageKeys from 'src/constants/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import { decodeJWT } from './utils';
import { IUserRole } from '../../apis/signin/types';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
export default function useAuthToken() {
    const [state, setState] = usePersistedState('session', StorageKeys.userAuthToken);
    const getRoles = useCallbackWithCtx(ctx => {
        if (!ctx.state) {
            return [IUserRole.GUEST];
        }
        const payload = decodeJWT(ctx.state);
        return payload?.roles ?? [IUserRole.GUEST];
    }, { state });
    const getIsSuperAdmin = useCallbackWithCtx(() => getRoles().includes(IUserRole.SUPER_ADMIN), {});
    const helpers = {
        getRoles,
        getIsSuperAdmin,
    };
    return [state, setState, helpers];
}
