import { useEffect } from 'react';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleSigninWebAPI } from '../apis/signin';
import { getDeviceInfo } from 'src/lib/device';
export default function useGoogleSignin(events = {}) {
    const { translate } = useI18n();
    const [googleSigninAPIState, googleSigninAPIActions] = useGoogleSigninWebAPI(events);
    const handleGoogleLogin = useGoogleLogin({
        flow: 'implicit',
        async onSuccess(tokenResponse) {
            googleSigninAPIActions.submit(tokenResponse.access_token, await getDeviceInfo());
        },
        onError(errorResponse) {
            console.log('Login Failed:', errorResponse);
            snackbarHelperActions.show({
                message: errorResponse?.error_description ||
                    translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        },
    });
    useEffect(() => {
        if (googleSigninAPIState.error) {
            snackbarHelperActions.show({
                message: googleSigninAPIState.error.code === 'ERR_BAD_RESPONSE'
                    ? translate('Network connection failure. Please try later')
                    : googleSigninAPIState.error.message ||
                        translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        }
    }, [googleSigninAPIState.error]);
    const state = {
        googleSigninAPI: googleSigninAPIState,
    };
    const actions = {
        signinWithGoogle: handleGoogleLogin,
    };
    return [state, actions];
}
