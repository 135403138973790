import React from 'react';
import { StyleSheet } from 'react-native';
import ChevronIcon from './ChevronIcon';
const ChevronRightIcon = ({ style, ...rest }) => {
    return React.createElement(ChevronIcon, { style: [styles.container, style], ...rest });
};
const styles = StyleSheet.create({
    container: {
        transform: [
            {
                rotateZ: '180deg',
            },
        ],
    },
});
export default ChevronRightIcon;
