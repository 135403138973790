import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_DELETE_ANSWER_MUTATION = gql `
  mutation Community_deleteAnswer($answerID: String!) {
    community_deleteAnswer(answerID: $answerID)
  }
`;
export function useCommunityDeleteAnswerMutation(options) {
    return useGQLMutation(COMMUNITY_DELETE_ANSWER_MUTATION, {
        ...options,
        update(cache, {}, { variables }) {
            cache.evict({
                id: cache.identify({
                    id: variables?.answerID,
                    __typename: 'CommunityAnswer',
                }),
            });
            cache.gc();
        },
    });
}
