import React, { useMemo } from 'react';
import { ActivityIndicator } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import ShareIcon from 'src/components/10-atoms/icons/ShareIcon';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function useMenuItems() {
    const { translate } = useI18n();
    return useMemo(() => ({
        NOTIFY_ME: (onPress, options) => ({
            title: translate('Turn on notification for this Question'),
            Icon: props => options.followInProgress ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(FeatherIcon, { name: "bell-off", ...props, size: options.theme.iconSize.sm, color: options.theme.palette.icon.primary })),
            description: '',
            onPress,
        }),
        STOP_NOTIFICATIONS: (onPress, options) => ({
            title: translate('Stop notifications for this Question'),
            Icon: props => options.followInProgress ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(FeatherIcon, { name: "bell", ...props, size: options.theme.iconSize.sm, color: options.theme.palette.primary.dark })),
            description: '',
            onPress,
        }),
        SHARE: (onPress, options) => ({
            title: translate('Share'),
            Icon: props => (React.createElement(ShareIcon, { ...props, size: options.theme.iconSize.sm })),
            description: translate('Share this Question with people who can Answer'),
            onPress,
        }),
        REPORT_ABUSE: (onPress) => ({
            Icon: props => React.createElement(FeatherIcon, { name: "slash", ...props }),
            title: translate('Report Abuse'),
            description: translate("I'm concerned with this content"),
            onPress,
        }),
        BLOCK_CONTENT: (onPress) => ({
            Icon: props => React.createElement(FeatherIcon, { name: "eye-off", ...props }),
            title: translate('Hide Content'),
            description: translate("We'll not show this content"),
            onPress,
        }),
        BLOCK_AUTHOR: (onPress) => ({
            Icon: props => React.createElement(FeatherIcon, { name: "user-x", ...props }),
            title: translate('Block Author'),
            description: translate("You'll not see any content by this Author anymore"),
            onPress,
        }),
    }), []);
}
