import dynamicLinks from '@react-native-firebase/dynamic-links';
import config from 'react-native-ultimate-config';
// Doc: https://rnfirebase.io/reference/dynamic-links#buildShortLink
export default async function buildLink(deeplinkPath) {
    const link = await dynamicLinks().buildShortLink({
        link: `https://marinesamraj.page.link/${deeplinkPath}`,
        domainUriPrefix: 'https://marinesamraj.page.link',
        android: {
            packageName: config.BUNDLE_ID,
        },
        ios: {
            bundleId: config.BUNDLE_ID,
            appStoreId: '1625331440',
        },
        // optional setup which updates Firebase analytics campaign
        // "banner". This also needs setting up before hand
        // analytics: {
        //   campaign: 'banner',
        // },
    });
    return link;
}
