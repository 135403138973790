import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useEffectRange from 'src/hooks/useEffectUtils/useEffectRange';
import { graphqlClient } from 'src/lib/graphql';
import useUserPreferences from 'src/modules/preferences/storage/hook';
import SelectAvailableLanguageDrawer from './SelectAvailableLanguageDrawer';
import LangChangeSuccessDrawer from '../../components/LangChangeSuccessDrawer';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import RequestNewLanguageDrawer from './RequestNewLanguageDrawer';
import useData from './useData';
import RequestNewLangSuccessDrawer from '../../components/RequestNewLangSuccessDrawer';
const SelectLanguage = ({}) => {
    const [userPrefs, userPrefsActions] = useUserPreferences();
    const [, actions] = useData();
    useEffectRange([1], () => {
        graphqlClient.resetStore();
    }, [userPrefs.lang]);
    const handleDidntFindAnyPress = useCallbackWithCtx(() => {
        helperViewActions.bottomDrawer.show({
            component: (React.createElement(RequestNewLanguageDrawer, { onItemPress: lang => {
                    actions.requestNewLangAPI.submit(lang.code);
                    helperViewActions.bottomDrawer.show({
                        component: (React.createElement(RequestNewLangSuccessDrawer, { lang: lang, onDismiss: helperViewActions.bottomDrawer.hide })),
                    });
                } })),
        });
    }, {});
    return (React.createElement(SelectAvailableLanguageDrawer, { value: userPrefs.lang, onChange: newLang => {
            userPrefsActions.setLang(newLang);
            helperViewActions.bottomDrawer.show({
                component: (React.createElement(LangChangeSuccessDrawer, { onConfirm: helperViewActions.bottomDrawer.hide })),
            });
        }, onDidntFindAnyPress: handleDidntFindAnyPress }));
};
export default SelectLanguage;
