import { useCallback, useRef, useState } from 'react';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
import { getDuration, snackbarIdGenerator } from './utils';
const DUMMY_ACTION = {
    title: '',
    onPress() { },
};
function addDefaultValues(data) {
    return {
        variant: 'NEUTRAL',
        action: DUMMY_ACTION,
        ...data,
    };
}
export default function useSnackbar() {
    const [state, setState] = useState({
        id: 0,
        visible: false,
        variant: 'NEUTRAL',
        message: '',
        action: DUMMY_ACTION,
    });
    const timer = useRef();
    const show = useCallback((id, snackbarData) => {
        clearTimeout(timer.current);
        setState({
            id,
            ...addDefaultValues(snackbarData),
            visible: true,
        });
        timer.current = setTimeout(() => {
            setState(prevState => {
                return {
                    ...prevState,
                    visible: false,
                };
            });
        }, getDuration(snackbarData.message, snackbarData.duration || 'SHORT'));
    }, []);
    useSubscription('/helper-views/snackbar/show', useCallback((snackbarData) => {
        const mySnackbarId = snackbarIdGenerator.getNew();
        show(mySnackbarId, snackbarData);
    }, [show]));
    return [state];
}
export const snackbarHelperActions = {
    show(data) {
        SE.publish('/helper-views/snackbar/show', data);
    },
};
