import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
export function useCommunityQuestionListQuery(query) {
    return useGQLPaginatedQuery(query, 'community_questionList', {
        variables: {
            searchFilter: {
                onlyUnanswered: false,
                tags: [],
                searchTerm: '',
                exclude: [],
            },
            pagination: {
                offset: 0,
                limit: 10,
            },
        },
    });
}
addTypePolicies({
    Query: {
        fields: {
            community_questionList: paginatedListFieldPolicy([
                'searchFilter',
            ]),
        },
    },
});
