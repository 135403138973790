import React from 'react';
import APIHandler from 'src/components/10-atoms/APIHandler';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import SelectInputItem from 'src/components/10-atoms/form/inputs/select-input-group/SelectInputItem';
import CustomFlatList from 'src/components/10-atoms/list-views/CustomFlatList';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import useI18n from '../../../hooks/useI18n';
import SelectLanguageLoader from './Loader';
import useData from './useData';
import Button from 'src/components/10-atoms/button/Button';
import Row from 'src/components/10-atoms/layout/Row';
import Column from 'src/components/10-atoms/layout/Column';
const SelectAvailableLanguageDrawer = ({ value, onChange, onDidntFindAnyPress, }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    return (React.createElement(BottomDrawerLayout, { yAxis: "flex-start", style: styleUtils.containerHeight },
        React.createElement(BottomDrawerLayout.Title, null, translate('Select from Available Languages')),
        (state.listAPI.data?.totalCount ?? 0) > 10 && (React.createElement(SearchInput, { style: styles.searchBar, autoFocus: true, autoCorrect: false, autoCapitalize: "none", spellCheck: false, value: state.search.value, onChange: actions.search.change, onClear: () => actions.search.change('') })),
        React.createElement(APIHandler, { requestState: state.listAPI, LoaderComponent: SelectLanguageLoader, renderData: () => (React.createElement(Column, { flex: true },
                React.createElement(CustomFlatList, { containerStyle: styles.listContainer, data: state.languages, renderItem: ({ item }) => (React.createElement(SelectInputItem, { isSelected: value === item.code, title: item.label, onPress: () => {
                            helperViewActions.bottomDrawer.hide();
                            onChange(item.code);
                        } })) }),
                React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.sm },
                    React.createElement(Button, { flex: true, variant: "outline", onPress: onDidntFindAnyPress }, translate("Didn't find any?"))))), renderError: () => (React.createElement(GenericServerError, { onRetry: actions.listAPI.refresh })) })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    searchBar: {
        marginHorizontal: theme.spacing.standard.sm,
        marginVertical: theme.spacing.standard.sm,
    },
    listContainer: {
        flex: 1,
    },
}));
export default SelectAvailableLanguageDrawer;
