import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { ToolbarAction } from '../../Toolbar/types';
import useUploadImageHandler from './useUploadImageHandler';
import useCaretPosition from './useCaretPosition';
export default function useActionHandler(htmlRendererRef) {
    const [, uploadImageHandler] = useUploadImageHandler(htmlRendererRef);
    const [, caretPostionActions] = useCaretPosition(htmlRendererRef);
    return useCallbackWithCtx(async (ctx, action) => {
        switch (action) {
            case ToolbarAction.BOLD: {
                caretPostionActions.focusAtLastCaretPos();
                document.execCommand('bold');
                break;
            }
            case ToolbarAction.ITALIC: {
                caretPostionActions.focusAtLastCaretPos();
                document.execCommand('italic');
                break;
            }
            case ToolbarAction.UNORDERED_LIST: {
                caretPostionActions.focusAtLastCaretPos();
                document.execCommand('insertUnorderedList');
                break;
            }
            case ToolbarAction.ORDERED_LIST: {
                caretPostionActions.focusAtLastCaretPos();
                document.execCommand('insertOrderedList');
                break;
            }
            case ToolbarAction.STRIKE_THROUGH: {
                caretPostionActions.focusAtLastCaretPos();
                document.execCommand('strikeThrough');
                break;
            }
            case ToolbarAction.IMAGE: {
                uploadImageHandler.upload();
                break;
            }
            default: {
                console.warn('Unhandled Toolbar Action:', action);
            }
        }
    }, {});
}
