import React from 'react';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import PressableArea from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { renderNamedSlot } from 'src/utils/component';
const ToolbarItem = ({ icon, ...rest }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(PressableArea, { ...rest },
        React.createElement(RowCenter, null, renderNamedSlot(icon, { color: theme.palette.typography.primary }))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ToolbarItem;
