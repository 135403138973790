import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Tag from 'src/components/10-atoms/Tag';
import useCategoryColor from 'src/components/10-atoms/topics/useCategoryColor';
import { parseTopic } from 'src/components/10-atoms/form/inputs/topics-input/utils';
const TopicTag = ({ children, ...tagProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const categoryColorActions = useCategoryColor();
    const { tag, prefix } = parseTopic(children);
    return (React.createElement(Tag, { bg: categoryColorActions.getColor(prefix), ...tagProps }, tag));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default TopicTag;
