import React from 'react';
import Author from 'src/components/10-atoms/author/Author';
import FormattedDate from 'src/components/10-atoms/date/FormattedDate';
import Divider from 'src/components/10-atoms/Divider';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import TopicTagList from 'src/components/10-atoms/topics/TopicTagList';
import Typography from 'src/components/10-atoms/typography/Typography';
import Votes from 'src/components/10-atoms/votes/Votes';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import EditButton from '../EditButton';
import TranslatedBadge from 'src/modules/translations/components/TranslatedBadge';
import BadgeList from 'src/components/10-atoms/badge/BadgeList';
import HTMLRenderer from 'src/components/10-atoms/form/inputs/editor/html/HTMLRenderer';
const QuestionLayout = ({ ctime, author, badges, shortDescription, description, title, votes, votingInProgress, hasVoted, onDownVote, onUpVote, tags, isAuthor, onEdit, isTranslated = false, style, ...columnProps }) => {
    const [{ theme }] = useTheme();
    if (isTranslated) {
        badges = (badges ?? []).concat(React.createElement(TranslatedBadge, null));
    }
    return (React.createElement(Column, { spacing: 8, style: style, ...columnProps },
        React.createElement(Row, { xAxis: "space-between", spacing: 24 },
            Array.isArray(badges) && badges.length > 0 ? (React.createElement(BadgeList, null, badges)) : (React.createElement(Row, null)),
            !!ctime && (React.createElement(FormattedDate, { date: ctime, mode: "relative", variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }))),
        React.createElement(Column, { spacing: theme.spacing.standard.xxs },
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, title),
            !!shortDescription && (React.createElement(Typography, { numberOfLines: 3, ellipsizeMode: "tail" }, shortDescription)),
            !!description && React.createElement(HTMLRenderer, { html: description })),
        tags.length > 0 && React.createElement(TopicTagList, { topics: tags }),
        React.createElement(Row, { xAxis: "space-between", yAxis: "center", spacing: theme.spacing.itemSeparation },
            author && React.createElement(Author, { flex: true, author: author }),
            typeof votes === 'number' && (React.createElement(Votes, { votes: votes, votingInProgress: votingInProgress, hasVoted: hasVoted, onUpVote: !isAuthor ? onUpVote : undefined, onDownVote: !isAuthor ? onDownVote : undefined })),
            isAuthor && !!onEdit && (React.createElement(Row, { spacing: theme.spacing.standard.sm },
                React.createElement(Divider, { orientation: "vertical" }),
                React.createElement(EditButton, { onPress: onEdit }))))));
};
export default QuestionLayout;
