import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_DELETE_REPLY_MUTATION = gql `
  mutation Community_deleteReply($replyID: String!) {
    community_deleteReply(replyID: $replyID)
  }
`;
export function useCommunityDeleteReplyMutation(options) {
    return useGQLMutation(COMMUNITY_DELETE_REPLY_MUTATION, {
        ...options,
        update(cache, {}, { variables }) {
            if (variables?.replyID) {
                cache.evict({
                    id: cache.identify({
                        id: variables.replyID,
                        __typename: 'CommunityReply',
                    }),
                });
                cache.gc();
            }
        },
    });
}
