import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import RowCenter from '../layout/RowCenter';
const VoteButton = ({ Icon, style, disabled = false, ...rowCenterProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator, { disabled }, [disabled]);
    return (React.createElement(RowCenter, { style: [styles.container, style], ...rowCenterProps },
        React.createElement(Icon, { color: disabled ? theme.palette.icon.secondary : theme.palette.primary.main })));
};
export const VOTES_BUTTON_SIZE = 40;
const stylesGenerator = createThemedStyle(({ theme }, { disabled }) => ({
    container: {
        backgroundColor: disabled
            ? theme.palette.background.darker
            : theme.palette.background.dark,
        height: VOTES_BUTTON_SIZE,
        width: VOTES_BUTTON_SIZE,
        borderRadius: VOTES_BUTTON_SIZE / 2,
    },
}));
export default withPress(VoteButton);
