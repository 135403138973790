import useFilePicker from 'src/components/10-atoms/file-picker/hooks/useFilePicker';
import { FileType } from 'src/components/10-atoms/file-picker/types';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useSeq from 'src/hooks/useSeq';
import uploadFile from 'src/lib/file-storage/uploadFile';
import { isExternalLink } from 'src/utils/url';
import { getWrappedMathField } from '../../common/common-utils/mathField';
import { log, warn } from '../../common/logger';
import { WEB_HANDLER } from '../../common/types';
import { ToolbarAction } from '../Toolbar/types';
export default function useToolbarActions(options) {
    const filePicker = useFilePicker();
    const getId = useSeq();
    const webExecCommand = useCallbackWithCtx((ctx, ...args) => options.executeAction(WEB_HANDLER.EXEC_COMMAND, { args }), {});
    const insertImage = useCallbackWithCtx((ctx, url) => webExecCommand('insertHTML', false, `<div><img src="${url}"/></div>`), {});
    const addNewLine = useCallbackWithCtx(() => {
        setTimeout(async () => {
            await webExecCommand('insertHTML', false, '<br />');
            await webExecCommand('insertText', ' ');
        }, 500);
    }, {});
    return useCallbackWithCtx(async (ctx, action) => {
        log('Handling Toolbar action:', action);
        switch (action) {
            case ToolbarAction.BOLD:
                webExecCommand('bold');
                break;
            case ToolbarAction.ITALIC:
                webExecCommand('italic');
                break;
            case ToolbarAction.UNORDERED_LIST:
                webExecCommand('insertUnorderedList');
                break;
            case ToolbarAction.ORDERED_LIST:
                webExecCommand('insertOrderedList');
                break;
            case ToolbarAction.STRIKE_THROUGH:
                webExecCommand('strikeThrough');
                break;
            case ToolbarAction.IMAGE: {
                const files = await filePicker.pick({
                    allowedTypes: FileType.IMAGES,
                    resizeImage: {
                        maxWidth: 1200,
                        maxHeight: 1200,
                    },
                });
                if (files && files[0]) {
                    const [pickedFile] = files;
                    const isExternal = isExternalLink(pickedFile.uri);
                    if (isExternal) {
                        await insertImage(pickedFile.uri);
                        addNewLine();
                    }
                    else {
                        const imageLoaderId = String(getId());
                        options.executeAction(WEB_HANDLER.INSERT_IMG_UPLOAD_PROGRESS, {
                            containerId: imageLoaderId,
                        });
                        const response = await uploadFile(pickedFile, {
                            onProgress(percentage) {
                                options.executeAction(WEB_HANDLER.UPDATE_IMG_UPLOAD_PROGRESS, {
                                    containerId: imageLoaderId,
                                    progress: percentage,
                                });
                            },
                        });
                        if (response) {
                            if (response.data && response.statusCode < 300) {
                                const imgSrc = response.data.publicURL;
                                await options.executeAction(WEB_HANDLER.REPLACE_IMG_UPLOAD_PROGRESS_WITH_IMAGE, {
                                    containerId: imageLoaderId,
                                    imgSrc,
                                });
                                addNewLine();
                            }
                        }
                    }
                }
                break;
            }
            case ToolbarAction.FORMULA: {
                const containerId = String(getId());
                await options.executeAction(WEB_HANDLER.INSERT_MATH_FIELD, {
                    containerId,
                    node: getWrappedMathField({ containerId, addLineBreak: true }),
                });
                break;
            }
            default:
                warn('No Handler found for Toolbar Action:', action);
        }
    }, {});
}
