import { useEffect, useState } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useSeq from 'src/hooks/useSeq';
import { getWrappedMathField } from '../../../../common/common-utils/mathField';
import useCaretPosition from '../useCaretPosition';
import { addMathFieldListeners } from './utils';
export default function useMathFieldHandlers(containerRef) {
    const getId = useSeq();
    const [, caretPosActions] = useCaretPosition(containerRef);
    const [isMFFocused, setIsMFFocused] = useState(false);
    const mfListenerHandlers = {
        onFocus() {
            setIsMFFocused(true);
        },
        onBlur() {
            setIsMFFocused(false);
        },
    };
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const mathExprNodes = containerRef.current.getElementsByClassName('math-expr');
        for (let i = 0; i < mathExprNodes.length; ++i) {
            const mathExprNode = mathExprNodes[i];
            addMathFieldListeners(mathExprNode, mfListenerHandlers);
        }
    }, []);
    const insertMathField = useCallbackWithCtx(ctx => {
        caretPosActions.focusAtLastCaretPos();
        const containerId = String(getId());
        const mathFieldContainer = getWrappedMathField({
            containerId,
            addLineBreak: true,
        });
        // Add math-field to the editor
        document.execCommand('insertHTML', false, '<br>');
        document.execCommand('insertHTML', false, mathFieldContainer);
        // setup listeners on the newly added math-field
        const mathExprNode = document.getElementById(containerId);
        addMathFieldListeners(mathExprNode, mfListenerHandlers);
        // Auto-focus on the newly added math-field
        const [mf] = mathExprNode.getElementsByTagName('math-field');
        mf.focus();
    }, {});
    const state = {
        isMFFocused,
    };
    const actions = {
        insertMathField,
    };
    return [state, actions];
}
