import React from 'react';
import Author from 'src/components/10-atoms/author/Author';
import FormattedDate from 'src/components/10-atoms/date/FormattedDate';
import MoreOptionsButton from 'src/components/10-atoms/dropdown-menu/MoreOptionsButton';
import HTMLDiffRenderer from 'src/components/10-atoms/form/inputs/editor/html/HTMLDiffRenderer';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { ICaseEntityType, ICaseType } from 'src/modules/ugc-policy/graphql/types';
import useUGCPolicy from 'src/modules/ugc-policy/hooks/useUGCPolicy';
import useI18n from 'src/modules/translations/hooks/useI18n';
import BlockedContentMessage from 'src/modules/ugc-policy/components/BlockedContentMessage';
import useUGCMenuOptions from 'src/modules/ugc-policy/hooks/useUGCMenuOptions';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import Comments from 'src/modules/review-pipeline/screens/ReviewDetails/components/Comments';
const AnswerHistoryItem = ({ answer, historyIndex, showOnlyDiff, onUGCPolicyUpdated, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const ugcMenuOptions = useUGCMenuOptions();
    const [userDetails] = useUserStorage();
    const answerHistory = answer.data.answerHistory;
    const data = answerHistory[historyIndex];
    const isAuthor = data.author.id === userDetails?.id;
    const currentAnswer = data.answer;
    const prevAnswer = answerHistory[historyIndex - 1]?.answer;
    const [ugcPolicyState, ugcPolicyActions] = useUGCPolicy({
        entity: {
            id: `${answer.id}:${historyIndex}`,
            type: ICaseEntityType.EP_WRITTEN_ANSWER,
        },
        reportAbuseType: ICaseType.EP_ABSUIVE_CONTENT,
        entityAuthor: data.author.id,
        contentBlockList: data.blockedBy,
        onBlockContentSuccess: onUGCPolicyUpdated,
        onUnblockContentSuccess: onUGCPolicyUpdated,
    });
    const isBlocked = ugcPolicyState.isContentBlocked || ugcPolicyState.isUserBlocked;
    return isBlocked ? (React.createElement(BlockedContentMessage, { ph: theme.spacing.page, message: ugcPolicyState.isContentBlocked
            ? translate('You have blocked this Content')
            : translate('You have blocked the Author of this Content'), unblockingInProgress: ugcPolicyState.unblockContent.loading ||
            ugcPolicyState.unblockUser.loading, onUnblockPress: ugcPolicyState.isContentBlocked
            ? ugcPolicyActions.unblockContent
            : ugcPolicyActions.unblockUser })) : (React.createElement(Column, { bbw: 1 },
        React.createElement(HTMLDiffRenderer, { oldHtml: prevAnswer, newHtml: currentAnswer, showOnlyDiff: showOnlyDiff, ph: theme.spacing.page, pb: theme.spacing.standard.md, pt: theme.spacing.standard.xs }),
        React.createElement(Row, { xAxis: "space-between", yAxis: "center", ph: theme.spacing.page, pv: theme.spacing.standard.sm },
            React.createElement(Author, { flex: true, author: data.author }),
            React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.sm },
                React.createElement(FormattedDate, { mode: "relative", date: data.ctime, variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }),
                !isAuthor && (React.createElement(MoreOptionsButton, { options: [
                        ugcMenuOptions.REPORT_ABUSE(ugcPolicyActions.reportAbuse),
                        ugcMenuOptions.BLOCK_AUTHOR(ugcPolicyActions.blockUser),
                        ugcMenuOptions.BLOCK_CONTENT(ugcPolicyActions.blockContent),
                    ] })))),
        !!data.comments && (React.createElement(Comments, { mh: theme.spacing.page, mb: theme.spacing.standard.sm, comments: data.comments }))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default AnswerHistoryItem;
