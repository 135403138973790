import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Row from 'src/components/10-atoms/layout/Row';
import Label from 'src/components/10-atoms/form/Label';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import Switch from 'src/components/10-atoms/form/inputs/SwitchInput';
const ShowDifferenceSwitch = ({ ...switchProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xs, bg: theme.palette.background.dark, xAxis: "space-between", yAxis: "center" },
        React.createElement(Label, { variant: TypographyVariant.BODY }, translate('Show only the difference')),
        React.createElement(Switch, { ...switchProps })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ShowDifferenceSwitch;
