const StorageKeys = {
    debugSettings: '/debug/settings',
    drawerInitialRouteName: '/drawer/initial-route-name',
    usageProfile: '/usage-profile',
    usageProfileThresholdCondState: '/usage-profile/threshold-cond-state',
    trackerLastOpenedDate: '/trackers/last-opened-date',
    configService: '/config-service',
    notificationPermission: '/notification/permission',
    fcmToken: '/notification/fcmToken',
    requestETag: '/api/request/etag',
    userPreferences: '/user/preferences',
    // Translations related keys
    preferredLanguage: '/translations/selected-lang',
    languageTranslations: '/translations/lang',
    communityTopicsUsedFrequency: '/community/topics/frequency',
    communityCategoriesColor: '/community/category/color',
    communityPrevSelectedFilterViewID: '/community/filter-view/prev-selected/id',
    examPrepSubjectLastUsedGroup: '/exam-prep/subject/last-used-group',
    // Auth
    userAuthToken: 'auth-token',
    epSelectedExamLevel: '/exam-prep/selected-exam-level',
    ttsPreferences: '/tts/preferences',
};
export default StorageKeys;
