import { CommonActions } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef } from 'react';
import { InteractionManager, Share } from 'react-native';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import { UsageTrackingParams } from 'src/hooks/app-level/trackers/constants';
import useUsageProfile from 'src/hooks/app-level/useUsageProfile';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import Analytics from 'src/lib/analytics';
import DynamicLink from 'src/lib/dynamic-links';
import FTUEKeys from 'src/lib/ftue/keys';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import { ICaseEntityType, ICaseType } from 'src/modules/ugc-policy/graphql/types';
import useUGCPolicy from 'src/modules/ugc-policy/hooks/useUGCPolicy';
import { getCommunityQuestionDetailsDL } from '../../communityLinking';
import AnswerList from '../../components/answer/list';
import QuestionContainer from '../../components/question/container';
import { IQuestionVariant } from '../../containers/QuestionList/types';
import SimilarQuestionList from '../../containers/SimilarQuestionList';
import FirstQuestionReadAsAuthorDrawer from '../../tutorials/feature-explanation/FirstQuestionReadAsAuthor';
import { showExplanationDrawer } from '../../tutorials/utils';
import HighlightProvider from './context/highlight/Provider';
import QuestionDetailsLoader from './Loader';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
import useMenuItems from './hooks/useMenuItems';
import BlockedContentMessage from 'src/modules/ugc-policy/components/BlockedContentMessage';
export const questionDetailsPageAnalytics = Analytics.event
    .builder()
    .setPage('Community_QuestionDetails');
const QuestionDetails = ({ navigation, route }) => {
    const { questionID, highlightEntityID, highlightEntityType } = route.params ?? {};
    const [{ theme, dimensions: { safeAreaInsets }, },] = useTheme();
    const { translate } = useI18n();
    const menuItems = useMenuItems();
    const [state, actions] = useData(questionID);
    const prevHightlightEntity = useRef('');
    useEffect(() => {
        if (state.questionDetailsQuery.data &&
            highlightEntityID &&
            highlightEntityType) {
            const highlightEntity = [highlightEntityType, highlightEntityID].join('_');
            if (prevHightlightEntity.current !== highlightEntity) {
                prevHightlightEntity.current = highlightEntity;
                switch (highlightEntityType) {
                    case 'answer': {
                        const answerFound = state.questionDetailsQuery.data.community_questionDetails.answers.some(answer => answer.id === highlightEntityID);
                        if (!answerFound) {
                            helperViewActions.snackbar.show({
                                variant: 'NEUTRAL',
                                message: translate('Answer has been deleted'),
                            });
                        }
                        break;
                    }
                    case 'reply': {
                        let replyFound = state.questionDetailsQuery.data.community_questionDetails.replies.some(reply => reply.id === highlightEntityID);
                        if (!replyFound) {
                            replyFound =
                                state.questionDetailsQuery.data.community_questionDetails.answers.some(answer => {
                                    return answer.replies.some(reply => reply.id === highlightEntityID);
                                });
                        }
                        if (!replyFound) {
                            helperViewActions.snackbar.show({
                                variant: 'NEUTRAL',
                                message: translate('Reply has been deleted'),
                            });
                        }
                        break;
                    }
                }
            }
        }
    }, [state.questionDetailsQuery.data, highlightEntityID]);
    const [userDetails] = useUserStorage();
    const isAuthor = state.questionDetailsQuery.data
        ? userDetails?.id ===
            state.questionDetailsQuery.data.community_questionDetails.author.id
        : false;
    useEffect(() => {
        if (state.questionDetailsQuery.data) {
            if (isAuthor) {
                showExplanationDrawer(FirstQuestionReadAsAuthorDrawer, FTUEKeys.COMMUNITY_FIRST_QUESTION_READ_AS_AUTHOR);
            }
        }
    }, [state.questionDetailsQuery.data]);
    const [, usageProfileModifiers] = useUsageProfile();
    useEffectWithCtx(({ increment }) => {
        if (state.questionDetailsQuery.data) {
            const timer = setTimeout(() => {
                increment(UsageTrackingParams.communityQuestionsViewed);
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, { increment: usageProfileModifiers.increment }, [state.questionDetailsQuery.data]);
    const handleQuestionShare = async () => {
        questionDetailsPageAnalytics.setWidget('Share').setTarget('').click();
        const url = await DynamicLink.buildLink(getCommunityQuestionDetailsDL(questionID));
        try {
            await Share.share({
                url,
                message: translate('Marine Samraj | Invites you to help your friend get an Answer. Start by clicking on the link.\n%url', { url }),
            });
        }
        catch (error) { }
    };
    const scrollRef = useRef(null);
    const handleHighlightElemFound = useCallback((ref) => {
        setTimeout(() => {
            InteractionManager.runAfterInteractions(() => {
                ref.current.measureInWindow((_, y) => {
                    if (scrollRef.current) {
                        let scrollYOffset = y - 100;
                        let node = scrollRef.current;
                        if (node.getNode) {
                            node = node.getNode();
                        }
                        if (node.scrollTo) {
                            node.scrollTo({
                                y: scrollYOffset,
                                animated: true,
                            });
                        }
                        else if (node.scrollToPosition) {
                            node.scrollToPosition(0, scrollYOffset, true);
                        }
                    }
                });
            });
        }, 500);
    }, []);
    const goBack = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.dispatch(navState => {
                return CommonActions.reset({
                    ...navState,
                    index: 0,
                    routes: [
                        {
                            name: 'Tabs',
                        },
                    ],
                });
            });
        }
    }, [navigation]);
    const followInProgress = state.followMutation.loading || state.unfollowMutation.loading;
    const handleFollowPress = () => {
        if (state.questionDetailsQuery.data && !followInProgress) {
            actions.followMutation.submit({
                variables: {
                    questionID: state.questionDetailsQuery.data.community_questionDetails.id,
                },
            });
        }
    };
    const handleUnfollowPress = () => {
        if (state.questionDetailsQuery.data && !followInProgress) {
            actions.unfollowMutation.submit({
                variables: {
                    questionID: state.questionDetailsQuery.data.community_questionDetails.id,
                },
            });
        }
    };
    const [ugcPolicyState, ugcPolicyActions] = useUGCPolicy({
        entity: {
            type: ICaseEntityType.COMMUNITY_QUESTION,
            id: questionID,
        },
        entityAuthor: state.questionDetailsQuery.data?.community_questionDetails.author.id,
        reportAbuseType: ICaseType.COMMUNITY_ABSUIVE_CONTENT,
        contentBlockList: state.questionDetailsQuery.data?.community_questionDetails.blockedBy,
    });
    return (React.createElement(Page, null,
        React.createElement(CustomScrollView, { ref: ref => (scrollRef.current = ref), renderCollapsibleHeader: () => (React.createElement(PageNavbar, { title: "Question", backType: "back", onBackPress: goBack, maxVisibleActions: 2, actions: state.questionDetailsQuery.data && [
                    state.isFollowing
                        ? menuItems.STOP_NOTIFICATIONS(handleUnfollowPress, {
                            theme,
                            followInProgress,
                        })
                        : menuItems.NOTIFY_ME(handleFollowPress, {
                            theme,
                            followInProgress,
                        }),
                    menuItems.SHARE(handleQuestionShare, { theme }),
                    !isAuthor &&
                        menuItems.REPORT_ABUSE(ugcPolicyActions.reportAbuse),
                    !isAuthor &&
                        menuItems.BLOCK_CONTENT(ugcPolicyActions.blockContent),
                    !isAuthor && menuItems.BLOCK_AUTHOR(ugcPolicyActions.blockUser),
                ] })), collapsibleHeaderHeight: NAVBAR_HEIGHT, contentContainerStyle: {
                paddingBottom: safeAreaInsets.bottom,
                paddingTop: theme.spacing.standard.md,
            }, refreshing: state.questionDetailsQuery.refetching, onRefresh: actions.questionDetailsQuery.fetch },
            React.createElement(Column, null, (() => {
                if (state.questionDetailsQuery.data) {
                    const question = state.questionDetailsQuery.data.community_questionDetails;
                    const isBlocked = ugcPolicyState.isContentBlocked || ugcPolicyState.isUserBlocked;
                    return isBlocked ? (React.createElement(BlockedContentMessage, { message: ugcPolicyState.isContentBlocked
                            ? translate('You have blocked this Question')
                            : translate('You have blocked the Author of this Question'), unblockingInProgress: ugcPolicyState.unblockContent.loading ||
                            ugcPolicyState.unblockUser.loading, onUnblockPress: ugcPolicyState.isContentBlocked
                            ? ugcPolicyActions.unblockContent
                            : ugcPolicyActions.unblockUser })) : (React.createElement(Column, null,
                        React.createElement(HighlightProvider, { entityID: highlightEntityID, entityType: highlightEntityType, onHighlightRef: handleHighlightElemFound },
                            React.createElement(QuestionContainer, { question: question, variant: IQuestionVariant.DETAILS }),
                            React.createElement(AnswerList, { question: question, onSharePress: handleQuestionShare })),
                        !!state.questionDetailsQuery.data && (React.createElement(SimilarQuestionList, { exclude: [question.id], tags: state.questionDetailsQuery.data
                                .community_questionDetails.data.tags }))));
                }
                if (state.questionDetailsQuery.loading) {
                    return React.createElement(QuestionDetailsLoader, null);
                }
                if (state.questionDetailsQuery.error) {
                    return (React.createElement(GenericServerError, { error: state.questionDetailsQuery.error, onRetry: actions.questionDetailsQuery.fetch }));
                }
                return null;
            })()))));
};
export default QuestionDetails;
