import useLayoutAnimation from 'src/hooks/animation/useLayoutAnimation';
import FTUEKeys from 'src/lib/ftue/keys';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import { useCommunityAnswerAcceptMutation } from 'src/modules/community/graphql/answer/accept';
import { useCommunityDeleteAnswerMutation } from 'src/modules/community/graphql/answer/delete';
import { useCommunityEditAnswerMutation } from 'src/modules/community/graphql/answer/edit';
import { useCommunityCastVoteMutation } from 'src/modules/community/graphql/common/castVote';
import { CommunityVoteType } from 'src/modules/community/graphql/common/castVote/types';
import { useHighlightContext } from 'src/modules/community/screens/QuestionDetails/context/highlight';
import AnswerVotingGuidelinesDrawer from 'src/modules/community/tutorials/guidelines/AnswerVotingGuidelines';
import { showGuidelinesDrawer } from 'src/modules/community/tutorials/utils';
export default function useData(question, answer) {
    const layoutAnimation = useLayoutAnimation();
    const [currUser] = useUserStorage();
    const [castVoteMutationState, castVoteMutationActions] = useCommunityCastVoteMutation();
    const [answerAcceptMutationState, answerAcceptMutationActions] = useCommunityAnswerAcceptMutation();
    const [editAnswerMutationState, editAnswerMutationActions] = useCommunityEditAnswerMutation();
    const [deleteAnswerMutationState, deleteAnswerMutationActions] = useCommunityDeleteAnswerMutation({
        onCompleted() {
            layoutAnimation.setupLayoutAnimation();
        },
    });
    const highlightContext = useHighlightContext();
    const shouldHighlight = highlightContext.entityType === 'answer' &&
        highlightContext.entityID === answer.id;
    const state = {
        highlight: shouldHighlight,
        isQuestionAuthor: currUser?.id === question.author.id,
        isAuthor: currUser?.id === answer.author.id,
        castVoteMutation: castVoteMutationState,
        answerAcceptMutation: answerAcceptMutationState,
        editAnswerMutation: editAnswerMutationState,
        deleteAnswerMutation: deleteAnswerMutationState,
    };
    const actions = {
        castVoteMutation: {
            async upVote() {
                await showGuidelinesDrawer(AnswerVotingGuidelinesDrawer, FTUEKeys.COMMUNITY_ANSWER_VOTING_GUIDELINES);
                castVoteMutationActions.submit({
                    variables: {
                        nodeID: answer.id,
                        type: CommunityVoteType.UP,
                    },
                    context: {
                        parentNodeID: answer.id,
                        parentNodeType: 'CommunityAnswer',
                    },
                });
            },
            async downVote() {
                await showGuidelinesDrawer(AnswerVotingGuidelinesDrawer, FTUEKeys.COMMUNITY_ANSWER_VOTING_GUIDELINES);
                castVoteMutationActions.submit({
                    variables: {
                        nodeID: answer.id,
                        type: CommunityVoteType.DOWN,
                    },
                    context: {
                        parentNodeID: answer.id,
                        parentNodeType: 'CommunityAnswer',
                    },
                });
            },
        },
        answerAcceptMutation: answerAcceptMutationActions,
        editAnswerMutation: editAnswerMutationActions,
        deleteAnswerMutation: deleteAnswerMutationActions,
    };
    return [state, actions];
}
