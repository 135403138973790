import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import InfoIcon from '../icons/InfoIcon';
import Typography from '../typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const Message = ({ type = 'info', style, children, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xxs, style: [styles.container, style], ...rowProps },
        type === 'info' ? (React.createElement(InfoIcon, { style: { marginTop: 2, alignSelf: 'flex-start' }, size: theme.iconSize.xs })) : (React.createElement(Typography, { variant: TypographyVariant.BODY, color: theme.palette.typography.secondary, lineHeight: 20 }, "\uD83D\uDCA1")),
        typeof children === 'string' ? (React.createElement(Typography, { flex: true, variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, children)) : (children)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        backgroundColor: theme.palette.background.message,
        paddingHorizontal: theme.spacing.page,
        paddingVertical: theme.spacing.standard.xs,
    },
}));
export default Message;
