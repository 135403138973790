import { renderMathInElement } from 'mathlive';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { ScrollView } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import { getFontWeightStyle } from 'src/components/10-atoms/typography/utils';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useId from 'src/hooks/useId';
import styleUtils from 'src/utils/styles';
import addMathFieldWrapper, { formatMathExpr, } from '../../common/common-utils/mathField';
import getRootStyles from '../../common/common-utils/rootStyles';
import { HTMLRendererVariant, WEB_VARIANT } from '../../common/types';
import { extractHTML2TTSContent } from 'src/utils/html';
const WebHTMLRenderer = React.forwardRef(({ html, onRenderComplete, variant, style, ...columnProps }, ref) => {
    const [{ theme, deviceType, styles }] = useTheme(stylesGenerator);
    const id = useId();
    const htmlRendererDOMId = `html_renderer_${id}`;
    const htmlRendererRef = useRef(null);
    // @ts-nocheck
    useEffect(() => {
        renderMathInElement(htmlRendererDOMId);
        setTimeout(() => {
            onRenderComplete?.();
        }, 100);
    }, [html]);
    useImperativeHandle(ref, () => ({
        async getTTSContent() {
            return extractHTML2TTSContent(htmlRendererRef);
        },
    }), [htmlRendererRef]);
    return (React.createElement(Column
    // This was a hack for web because the styles are converted to classes
    // in web, so that will be applied to all the child DOM elements
    , { 
        // This was a hack for web because the styles are converted to classes
        // in web, so that will be applied to all the child DOM elements
        style: [
            style,
            // @ts-ignore
            theme.typography[TypographyVariant.READER].style,
            // @ts-ignore
            getFontWeightStyle(TypographyFontWeight.REGULAR),
            // @ts-ignore
            styles.font,
        ], ...columnProps },
        React.createElement(ScrollView, { style: styleUtils.flex },
            React.createElement(Helmet, null,
                React.createElement("style", null, getRootStyles(theme, deviceType, WEB_VARIANT.WEB_RENDERER))),
            React.createElement("div", { id: htmlRendererDOMId, ref: htmlRendererRef, dangerouslySetInnerHTML: {
                    __html: variant === HTMLRendererVariant.TTS
                        ? addMathFieldWrapper(html)
                        : formatMathExpr(html),
                } }))));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    font: {
        color: theme.palette.typography.primary,
    },
}));
export default WebHTMLRenderer;
