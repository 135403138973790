import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { COMMUNITY_QUESTION_DETAILS_QUERY } from '../../question/get';
const COMMUNITY_ANSWER_ACCEPT_MUTATION = gql `
  mutation Community_acceptAnswer($answerID: String!) {
    community_acceptAnswer(answerID: $answerID)
  }
`;
function updateAnswerAcceptance(cache, answerID, accept) {
    cache.modify({
        id: cache.identify({
            id: answerID,
            __typename: 'CommunityAnswer',
        }),
        fields: {
            data: existing => ({
                ...existing,
                accepted: accept,
            }),
        },
    });
}
export function useCommunityAnswerAcceptMutation(options) {
    return useGQLMutation(COMMUNITY_ANSWER_ACCEPT_MUTATION, {
        ...options,
        update(cache, {}, { variables, context }) {
            // Remove Accepted on all the answers belonging to this question
            const { questionID } = context ?? {};
            if (questionID) {
                const question = cache.readQuery({
                    query: COMMUNITY_QUESTION_DETAILS_QUERY,
                    variables: {
                        questionID,
                    },
                });
                if (question) {
                    question.community_questionDetails.answers?.forEach(answer => {
                        updateAnswerAcceptance(cache, answer.id, answer.id === variables?.answerID);
                    });
                }
                cache.modify({
                    id: cache.identify({
                        id: questionID,
                        __typename: 'CommunityQuestion',
                    }),
                    fields: {
                        hasAcceptedAnswer: () => true,
                    },
                });
            }
        },
    });
}
