import { useRef } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { ToolbarAction } from '../../Toolbar/types';
import useMathFieldHandlers from './math-field/useMathFieldHandlers';
import useActionHandler from './useActionHandler';
import usePasteSanitizedHTML from './usePasteSanitizedHTML';
export default function useData() {
    const htmlRendererRef = useRef(null);
    usePasteSanitizedHTML(htmlRendererRef);
    const [mathFieldState, mathFieldActions] = useMathFieldHandlers(htmlRendererRef);
    const actionHandler = useActionHandler(htmlRendererRef);
    const handleActionPress = useCallbackWithCtx(async (ctx, action) => {
        switch (action) {
            case ToolbarAction.FORMULA: {
                mathFieldActions.insertMathField();
                break;
            }
            default: {
                actionHandler(action);
            }
        }
    }, {});
    const state = {
        mathField: mathFieldState,
        htmlRendererRef,
    };
    const actions = {
        handleActionPress,
    };
    return [state, actions];
}
