import React, { useEffect } from 'react';
import Divider from 'src/components/10-atoms/Divider';
import Column from 'src/components/10-atoms/layout/Column';
import BackdropLoader from 'src/components/10-atoms/loaders/BackdropLoader';
import ListLoaderLayout from 'src/components/10-atoms/loaders/ListLoaderLayout';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import EmptyList from 'src/components/templates/EmptyList.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import QuestionItem from '../../../../containers/QuestionList/QuestionItem';
import useData from './useData';
import Row from 'src/components/10-atoms/layout/Row';
import Button from 'src/components/10-atoms/button/Button';
const MarkAsDuplicateDrawer = ({ duplicateQuestion, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData(duplicateQuestion);
    const handleSelect = useCallbackWithCtx((ctx, matchingQuestion) => {
        ctx.actions.markAsDuplicateMutation.submit({
            variables: {
                questionId: matchingQuestion.id,
                duplicateQuestionId: ctx.duplicateQuestion.id,
            },
        });
    }, { state, actions, duplicateQuestion });
    const handleRemoveDuplicate = useCallbackWithCtx(ctx => {
        ctx.actions.markAsDuplicateMutation.submit({
            variables: {
                questionId: ctx.duplicateQuestion.id,
                duplicateQuestionId: ctx.duplicateQuestion.id,
            },
        });
    }, { state, actions, duplicateQuestion });
    useEffect(() => {
        if (state.markAsDuplicateMutation.data) {
            helperViewActions.bottomDrawer.hide();
        }
    }, [state.markAsDuplicateMutation]);
    return (React.createElement(BottomDrawerLayout, { yAxis: "flex-start", style: styleUtils.containerHeight },
        React.createElement(BottomDrawerLayout.Title, null, translate('Select the matching Question')),
        React.createElement(Column, { style: styleUtils.flex },
            React.createElement(GQLPaginatedList, { containerStyle: styleUtils.flex, ListEmptyComponent: React.createElement(EmptyList, { message: translate('No matching questions found') }), LoaderComponent: React.createElement(ListLoaderLayout, { pt: theme.spacing.standard.sm, loaderItemHeight: 76 }), queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVariables, renderItem: ({ item }) => item.id === duplicateQuestion.id ? null : (React.createElement(QuestionItem, { data: item, variant: "question-search", onPress: () => {
                        handleSelect(item);
                    } })), ItemSeparatorComponent: Divider }),
            React.createElement(Row, { ph: theme.spacing.page, pt: theme.spacing.standard.xs, btw: 1 },
                React.createElement(Button, { flex: true, onPress: handleRemoveDuplicate }, translate('Remove Duplicate'))),
            state.markAsDuplicateMutation.loading && React.createElement(BackdropLoader, null))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default MarkAsDuplicateDrawer;
