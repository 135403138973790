import { useCallback } from 'react';
import StorageKeys from 'src/constants/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
export default function useUserPreferences() {
    const [prefs, setPrefs] = usePersistedState('session', StorageKeys.userPreferences, {});
    const updatePrefs = useCallback((prop, value) => {
        prefs[prop] = value;
        setPrefs({ ...prefs });
    }, []);
    const actions = {
        setPrefs,
        setLang(lang) {
            updatePrefs('lang', lang);
        },
    };
    return [prefs, actions];
}
