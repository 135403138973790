export var IFilterViewType;
(function (IFilterViewType) {
    IFilterViewType[IFilterViewType["PRE_DEFINED"] = 1] = "PRE_DEFINED";
    IFilterViewType[IFilterViewType["USER_DEFINED"] = 2] = "USER_DEFINED";
    IFilterViewType[IFilterViewType["RECOMMENDED"] = 3] = "RECOMMENDED";
})(IFilterViewType || (IFilterViewType = {}));
export var IFilterViewScope;
(function (IFilterViewScope) {
    IFilterViewScope[IFilterViewScope["PUBLIC"] = 1] = "PUBLIC";
    IFilterViewScope[IFilterViewScope["PRIVATE"] = 2] = "PRIVATE";
})(IFilterViewScope || (IFilterViewScope = {}));
