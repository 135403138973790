import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_ADD_ANSWER_MUTATION = gql `
  mutation Community_addAnswer($data: CommunityAddAnswerInput!) {
    community_addAnswer(data: $data) {
      id
      data {
        format
        answer
        accepted
        votes
      }
      ctime
      utime
      replies {
        id
        data {
          reply
        }
        ctime
        utime
      }
      repliesCount
      author {
        id
        details {
          avatarURL
          firstName
          lastName
          userName
        }
      }
    }
  }
`;
export function useCommunityAddAnswerMutation(options) {
    return useGQLMutation(COMMUNITY_ADD_ANSWER_MUTATION, {
        ...options,
        update(cache, { data }, { context }) {
            const { questionID } = context ?? {};
            if (questionID) {
                cache.modify({
                    id: cache.identify({
                        id: questionID,
                        __typename: 'CommunityQuestion',
                    }),
                    fields: {
                        answers(existingAnswersRef) {
                            return [...existingAnswersRef, data?.community_addAnswer];
                        },
                    },
                });
            }
        },
    });
}
