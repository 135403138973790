import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useI18n from 'src/modules/translations/hooks/useI18n';
const QuestionReplyGuidelinesDrawer = ({ onConfirm, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Question Reply Guidelines')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(ULItem, null, translate('Use this section only to ask doubts related to the Question')),
            React.createElement(ULItem, null, translate('Suggest modifications to the Question')),
            React.createElement(ULItem, null, translate('Kindly add your Answer using "Add your Answer" button'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { enableAfter: 3, flex: true, onPress: onConfirm }, translate('I Agree')))));
};
export default QuestionReplyGuidelinesDrawer;
