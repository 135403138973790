import { Linking } from 'react-native';
import { imageViewHelperActions } from 'src/components/20-helper-views/image-viewer/useImageViewer';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { RN_HANDLER } from '../../common/types';
export default function useActionMessageHandler(options) {
    return useCallbackWithCtx(async (ctx, message) => {
        const handlers = {
            [RN_HANDLER.DOCUMENT_HEIGHT](payload) {
                options.onHeightChange(payload.data);
            },
            [RN_HANDLER.IMG_CLICK](payload) {
                imageViewHelperActions.show({
                    images: [{ uri: payload.data }],
                });
            },
            [RN_HANDLER.LINK_CLICK](payload) {
                Linking.openURL(payload.data);
            },
            [RN_HANDLER.RENDER_COMPLETED]() {
                options.onRenderComplete?.();
            },
        };
        const handler = handlers[message.data.handler];
        if (handler) {
            return handler(message.data);
        }
    }, {});
}
