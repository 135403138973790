export var FileType;
(function (FileType) {
    // ALL_FILES = 'allFiles',
    // AUDIO = 'audio',
    // CSV = 'csv',
    // DOC = 'doc',
    // DOCX = 'docx',
    FileType["IMAGES"] = "images";
    FileType["PDF"] = "pdf";
    // PLAIN_TEXT = 'plainText',
    // PPT = 'ppt',
    // PPTX = 'pptx',
    // VIDEO = 'video',
    // XLS = 'xls',
    // XLSX = 'xlsx',
    // ZIP = 'zip',
})(FileType || (FileType = {}));
export const WebFileTypeMapping = {
    [FileType.IMAGES]: 'image/*',
    [FileType.PDF]: '.pdf',
};
