import useTabs from 'src/components/10-atoms/tabs/useTabs';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function useData() {
    const { translate } = useI18n();
    const [tabsState, tabsActions] = useTabs({
        tabs: [
            {
                id: 'MY_LIST',
                label: translate('My List'),
            },
            {
                id: 'RECOMMENDED',
                label: translate('Recommendations'),
            },
        ],
        activeIndex: 0,
    });
    const state = {
        tabs: tabsState,
    };
    const actions = {
        tabs: tabsActions,
    };
    return [state, actions];
}
