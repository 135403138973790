import React, { useEffect } from 'react';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import ListLoaderLayout from 'src/components/10-atoms/loaders/ListLoaderLayout';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Analytics from 'src/lib/analytics';
import getGraphQLErrorMessage from 'src/lib/graphql/utils/getError';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import AnswerHistoryItem from './components/AnswerHistoryItem';
import useData from './useData';
import ShowDifferenceSwitch from './components/ShowOnlyDifferenceSwitch';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import InfoIcon from 'src/components/10-atoms/icons/InfoIcon';
const pageAnalytics = Analytics.event.builder().setPage('EP_AnswerHistory');
const AnswerHistory = ({ route, navigation }) => {
    const { questionID } = route.params;
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData(questionID);
    useEffect(() => {
        if (state.historyQuery.data) {
            if (state.isFTUE) {
                actions.showWalkthrough();
            }
        }
    }, [state.historyQuery.data]);
    const answerHistory = state.historyQuery.data?.ep_questionDetails.answer?.data.answerHistory;
    const answer = state.historyQuery.data?.ep_questionDetails.answer;
    return (React.createElement(Page, { style: styleUtils.flex },
        React.createElement(CustomScrollView, { containerStyle: styleUtils.flex, renderCollapsibleHeader: () => (React.createElement(Column, { bbw: 1 },
                React.createElement(PageNavbar, { title: translate('Answer History'), backType: "back", onBackPress: navigation.goBack, actions: [
                        {
                            Icon: InfoIcon,
                            title: 'Info',
                            onPress: () => actions.showWalkthrough(true),
                        },
                    ] }),
                React.createElement(ShowDifferenceSwitch, { value: state.showOnlyDiff, onChange: actions.setShowOnlyDiff }))), collapsibleHeaderHeight: NAVBAR_HEIGHT + PRESSABLE_SIZE, contentContainerStyle: {
                paddingBottom: NAVBAR_HEIGHT,
            }, onRefresh: actions.historyQuery.fetch, refreshing: !!(state.historyQuery.loading && state.historyQuery.data) },
            React.createElement(QueryHandler, { queryState: state.historyQuery, LoaderComponent: React.createElement(ListLoaderLayout, { pt: theme.spacing.standard.sm, count: 3, loaderItemHeight: 96 }), ErrorComponent: React.createElement(GenericServerError, { message: getGraphQLErrorMessage(state.historyQuery.error), onRetry: actions.historyQuery.fetch }), DataComponent: React.createElement(Column, { flex: true }, answerHistory?.map((_, i) => (React.createElement(AnswerHistoryItem, { key: i, answer: answer, historyIndex: answerHistory.length - 1 - i, showOnlyDiff: state.showOnlyDiff, onUGCPolicyUpdated: actions.historyQuery.fetch })))) }))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default AnswerHistory;
