import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import Row from '../../layout/Row';
import Typography from '../../typography/Typography';
import isNot from 'src/utils/array/isNot';
import useI18n from 'src/modules/translations/hooks/useI18n';
const Option = withPress(({ Icon, label }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { yAxis: "center", style: styles.option, spacing: 8 },
        React.createElement(Icon, null),
        React.createElement(Typography, null, label)));
});
const FilePickerOptionsDrawer = ({ title, sources, onCancel, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, null, sources
            .filter(isNot(false))
            .filter(isNot(undefined))
            .map(option => (React.createElement(Option, { key: option.label, onPress: option.onPress, Icon: option.Icon, label: option.label })))),
        React.createElement(BottomDrawerLayout.Actions, { xAxis: "center" },
            React.createElement(Button, { variant: "text", onPress: onCancel }, translate('Cancel')))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    option: {
        paddingVertical: theme.spacing.standard.xxs,
        minHeight: PRESSABLE_SIZE,
    },
}));
export default FilePickerOptionsDrawer;
