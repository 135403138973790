import { getDeviceInfo } from 'src/lib/device';
import deviceInfoStorage from '../../authentication/storage/device/storage';
import tokenStorage from '../../authentication/storage/token/storage';
import { updateDeviceInfo } from '../apis/device/update';
export default async function updateUserDeviceInfo() {
    const token = tokenStorage.getToken();
    if (token) {
        try {
            const currentDeviceInfo = await getDeviceInfo();
            const response = await updateDeviceInfo(currentDeviceInfo);
            if (response?.data?.updateDeviceDetails?.success) {
                deviceInfoStorage.setDeviceInfo(currentDeviceInfo);
            }
        }
        catch (error) {
            console.log('failed to update device info:', error);
        }
    }
}
