import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { CommunityVoteType } from './types';
const COMMUNITY_CAST_VOTE_MUTATION = gql `
  mutation Community_castVote($nodeID: String!, $type: CommunityVoteType!) {
    community_castVote(nodeID: $nodeID, type: $type)
  }
`;
export function useCommunityCastVoteMutation(options) {
    return useGQLMutation(COMMUNITY_CAST_VOTE_MUTATION, {
        ...options,
        update(cache, { data }, { context, variables }) {
            const { parentNodeID, parentNodeType } = context ?? {};
            const castVoteSuccess = data?.community_castVote;
            if (parentNodeID && parentNodeType && castVoteSuccess) {
                cache.modify({
                    id: cache.identify({
                        id: parentNodeID,
                        __typename: parentNodeType,
                    }),
                    fields: {
                        data(prevData) {
                            // If the user has already downvoted, then the down vote button
                            // will be disabled, hence the only option left is to upvote.
                            // Since he had already downvote(which contributes to -1 in the total votes),
                            // we must add +2(+1 to negate the downvote and +1 for the upvote) on upvote
                            // and vice-versa(for downvote).
                            const diff = prevData.hasVoted === -1
                                ? 2
                                : prevData.hasVoted === 1
                                    ? -2
                                    : variables?.type === CommunityVoteType.UP
                                        ? 1
                                        : -1;
                            return {
                                ...prevData,
                                votes: prevData.votes + diff,
                                hasVoted: variables?.type === CommunityVoteType.UP ? 1 : -1,
                            };
                        },
                    },
                });
            }
        },
    });
}
