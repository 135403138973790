import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Divider from 'src/components/10-atoms/Divider';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import FTUEKeys from 'src/lib/ftue/keys';
import { questionDetailsPageAnalytics } from 'src/modules/community/screens/QuestionDetails';
import AnswerReplyGuidelinesDrawer from 'src/modules/community/tutorials/guidelines/AnswerReplyGuidelines';
import QuestionReplyGuidelinesDrawer from 'src/modules/community/tutorials/guidelines/QuestionReplyGuidelines';
import { showGuidelinesDrawer } from 'src/modules/community/tutorials/utils';
import useI18n from 'src/modules/translations/hooks/useI18n';
import ReplyContainer from '../container';
import ReplyEditor from '../Editor';
import useData from './useData';
const ReplyList = ({ replies, context, style, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData(replies);
    const handleSubmit = (reply) => {
        actions.addReplyMutation.create({
            variables: {
                data: {
                    nodeID: context.parentNodeID,
                    reply,
                },
            },
            context,
        });
    };
    const handleReplyPress = async () => {
        const ReplyGuidelinesComponent = context.parentNodeType === 'CommunityQuestion'
            ? QuestionReplyGuidelinesDrawer
            : AnswerReplyGuidelinesDrawer;
        await showGuidelinesDrawer(ReplyGuidelinesComponent, context.parentNodeType === 'CommunityQuestion'
            ? FTUEKeys.COMMUNITY_QUESTION_REPLY_GUIDELINES
            : FTUEKeys.COMMUNITY_ANSWER_REPLY_GUIDELINES);
        actions.setShowReplyEditor(true);
    };
    return (React.createElement(Column, { style: [styles.container, style], ItemSeparator: () => React.createElement(Divider, null), ...columnProps },
        state.isExpanded &&
            replies.map(reply => React.createElement(ReplyContainer, { key: reply.id, reply: reply })),
        (() => {
            if (state.showReplyEditor) {
                return (React.createElement(ReplyEditor, { style: styles.replyEditor, variant: "new", onCancel: () => actions.setShowReplyEditor(false), onSubmit: handleSubmit, submitInProgress: state.addReplyMutation.loading }));
            }
            return state.isExpanded ? (React.createElement(Button, { noIconSpacing: false, loading: state.addReplyMutation.loading, style: styles.btn, variant: "text", onPress: handleReplyPress }, translate('Reply'))) : (React.createElement(Button, { noIconSpacing: false, variant: "text", style: styles.btn, onPress: () => {
                    questionDetailsPageAnalytics
                        .setWidget('Reply')
                        .setTarget('ShowReplies')
                        .click({
                        node: context.parentNodeType,
                    });
                    actions.showAllReplies();
                } }, translate({
                singular: 'SHOW %repliesCount REPLY',
                plural: 'SHOW %repliesCount REPLIES',
                count: replies.length,
            }, {
                repliesCount: replies.length,
            })));
        })()));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        backgroundColor: theme.palette.background.dark,
        paddingHorizontal: theme.spacing.page,
    },
    btn: {
        alignSelf: 'center',
    },
    replyEditor: {
        paddingVertical: theme.spacing.itemVerticalPadding,
    },
}));
export default ReplyList;
