import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
export const ALL_MY_FILTER_VIEWS_QUERY = gql `
  query Community_getAllMyFilterViews {
    community_getAllMyFilterViews {
      id
      title
      filters {
        tags
      }
      ctime
      scope
      type
    }
  }
`;
export function useAllMyFilterViewsQuery(options) {
    return useGQLQuery(ALL_MY_FILTER_VIEWS_QUERY, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        ...options,
    });
}
