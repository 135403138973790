import { useEffect, useMemo } from 'react';
import { useCommunityQuestionListQuery } from '../../graphql/question/list';
import { COMMUNITY_QUESTION_LIST_QUERY } from '../../graphql/question/list/queries';
export default function useData(tags, exclude) {
    const [similarQuestionListQueryState, similarQuestionListQueryActions] = useCommunityQuestionListQuery(COMMUNITY_QUESTION_LIST_QUERY);
    const similarQuestionVariables = useMemo(() => ({
        searchFilter: {
            onlyUnanswered: false,
            tags,
            searchTerm: '',
            exclude,
        },
    }), [tags, exclude]);
    useEffect(() => {
        if (tags.length > 0) {
            similarQuestionListQueryActions.refresh(similarQuestionVariables);
        }
    }, []);
    const state = {
        similarQuestionListQuery: similarQuestionListQueryState,
    };
    const actions = {};
    return [state, actions];
}
