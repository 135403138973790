import { gql } from '@apollo/client';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
import { ProcessorType } from 'src/lib/playlist/types';
const EP_QUESTION_PLAYLIST_FOR_TOPIC_QUERY = gql `
  query EP_QuestionPlaylistForTopic($ancestorID: ID!, $topic: String!) {
    ep_questionPlaylistForTopic(ancestorID: $ancestorID, topic: $topic) {
      totalCount
      list {
        id
      }
    }
  }
`;
export function useQuestionPlaylistForTopicQuery(options) {
    return useGQLQuery(EP_QUESTION_PLAYLIST_FOR_TOPIC_QUERY, {
        fetchPolicy: 'cache-first',
        lazy: true,
        ...options,
    });
}
export function useTopicPlaylist(ancestorID, topic, playlistIndex = 0) {
    const [playlistQueryState, playlistQueryActions] = useQuestionPlaylistForTopicQuery({
        onCompleted(data) {
            const list = data.ep_questionPlaylistForTopic.list.map((item, index) => ({
                processorType: ProcessorType.EP_QUESTION_DETAILS,
                meta: {
                    questionID: item.id,
                    ...(ancestorID && topic
                        ? {
                            navigatedFrom: 'TOPIC_QUESTIONS',
                            navigationData: {
                                ancestorID,
                                topic,
                                sequence: index,
                            },
                        }
                        : {}),
                },
            }));
            helperViewActions.playlistPlayer.overrideQueue({
                list,
                activeIndex: playlistIndex,
            });
        },
        onError(error) {
            helperViewActions.snackbar.show({
                message: `Failed to play: ${error.message ?? 'Unknown failure'}`,
                variant: 'NEGATIVE',
                duration: 'LONG',
            });
        },
    });
    const state = {
        playlistQuery: playlistQueryState,
    };
    const actions = {
        play() {
            playlistQueryActions.fetch({
                ancestorID: ancestorID,
                topic: topic,
            });
        },
    };
    return [state, actions];
}
