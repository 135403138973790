import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_EDIT_REPLY_MUTATION = gql `
  mutation Community_editReply($id: String!, $reply: String!) {
    community_editReply(id: $id, reply: $reply) {
      id
      data {
        reply
      }
      utime
    }
  }
`;
export function useCommunityEditReplyMutation(options) {
    return useGQLMutation(COMMUNITY_EDIT_REPLY_MUTATION, options);
}
