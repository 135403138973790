import React from 'react';
import { Platform } from 'react-native';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import palette from 'src/customizations/themes/10-parts/color-schemes/palette';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import { resizeImage } from 'src/lib/image-resize';
import CameraIcon from '../../icons/CameraIcon';
import FileIcon from '../../icons/FileIcon';
import PhotoIcon from '../../icons/PhotoIcon';
import FileLinkInputDrawer from '../components/FileLinkInputDrawer';
import FilePickerOptionsDrawer from '../components/FilePickerOptions';
import { FileType } from '../types';
import { convertExternalLinkToStandardLink } from '../utils';
import useCameraPicker from './useCameraPicker';
import useDocumentPicker from './useDocumentPicker';
import useGalleryPicker from './useGalleryPicker';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { isWeb } from 'src/utils/platform';
import FeatherIcon from '../../icons/FeatherIcon';
const useFilePicker = () => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    const cameraPicker = useCameraPicker();
    const galleryPicker = useGalleryPicker();
    const documentPicker = useDocumentPicker();
    return {
        async pick(options) {
            const pickedFiles = await new Promise(promiseResolve => {
                function resolve(...args) {
                    bottomDrawerHelperActions.hide();
                    promiseResolve(...args);
                }
                const pickFromCamera = async () => {
                    const result = await cameraPicker.pick(options);
                    resolve(result ?? []);
                };
                const pickFromGallery = async () => {
                    const result = await galleryPicker.pick(options);
                    resolve(result ?? []);
                };
                const pickFromDocuments = async () => {
                    const result = await documentPicker.pick(options);
                    resolve(result ?? []);
                };
                const pickFromLink = async () => {
                    bottomDrawerHelperActions.show({
                        component: (React.createElement(FileLinkInputDrawer, { onCancel: () => {
                                bottomDrawerHelperActions.hide();
                                resolve([]);
                            }, onConfirm: link => {
                                resolve([convertExternalLinkToStandardLink(link)]);
                            } })),
                    });
                };
                if (options.allowedTypes === FileType.IMAGES) {
                    bottomDrawerHelperActions.show({
                        component: (React.createElement(FilePickerOptionsDrawer, { title: translate('Choose how to pick an Image'), sources: [
                                !isWeb && {
                                    label: translate('Camera'),
                                    Icon: ({ color: _color, ...rest }) => (React.createElement(CameraIcon, { color: theme.palette.positive.main, ...rest })),
                                    onPress: pickFromCamera,
                                },
                                !isWeb && {
                                    label: translate('Photos'),
                                    Icon: ({ color: _color, ...rest }) => (React.createElement(PhotoIcon, { color: palette.navyBlue, ...rest })),
                                    onPress: pickFromGallery,
                                },
                                !isWeb && {
                                    label: Platform.select({
                                        ios: translate('Browse'),
                                        android: translate('Files'),
                                        default: translate('Files'),
                                    }),
                                    Icon: ({ color: _color, ...rest }) => (React.createElement(FileIcon, { color: palette.yellow['800'], ...rest })),
                                    onPress: pickFromDocuments,
                                },
                                isWeb && {
                                    label: translate('Choose a file'),
                                    Icon: ({ color: _color, ...rest }) => (React.createElement(FileIcon, { color: palette.yellow['800'], ...rest })),
                                    onPress: pickFromDocuments,
                                },
                                {
                                    label: translate('Link'),
                                    Icon: ({ color: _color, ...rest }) => (React.createElement(FeatherIcon, { name: "link-2", color: palette.navyBlue, ...rest })),
                                    onPress: pickFromLink,
                                },
                            ], onCancel: () => {
                                bottomDrawerHelperActions.hide();
                                resolve([]);
                            } })),
                    });
                }
                else {
                    pickFromDocuments();
                }
            });
            if (options.allowedTypes === FileType.IMAGES && options.resizeImage) {
                if (!isWeb) {
                    const resizedFiles = await Promise.all(pickedFiles.map(file => {
                        return resizeImage(file.uri, options.resizeImage);
                    }));
                    return resizedFiles.map(file => ({
                        uri: file?.uri,
                        filename: file?.name ?? 'unknown',
                        type: 'image/png',
                        size: file?.size,
                    }));
                }
            }
            return pickedFiles;
        },
    };
};
export default useFilePicker;
