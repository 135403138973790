import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import FTUEStorage from 'src/lib/ftue/storage';
function showDrawerPromise(Component, dismissable, ftueKey) {
    let show = true;
    if (ftueKey) {
        show = !FTUEStorage.isComplete(ftueKey);
    }
    if (!show) {
        return Promise.resolve();
    }
    return new Promise(resolve => {
        helperViewActions.bottomDrawer.show({
            dismissable,
            component: (React.createElement(Component, { onConfirm: () => {
                    helperViewActions.bottomDrawer.hide();
                    if (ftueKey) {
                        FTUEStorage.markComplete(ftueKey);
                    }
                    resolve();
                } })),
        });
    });
}
export function showGuidelinesDrawer(Component, ftueKey) {
    return showDrawerPromise(Component, false, ftueKey);
}
export function showExplanationDrawer(Component, ftueKey) {
    return showDrawerPromise(Component, true, ftueKey);
}
