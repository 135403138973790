import { useMemo } from 'react';
export default function useListState(queryState, field) {
    const listDataState = useMemo(() => {
        const listData = [];
        if (queryState.data) {
            if (queryState.data[field].length === 0) {
                listData.push({ type: 'list_empty' });
            }
            else {
                listData.push(...queryState.data[field].map((data) => ({
                    type: 'list_data',
                    data,
                })));
            }
        }
        else if (queryState.loading) {
            listData.push({
                type: 'list_loading',
            });
        }
        else if (queryState.error) {
            listData.push({
                type: 'list_error',
            });
        }
        return listData;
    }, [queryState]);
    return [listDataState];
}
