import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { ALL_MY_FILTER_VIEWS_QUERY } from './allMyList';
import { IFilterViewScope } from './types';
const CREATE_FILTER_VIEW_MUTATION = gql `
  mutation Community_createFilterView($data: CommunityNewFilterViewInput!) {
    community_createFilterView(data: $data) {
      id
      title
      filters {
        tags
      }
      ctime
      scope
      type
    }
  }
`;
export function useCreateFilterViewMutation(options) {
    return useGQLMutation(CREATE_FILTER_VIEW_MUTATION, {
        ...options,
        update(cache, { data: response }) {
            const newFilterView = response?.community_createFilterView;
            if (newFilterView) {
                cache.updateQuery({ query: ALL_MY_FILTER_VIEWS_QUERY }, (data) => {
                    const { community_getAllMyFilterViews: list } = data ?? {
                        community_getAllMyFilterViews: [],
                    };
                    return {
                        ...data,
                        community_getAllMyFilterViews: [
                            ...list,
                            {
                                // For some reason, scope wasn't present in the filterView passed,
                                // hence adding it manually
                                // @ts-ignore
                                scope: IFilterViewScope.PUBLIC,
                                ...newFilterView,
                            },
                        ],
                    };
                });
            }
        },
    });
}
