import { sanitizeSupportedHTML } from 'src/utils/html';
import { getHTMLContentFromClipboard } from '../utils';
import { useEffect } from 'react';
export default function usePasteSanitizedHTML(inputElemRef) {
    useEffect(() => {
        if (inputElemRef.current) {
            inputElemRef.current.focus();
            inputElemRef.current.addEventListener('paste', ev => {
                ev.preventDefault();
                (async function pasteSanitizedHTML() {
                    const result = await navigator.permissions.query({
                        name: 'clipboard-read',
                    });
                    if (result.state === 'granted' || result.state === 'prompt') {
                        const rawHTML = await getHTMLContentFromClipboard();
                        document.execCommand('insertHTML', false, sanitizeSupportedHTML(rawHTML));
                    }
                })();
            });
        }
    }, []);
}
