import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const UNFOLLOW_QUESTION_MUTATION = gql `
  mutation Community_unfollowQuestion($questionID: String!) {
    community_unfollowQuestion(questionID: $questionID)
  }
`;
export function useUnfollowQuestionMutation(options = {}) {
    return useGQLMutation(UNFOLLOW_QUESTION_MUTATION, {
        ...options,
        update(cache, {}, { variables }) {
            const questionID = variables?.questionID;
            if (questionID) {
                cache.modify({
                    id: cache.identify({
                        __typename: 'CommunityQuestion',
                        id: questionID,
                    }),
                    fields: {
                        isFollowing() {
                            return false;
                        },
                    },
                });
            }
        },
    });
}
