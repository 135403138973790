export const getFieldState = (value) => ({
    value,
    meta: {
        dirty: false,
        disabled: false,
        hidden: false,
        touched: false,
        focused: false,
    },
    errors: [],
});
