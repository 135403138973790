import StorageKeys from 'src/constants/storageKeys';
import { sessionStorage } from 'src/lib/storage';
const userPreferencesStorage = {
    setPrefs(preferences) {
        sessionStorage.set(StorageKeys.userPreferences, preferences);
    },
    getPrefs() {
        return sessionStorage.get(StorageKeys.userPreferences) ?? {};
    },
};
export default userPreferencesStorage;
