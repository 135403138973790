import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const FOLLOW_QUESTION_MUTATION = gql `
  mutation Community_followQuestion($questionID: String!) {
    community_followQuestion(questionID: $questionID)
  }
`;
export function useFollowQuestionMutation(options = {}) {
    return useGQLMutation(FOLLOW_QUESTION_MUTATION, {
        ...options,
        update(cache, {}, { variables }) {
            const questionID = variables?.questionID;
            if (questionID) {
                cache.modify({
                    id: cache.identify({
                        __typename: 'CommunityQuestion',
                        id: questionID,
                    }),
                    fields: {
                        isFollowing() {
                            return true;
                        },
                    },
                });
            }
        },
    });
}
