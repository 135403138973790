import { useEffect, useMemo, useState } from 'react';
import useLayoutAnimation from 'src/hooks/animation/useLayoutAnimation';
import { useCommunityAddReplyMutation } from 'src/modules/community/graphql/reply/add';
import { useHighlightContext } from 'src/modules/community/screens/QuestionDetails/context/highlight';
export default function useData(replies) {
    const highlightContext = useHighlightContext();
    const containsHighlightedReply = useMemo(() => {
        if (!highlightContext) {
            return false;
        }
        if (highlightContext.entityType !== 'reply') {
            return false;
        }
        return (replies.findIndex(reply => reply.id === highlightContext.entityID) > -1);
    }, [replies, highlightContext?.entityID, highlightContext?.entityType]);
    const [isExpanded, setIsExpanded] = useState(containsHighlightedReply || replies.length === 0);
    useEffect(() => {
        if (containsHighlightedReply) {
            setIsExpanded(true);
        }
    }, [containsHighlightedReply]);
    const [showReplyEditor, setShowReplyEditor] = useState(false);
    const layoutAnimation = useLayoutAnimation();
    const [addReplyMutationState, addReplyMutationActions] = useCommunityAddReplyMutation({
        onCompleted() {
            actions.setShowReplyEditor(false);
            layoutAnimation.setupLayoutAnimation();
        },
    });
    const state = {
        isExpanded,
        showReplyEditor,
        addReplyMutation: addReplyMutationState,
    };
    const actions = {
        showAllReplies() {
            layoutAnimation.setupLayoutAnimation();
            setIsExpanded(true);
        },
        setShowReplyEditor(value) {
            layoutAnimation.setupLayoutAnimation();
            setShowReplyEditor(value);
        },
        addReplyMutation: addReplyMutationActions,
    };
    return [state, actions];
}
