import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
const RECOMMENDED_FILTER_VIEWS_LIST_QUERY = gql `
  query Community_getRecommendedFilterViews($pagination: PaginationOptions!) {
    community_getRecommendedFilterViews(pagination: $pagination) {
      totalCount
      list {
        id
        type
        filters {
          tags
        }
        title
      }
    }
  }
`;
export function useRecommendedFilterViewsListQuery() {
    return useGQLPaginatedQuery(RECOMMENDED_FILTER_VIEWS_LIST_QUERY, 'community_getRecommendedFilterViews', {
        variables: {
            pagination: {
                offset: 0,
            },
        },
    });
}
addTypePolicies({
    Query: {
        fields: {
            community_getRecommendedFilterViews: paginatedListFieldPolicy([]),
        },
    },
});
