import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const COMMUNITY_EDIT_QUESTION_MUTATION = gql `
  mutation Community_editQuestion(
    $id: String!
    $title: String!
    $description: String!
    $format: String!
    $tags: [String!]!
    $IMO: String
  ) {
    community_editQuestion(
      id: $id
      title: $title
      description: $description
      format: $format
      tags: $tags
      IMO: $IMO
    ) {
      id
      data {
        format
        title
        description
        shortDescription
        tags
        slug
        votes
        IMO
      }
    }
  }
`;
export function useCommunityEditQuestionMutation(options) {
    return useGQLMutation(COMMUNITY_EDIT_QUESTION_MUTATION, options);
}
