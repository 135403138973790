import { compile, match } from 'path-to-regexp';
const questionDetailsDL = 'community/question/details/:questionID';
export const communityQuestionDetailsMatcher = match(questionDetailsDL);
export const getCommunityQuestionDetailsDL = (questionID) => {
    const toPath = compile(questionDetailsDL);
    return toPath({
        questionID,
    });
};
export const communityStackLinking = {
    screens: {
        Community_QuestionForm: {
            path: 'community/question/new',
        },
        Community_QuestionDetails: {
            path: questionDetailsDL,
        },
    },
};
export const communityTabsLinking = {
    screens: {
        Community_Home: {
            path: 'community/home',
        },
    },
};
