import React from 'react';
import { RootStack } from 'src/navigation/RootNavigation';
import QuestionDetails from './screens/QuestionDetails';
import QuestionForm from './screens/QuestionForm';
import TopicsGroupForm from './screens/TopicsGroupForm';
import TopicsGroups from './screens/TopicsGroups';
import Onboarding from './tutorials/Onboarding';
export const renderCommunityStackRoutes = () => {
    return (React.createElement(RootStack.Group, null,
        React.createElement(RootStack.Screen, { name: "Community_QuestionForm", component: QuestionForm }),
        React.createElement(RootStack.Screen, { name: "Community_QuestionDetails", component: QuestionDetails }),
        React.createElement(RootStack.Screen, { name: "Community_TopicsGroups", component: TopicsGroups }),
        React.createElement(RootStack.Screen, { name: "Community_TopicsGroupForm", component: TopicsGroupForm }),
        React.createElement(RootStack.Screen, { name: "Community_Onboarding", component: Onboarding })));
};
