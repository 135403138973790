import React, { useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, ScrollView } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import Page from 'src/components/templates/page/Page.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Analytics from 'src/lib/analytics';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import styleUtils from 'src/utils/styles';
import HowItWorks from './screens/HowItWorks';
import Welcome from './screens/Welcome';
import WhatIsCommunity from './screens/WhatIsCommunity';
const pageAnalytics = Analytics.event.builder().setPage('Community_Onboarding');
const Onboarding = ({ navigation }) => {
    const [{}] = useTheme(stylesGenerator);
    const scrollPos = useRef(new Animated.Value(0)).current;
    const [pageHeight, setPageHeight] = useState(-1);
    const [currStep, setCurrStep] = useState(1);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrStep(2);
        }, 4000);
        return () => clearTimeout(timer);
    }, [pageHeight]);
    const containerScrollViewRef = useRef();
    useEffect(() => {
        if (currStep === 1) {
            // Do nothing because the view is initially here itself
        }
        else if (currStep === 2) {
            containerScrollViewRef.current?.scrollTo({
                y: pageHeight,
                animated: true,
            });
        }
    }, [currStep]);
    const horizontalScrollRef = useRef(null);
    useEffect(() => {
        if (currStep === 2) {
            horizontalScrollRef.current?.scrollTo({
                x: 0,
                animated: true,
            });
        }
        if (currStep === 3) {
            horizontalScrollRef.current?.scrollTo({
                x: Dimensions.get('window').width,
                animated: true,
            });
        }
    }, [currStep]);
    const [, ftueActions] = useFTUE(FTUEKeys.COMMUNITY_ONBOARADING);
    const gotoCommunityHome = () => {
        ftueActions.markComplete();
        navigation.navigate('Community_Home', {});
    };
    return (React.createElement(Page, null,
        React.createElement(Animated.ScrollView, { ref: containerScrollViewRef, onLayout: ev => setPageHeight(ev.nativeEvent.layout.height), style: [styleUtils.containerHeight], showsVerticalScrollIndicator: false, scrollEnabled: false, scrollEventThrottle: 16, bounces: false, onScroll: Animated.event([
                {
                    nativeEvent: {
                        contentOffset: {
                            y: scrollPos,
                        },
                    },
                },
            ], {
                useNativeDriver: true,
            }) }, pageHeight > -1 && (React.createElement(Column, null,
            React.createElement(Welcome, { height: pageHeight, scrollPos: scrollPos }),
            React.createElement(ScrollView, { ref: horizontalScrollRef, horizontal: true, showsHorizontalScrollIndicator: false, scrollEnabled: false, bounces: false },
                React.createElement(WhatIsCommunity, { isActive: currStep === 2, height: pageHeight, onSkip: () => {
                        pageAnalytics
                            .setWidget('Navigation')
                            .setTarget('Skip')
                            .click();
                        gotoCommunityHome();
                    }, onNext: () => setCurrStep(3) }),
                React.createElement(HowItWorks, { height: pageHeight, onPrev: () => setCurrStep(2), onGetStarted: () => {
                        pageAnalytics
                            .setWidget('Navigation')
                            .setTarget('GetStarted')
                            .click();
                        gotoCommunityHome();
                    } })))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default Onboarding;
