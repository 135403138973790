import { useState } from 'react';
import useLayoutAnimation from 'src/hooks/animation/useLayoutAnimation';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import { useCommunityDeleteReplyMutation } from 'src/modules/community/graphql/reply/delete';
import { useCommunityEditReplyMutation } from 'src/modules/community/graphql/reply/edit';
import { useHighlightContext } from 'src/modules/community/screens/QuestionDetails/context/highlight';
export default function useData(reply) {
    const [currUser] = useUserStorage();
    const [editMode, setEditMode] = useState(false);
    const layoutAnimation = useLayoutAnimation();
    const [editReplyMutationState, editReplyMutationActions] = useCommunityEditReplyMutation({
        onCompleted() {
            actions.editMode.off();
        },
    });
    const [deleteReplyMutationState, deleteReplyMutationActions] = useCommunityDeleteReplyMutation({
        onCompleted() {
            layoutAnimation.setupLayoutAnimation();
        },
    });
    const highlightContext = useHighlightContext();
    const shouldHighlight = highlightContext.entityType === 'reply' &&
        highlightContext.entityID === reply.id;
    const state = {
        highlight: shouldHighlight,
        isAuthor: currUser?.id === reply.author.id,
        editReplyMutation: editReplyMutationState,
        deleteReplyMutation: deleteReplyMutationState,
        editMode,
    };
    const actions = {
        editReplyMutation: editReplyMutationActions,
        deleteReplyMutation: deleteReplyMutationActions,
        editMode: {
            on: () => setEditMode(true),
            off: () => setEditMode(false),
        },
    };
    return [state, actions];
}
