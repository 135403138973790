import { CommonActions } from '@react-navigation/native';
import React, { useEffect } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import Form from 'src/components/10-atoms/form/Form';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import TopicInput from 'src/components/10-atoms/form/inputs/topics-input';
import Message from 'src/components/10-atoms/info/Message';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import Analytics from 'src/lib/analytics';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import QuestionCreateSuccessDrawer from '../../components/QuestionCreateSuccessDrawer';
import QuestionGuidelinesDrawer from '../../tutorials/guidelines/QuestionGuidelines';
import { showGuidelinesDrawer } from '../../tutorials/utils';
import { ValidationConfig } from './config';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
import HTMLEditor from 'src/components/10-atoms/form/inputs/editor/html/HTMLEditor';
export var QuestionFormFlow;
(function (QuestionFormFlow) {
    QuestionFormFlow[QuestionFormFlow["NEW"] = 1] = "NEW";
    QuestionFormFlow[QuestionFormFlow["EDIT"] = 2] = "EDIT";
})(QuestionFormFlow || (QuestionFormFlow = {}));
function isEditQuestionNavParams(params) {
    return params.flow === QuestionFormFlow.EDIT;
}
export const questionFormPageAnalytics = Analytics.event
    .builder()
    .setPage('Community_QuestionForm');
const QuestionForm = ({ navigation, route }) => {
    const { flow = QuestionFormFlow.NEW } = route.params ?? {};
    const question = route.params.flow === QuestionFormFlow.EDIT
        ? route.params.question
        : undefined;
    const isNewFlow = QuestionFormFlow.NEW === flow;
    const { translate } = useI18n();
    const [state, actions] = useData(flow, isEditQuestionNavParams(route.params) ? route.params.question : undefined);
    useFTUE(FTUEKeys.COMMUNITY_QUESTION_GUIDELINES, {
        onFTUE(markComplete) {
            if (isNewFlow) {
                showGuidelinesDrawer(QuestionGuidelinesDrawer).then(() => markComplete());
            }
        },
    });
    const gotoQuestionDetails = (questionID) => {
        /**
         * Intention of the below is to remove the form routes from the stack
         * and retain only the Question Details route.
         *
         * There are two flows, viz: Create & Edit
         *
         * `Create` flow:
         * In this flow, a User might arrive at this page from
         * Community_Home page.
         * In this cases, once the Question Creation is complete,
         * we must remove Question Form from the stack and then navigate
         * the User to Community_QuestionDetails page.
         *
         * `Edit` flow:
         * This is a bit tricky because a User will arrive at this page from
         * Community_QuestionDetails page. Hence post Question Creation, we
         * must not only remove Question Form, but also find the existing
         * Community_QuestionDetails in the stack and navigate to the same
         * route, so as to avoid duplicate routes in back stack.
         *
         */
        navigation.dispatch(navState => {
            const existingQuestionDetailsIndex = navState.routes.findIndex(navRoute => navRoute.name === 'Community_QuestionDetails');
            const routes = [
                ...navState.routes.slice(0, existingQuestionDetailsIndex > -1 ? existingQuestionDetailsIndex : -1),
                {
                    name: 'Community_QuestionDetails',
                    params: {
                        questionID,
                    },
                },
            ];
            return CommonActions.reset({
                ...navState,
                routes: routes,
                index: existingQuestionDetailsIndex > -1
                    ? existingQuestionDetailsIndex
                    : navState.index,
            });
        });
    };
    useEffect(() => {
        if (!state.createQuestionMutation.loading) {
            if (state.createQuestionMutation.data) {
                helperViewActions.bottomDrawer.show({
                    id: 'question_create_success',
                    duration: 7000,
                    component: (React.createElement(QuestionCreateSuccessDrawer, { onDismiss: helperViewActions.bottomDrawer.hide })),
                });
                // Initially we thought of navigating the user back to
                // Community_Home page, hence we issue a refresh event,
                // so that the newly created Question would appear on the
                // List. But later we decided to navigate the user to
                // QuestionDetails page and hence the below line was commented.
                // SE.publish('community/home/question_list/refresh');
                gotoQuestionDetails(state.createQuestionMutation.data.community_createQuestion.id);
            }
        }
    }, [state.createQuestionMutation.data, state.createQuestionMutation.loading]);
    useEffect(() => {
        if (!state.editQuestionMutation.loading) {
            if (state.editQuestionMutation.data) {
                helperViewActions.snackbar.show({
                    variant: 'POSITIVE',
                    duration: 'SHORT',
                    message: translate('Question has been updated successfully'),
                });
                gotoQuestionDetails(question.id);
            }
        }
    }, [state.editQuestionMutation.data, state.editQuestionMutation.loading]);
    return (React.createElement(Page, null,
        React.createElement(CustomScrollView, { renderCollapsibleHeader: () => (React.createElement(PageNavbar, { title: flow === QuestionFormFlow.NEW
                    ? translate('Ask Question')
                    : translate('Edit Question'), backType: "back", onBackPress: navigation.goBack })), collapsibleHeaderHeight: NAVBAR_HEIGHT },
            React.createElement(Column, null,
                React.createElement(Column, null,
                    React.createElement(Message, null, translate("Hope you've already searched for this Question")),
                    React.createElement(Form, null,
                        React.createElement(Field, { label: translate('Title'), error: state.form.fields.title.errors[0]?.message },
                            React.createElement(TextInput, { value: state.form.fields.title.value, onChange: actions.form.fields.title.setValue, placeholder: translate('Main engine MAN B&W 6S60ME-C, unit not firing') })),
                        React.createElement(Field, { label: translate('Description'), error: state.form.fields.description.errors[0]?.message },
                            React.createElement(HTMLEditor, { title: 'TODO fix this', placeholder: translate("After changing the fuel valve, the unit doesn't fire. Tried changing the fuel valve again, however the problem still persists."), html: state.form.fields.description.value, onSubmit: newValue => {
                                    actions.form.fields.description.setValue(newValue);
                                }, onClose: () => { } })),
                        React.createElement(Field, { label: translate('Topics'), error: state.form.fields.tags.errors[0]?.message },
                            React.createElement(TopicInput, { org: "ms-community", entityID: "ms-community", placeholder: translate('Click to Add Topics for the Question'), maxSelectedTopics: ValidationConfig.MAX_TAGS, value: state.form.fields.tags.value, onChange: actions.form.fields.tags.setValue })),
                        React.createElement(Button, { loading: state.createQuestionMutation.loading ||
                                state.editQuestionMutation.loading, colorScheme: isNewFlow ? 'positive' : 'normal', onPress: actions.form.handleSubmit }, isNewFlow
                            ? translate('Create Question')
                            : translate('Update Question'))))))));
};
export default QuestionForm;
