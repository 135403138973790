import React from 'react';
import { Platform } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import FormulaIcon from 'src/components/10-atoms/icons/FormulaIcon';
import Row from 'src/components/10-atoms/layout/Row';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import ToolbarItem from './Item';
import { ToolbarAction } from './types';
import OrderedListIcon from 'src/components/10-atoms/icons/OrderedListIcon';
const Toolbar = ({ onActionPress, ...rowCenterProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { style: styles.container, ...rowCenterProps },
        React.createElement(Row, { yAxis: "center", style: [styles.bar, styleUtils.cardShadow] },
            React.createElement(ToolbarItem, { icon: props => React.createElement(FeatherIcon, { name: "bold", ...props }), onPress: () => onActionPress(ToolbarAction.BOLD) }),
            React.createElement(ToolbarItem, { icon: props => React.createElement(FeatherIcon, { name: "italic", ...props }), onPress: () => onActionPress(ToolbarAction.ITALIC) }),
            React.createElement(ToolbarItem, { icon: props => React.createElement(FeatherIcon, { name: "list", ...props }), onPress: () => onActionPress(ToolbarAction.UNORDERED_LIST) }),
            React.createElement(ToolbarItem, { icon: OrderedListIcon, onPress: () => onActionPress(ToolbarAction.ORDERED_LIST) }),
            React.createElement(ToolbarItem, { icon: props => React.createElement(FeatherIcon, { name: "image", ...props }), onPress: () => onActionPress(ToolbarAction.IMAGE) }),
            React.createElement(ToolbarItem, { icon: FormulaIcon, onPress: () => onActionPress(ToolbarAction.FORMULA) }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        position: 'absolute',
        bottom: theme.spacing.standard.xs,
        left: 0,
        right: 0,
    },
    bar: {
        borderRadius: PRESSABLE_SIZE,
        paddingHorizontal: theme.spacing.standard.md,
        ...Platform.select({ web: styleUtils.cardShadow }),
        backgroundColor: theme.palette.background.dark,
    },
}));
export default Toolbar;
