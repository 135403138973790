import React from 'react';
import Badge from 'src/components/10-atoms/badge/Badge';
import BadgeText from 'src/components/10-atoms/badge/BadgeText';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import TopicTagList from 'src/components/10-atoms/topics/TopicTagList';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import { IFilterViewType } from '../../graphql/types';
import useI18n from 'src/modules/translations/hooks/useI18n';
const FilterViewLayout = ({ title, topics, listType, added, onAdd, addingInProgress, type, ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const isRecommendedList = listType === 'recommendation';
    const showAddBtn = isRecommendedList && onAdd;
    const showBadge = [
        IFilterViewType.PRE_DEFINED,
        IFilterViewType.RECOMMENDED,
    ].includes(type);
    const badgeTitle = type === IFilterViewType.PRE_DEFINED ? 'Pre-Included' : 'Recommended';
    return (React.createElement(Column, { ph: theme.spacing.page, pv: theme.spacing.itemVerticalPadding, ...columnProps },
        showBadge && (React.createElement(Row, { mb: theme.spacing.standard.xxs },
            React.createElement(Badge, { style: styles.recommendedBadge },
                React.createElement(BadgeText, null, badgeTitle)))),
        React.createElement(Column, { spacing: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.BODY, fontWeight: TypographyFontWeight.MEDIUM }, title),
            React.createElement(TopicTagList, { topics: topics, maxVisible: !isRecommendedList ? 10 : undefined }),
            showAddBtn && (React.createElement(Button, { flex: true, colorScheme: "positive", variant: "outline", disabled: added, LeftIcon: ({ disabled }) => (React.createElement(FeatherIcon, { name: added ? 'check' : 'plus', size: theme.iconSize.sm, disabled: disabled, color: disabled
                        ? theme.palette.pressable.disabledText
                        : theme.palette.typography.primary })), loading: addingInProgress, onPress: onAdd }, added ? translate('Added') : translate('Add'))))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    recommendedBadge: {
        backgroundColor: theme.palette.background.darker,
    },
}));
export default withPress(FilterViewLayout);
