import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Overlay from 'src/components/10-atoms/overlays/Overlay';
const OverlayHelperView = ({ component, ...props }) => {
    const [{}] = useTheme(stylesGenerator);
    return React.createElement(Overlay, { ...props }, component);
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default OverlayHelperView;
