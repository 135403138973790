import React from 'react';
import { Path } from 'react-native-svg';
import withPress from 'src/hoc/withPress';
import SVGIcon from './SVGIcon';
import withDefaultIconProps from './withDefaultIconProps';
const ChevronIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { d: "M15 6.5L9 12l6 5.5", stroke: color, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })));
};
export default withPress(withDefaultIconProps(ChevronIcon));
