import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { isExternalLink, isValidLink } from 'src/utils/url';
import Field from '../../form/Field';
import useFieldState from '../../form/hooks/useFieldState';
import TextInput from '../../form/inputs/TextInput';
const FileLinkInputDrawer = ({ onCancel, onConfirm, }) => {
    const [fieldState, fieldActions] = useFieldState('');
    const handleConfirm = () => {
        if (fieldState.value.trim().length === 0) {
            fieldActions.setError({
                type: 'error',
                message: 'Please enter the link for the file',
            });
        }
        else if (!isValidLink(fieldState.value) ||
            !isExternalLink(fieldState.value)) {
            fieldActions.setError({
                type: 'error',
                message: 'Please enter a valid link',
            });
        }
        else {
            onConfirm(fieldState.value);
        }
    };
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Link"),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Field, { label: "Paste the link here", error: fieldState.errors[0]?.message },
                React.createElement(TextInput, { autoFocus: true, keyboardType: "url", placeholder: "http(s)://", value: fieldState.value, onChange: fieldActions.setValue }))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { variant: "text", onPress: onCancel }, "Cancel"),
            React.createElement(Button, { onPress: handleConfirm }, "Add"))));
};
export default FileLinkInputDrawer;
