import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const FadeInBackground = ({ dragX }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const opacity = dragX.interpolate({
        inputRange: [-100, 0, 100],
        outputRange: [0, 1, 0],
        extrapolate: 'clamp',
    });
    return (React.createElement(Animated.View, { style: [StyleSheet.absoluteFill, styles.container, { opacity }] }));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
}));
export default FadeInBackground;
