import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ChevronRightIcon from 'src/components/10-atoms/icons/ChevronRightIcon';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
const TutorialNavigation = ({ isFirst, isLast, onNext, onPrev, onSkip, onGetStarted, ...rowProps }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(Row, { xAxis: "space-between", ph: theme.spacing.page, pv: theme.spacing.standard.xs, ...rowProps }, (() => {
        if (isLast) {
            return (React.createElement(Button, { flex: true, onPress: onGetStarted }, translate("Let's get started")));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "text", onPress: isFirst ? onSkip : onPrev }, isFirst ? translate('Skip') : translate('Prev')),
            React.createElement(Button, { onPress: onNext, RightIcon: () => (React.createElement(ChevronRightIcon, { color: theme.palette.primary.contrast })) }, translate('Next'))));
    })()));
};
export default TutorialNavigation;
