import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import BlockContentDrawer from '../components/BlockContentDrawer';
import { useBlockContentMutation } from '../graphql/blockContent';
export default function useBlockContent(...args) {
    const [blockContentMutationState, blockContentMutationActions] = useBlockContentMutation(...args);
    const block = (entity) => {
        helperViewActions.bottomDrawer.show({
            component: (React.createElement(BlockContentDrawer, { onConfirm: () => {
                    helperViewActions.bottomDrawer.hide();
                    blockContentMutationActions.submit({
                        variables: {
                            entity,
                        },
                    });
                } })),
        });
    };
    return [blockContentMutationState, { block }];
}
