import React from 'react';
import { Platform } from 'react-native';
import FeatherIcon from './FeatherIcon';
import withDefaultIconProps from './withDefaultIconProps';
const ShareIcon = props => {
    return (React.createElement(FeatherIcon, { name: Platform.select({
            ios: 'share',
        }) ?? 'share-2', ...props }));
};
export default withDefaultIconProps(ShareIcon);
