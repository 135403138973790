import React from 'react';
import { Path } from 'react-native-svg';
import SVGIcon from './SVGIcon';
import withDefaultIconProps from './withDefaultIconProps';
const BoatIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { d: "M1.29 13.46a.3.3 0 0 1 .253-.46h21.049a.3.3 0 0 1 .286.39l-.537 1.709a7 7 0 0 1-6.678 4.9h-5.29a9 9 0 0 1-7.62-4.21L1.29 13.46ZM13 1 5.367 11.018a.3.3 0 0 0 .239.482H12.7a.3.3 0 0 0 .3-.3V1Z", fill: color }),
        React.createElement(Path, { d: "M12 11h1v2h-1v-2ZM14 11.2a.3.3 0 0 0 .3.3h3.51a.3.3 0 0 0 .26-.45l-3.51-6.08c-.153-.266-.56-.157-.56.15v6.08Z", fill: color })));
};
export default withDefaultIconProps(BoatIcon);
