import { sessionStorage } from '../storage';
import { getCacheKey, getData, isCacheKey, isStale, setData, setMeta, } from './utils';
/**
 * Cache structure
 *
 * {
 *    <hash_key>: {
 *      __meta__: {
 *        expiresAt: ExpiresAt;
 *      }
 *      data,
 *    }
 * }
 */
const Cache = {
    has(key) {
        const cacheKey = getCacheKey(key);
        const exists = sessionStorage.contains(cacheKey);
        if (!exists) {
            return false;
        }
        const cachedValue = sessionStorage.get(cacheKey);
        if (isStale(cachedValue)) {
            sessionStorage.delete(cacheKey);
            return false;
        }
        return true;
    },
    get(key) {
        if (this.has(key)) {
            const cacheKey = getCacheKey(key);
            const cachedValue = sessionStorage.get(cacheKey);
            return getData(cachedValue);
        }
        return undefined;
    },
    set(key, data, ttl /* in seconds */) {
        const cacheValue = setData(setMeta({}, {
            expiresAt: Date.now() + ttl * 1000,
        }), data);
        sessionStorage.set(getCacheKey(key), cacheValue);
    },
    evictStale() {
        const cacheKeys = sessionStorage.getAllKeys().filter(isCacheKey);
        cacheKeys.forEach(key => {
            const value = sessionStorage.get(key);
            if (isStale(value)) {
                sessionStorage.delete(key);
            }
        });
    },
};
export default Cache;
