import usePersistedState from 'src/hooks/globalState/usePersistedState';
import { NotificationUnreadCountPageSize } from '../graphql/in-app/unread-count';
import { UNREAD_NOTIFICATION_COUNT_KEY } from '../storage/constants';
export default function useUnreadNotificationCount() {
    const [state, setState] = usePersistedState('session', UNREAD_NOTIFICATION_COUNT_KEY);
    const count = state ?? 0;
    const countState = {
        count,
        display: count >= NotificationUnreadCountPageSize
            ? `${NotificationUnreadCountPageSize}+`
            : String(count),
    };
    return [countState, setState];
}
