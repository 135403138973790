import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { RN_HANDLER, } from './types';
import useSeq from 'src/hooks/useSeq';
import { useCallback, useRef } from 'react';
import { log, warn } from './logger';
function useActionsRegistry() {
    const actionsRef = useRef({});
    const add = useCallback((id, promise) => {
        actionsRef.current[id] = {
            promise,
        };
    }, []);
    const contains = useCallback((id) => {
        return id in actionsRef.current;
    }, []);
    const pop = useCallback((id) => {
        const action = actionsRef.current[id];
        delete actionsRef.current[id];
        return action;
    }, []);
    return { add, contains, pop };
}
export default function useRN2WebCom(options) {
    const actionsRegistry = useActionsRegistry();
    const sendMessage = useCallbackWithCtx((ctx, id, message) => {
        options.webviewRef.current?.postMessage(JSON.stringify({
            id,
            ...message,
        }));
    }, {});
    const getId = useSeq();
    const sendAction = useCallbackWithCtx((ctx, handler, data) => {
        const id = getId();
        sendMessage(id, {
            type: 'ACTION',
            data: {
                handler,
                data,
            },
        });
        return new Promise((resolve, reject) => {
            actionsRegistry.add(id, {
                resolve,
                reject,
            });
        });
    }, {});
    const sendResponse = useCallbackWithCtx((ctx, id, response) => {
        sendMessage(id, {
            type: 'RESPONSE',
            data: response,
        });
    }, {});
    const handleMessage = useCallbackWithCtx(async (ctx, event) => {
        const payload = JSON.parse(event.nativeEvent.data);
        // log('Handling Message:', payload);
        if (payload.type === 'ACTION') {
            handleAction(payload);
        }
        else if (payload.type === 'RESPONSE') {
            handleResponse(payload);
        }
        else {
            warn('Unknown payload.type:', payload);
        }
    }, {});
    const handleAction = useCallbackWithCtx(async (ctx, payload) => {
        try {
            const result = payload.data.handler === RN_HANDLER.LOG
                ? log('[Web]', ...payload.data.data.args)
                : await options.onActionMessage(payload);
            sendResponse(payload.id, { data: result });
        }
        catch (error) {
            sendResponse(payload.id, { error: error });
        }
    }, {});
    const handleResponse = useCallbackWithCtx((ctx, payload) => {
        if (actionsRegistry.contains(payload.id)) {
            const action = actionsRegistry.pop(payload.id);
            if (payload.data.error) {
                action.promise.reject(payload.data.error);
            }
            else {
                action.promise.resolve(payload.data.data);
            }
        }
    }, {});
    return { handleMessage, sendAction };
}
