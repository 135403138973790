export var WEB_VARIANT;
(function (WEB_VARIANT) {
    WEB_VARIANT[WEB_VARIANT["RENDERER"] = 1] = "RENDERER";
    WEB_VARIANT[WEB_VARIANT["EDITOR"] = 2] = "EDITOR";
    WEB_VARIANT[WEB_VARIANT["WEB_RENDERER"] = 3] = "WEB_RENDERER";
})(WEB_VARIANT || (WEB_VARIANT = {}));
export var RN_HANDLER;
(function (RN_HANDLER) {
    RN_HANDLER[RN_HANDLER["LOG"] = 0] = "LOG";
    RN_HANDLER[RN_HANDLER["RENDER_COMPLETED"] = 1] = "RENDER_COMPLETED";
    RN_HANDLER[RN_HANDLER["DOCUMENT_HEIGHT"] = 2] = "DOCUMENT_HEIGHT";
    RN_HANDLER[RN_HANDLER["IMG_CLICK"] = 3] = "IMG_CLICK";
    RN_HANDLER[RN_HANDLER["MATH_FIELD_FOCUSED"] = 4] = "MATH_FIELD_FOCUSED";
    RN_HANDLER[RN_HANDLER["MATH_FIELD_BLURRED"] = 5] = "MATH_FIELD_BLURRED";
    RN_HANDLER[RN_HANDLER["LINK_CLICK"] = 6] = "LINK_CLICK";
})(RN_HANDLER || (RN_HANDLER = {}));
export var WEB_HANDLER;
(function (WEB_HANDLER) {
    WEB_HANDLER[WEB_HANDLER["EXEC_COMMAND"] = 1] = "EXEC_COMMAND";
    WEB_HANDLER[WEB_HANDLER["INSERT_MATH_FIELD"] = 2] = "INSERT_MATH_FIELD";
    WEB_HANDLER[WEB_HANDLER["GET_CONTENT"] = 3] = "GET_CONTENT";
    WEB_HANDLER[WEB_HANDLER["INSERT_IMG_UPLOAD_PROGRESS"] = 4] = "INSERT_IMG_UPLOAD_PROGRESS";
    WEB_HANDLER[WEB_HANDLER["UPDATE_IMG_UPLOAD_PROGRESS"] = 5] = "UPDATE_IMG_UPLOAD_PROGRESS";
    WEB_HANDLER[WEB_HANDLER["REPLACE_IMG_UPLOAD_PROGRESS_WITH_IMAGE"] = 6] = "REPLACE_IMG_UPLOAD_PROGRESS_WITH_IMAGE";
    WEB_HANDLER[WEB_HANDLER["EXTRACT_TTS_CONTENT"] = 7] = "EXTRACT_TTS_CONTENT";
})(WEB_HANDLER || (WEB_HANDLER = {}));
export var HTMLRendererVariant;
(function (HTMLRendererVariant) {
    HTMLRendererVariant[HTMLRendererVariant["READING"] = 1] = "READING";
    HTMLRendererVariant[HTMLRendererVariant["TTS"] = 2] = "TTS";
})(HTMLRendererVariant || (HTMLRendererVariant = {}));
