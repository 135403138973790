import React, { useRef } from 'react';
import HTMLRenderer from 'src/components/10-atoms/form/inputs/editor/html/HTMLRenderer';
import { HTMLRendererVariant } from 'src/components/10-atoms/form/inputs/editor/html/common/types';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
const HTMLTTSProcessor = ({ html, onCompleted }) => {
    const htmlRendererRef = useRef(null);
    const handleRenderComplete = useCallbackWithCtx(async (ctx) => {
        const text = await htmlRendererRef.current?.getTTSContent();
        ctx.onCompleted(text ?? '');
    }, { onCompleted });
    return (React.createElement(HTMLRenderer, { variant: HTMLRendererVariant.TTS, ref: htmlRendererRef, html: html, onRenderComplete: handleRenderComplete }));
};
export default HTMLTTSProcessor;
