import StorageKeys from 'src/constants/storageKeys';
import { sessionStorage } from 'src/lib/storage';
import { IUserRole } from '../../apis/signin/types';
import { decodeJWT } from './utils';
const tokenStorage = {
    setToken(token) {
        sessionStorage.set(StorageKeys.userAuthToken, token);
    },
    getToken() {
        return sessionStorage.get(StorageKeys.userAuthToken);
    },
    getRoles() {
        const token = this.getToken();
        const payload = decodeJWT(token);
        return payload?.roles ?? [IUserRole.USER];
    },
    getUserID() {
        const token = this.getToken();
        const payload = decodeJWT(token);
        return payload?.sub;
    },
};
export default tokenStorage;
