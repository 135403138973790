import React from 'react';
import { ActivityIndicator } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import NumberUtils from 'src/utils/number';
import FeatherIcon from '../icons/FeatherIcon';
import Row from '../layout/Row';
import RowCenter from '../layout/RowCenter';
import Typography from '../typography/Typography';
import VoteButton, { VOTES_BUTTON_SIZE } from './VoteButton';
const Votes = ({ votes, votingInProgress, hasVoted, onDownVote, onUpVote, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const actionsEnabled = onDownVote || onUpVote;
    return (React.createElement(Row, { ...rowProps },
        React.createElement(RowCenter, { spacing: 4, style: styles.container },
            actionsEnabled && (React.createElement(VoteButton, { disabled: !onDownVote || hasVoted === -1 || votingInProgress, Icon: props => React.createElement(FeatherIcon, { name: "thumbs-down", ...props }), onPress: onDownVote })),
            votingInProgress ? (React.createElement(ActivityIndicator, { style: styles.votesCountContainer, size: "small" })) : (React.createElement(RowCenter, { style: styles.votesCountContainer, spacing: theme.spacing.standard.sm },
                !actionsEnabled && (React.createElement(FeatherIcon, { name: votes >= 0 ? 'thumbs-up' : 'thumbs-down', size: theme.iconSize.sm })),
                React.createElement(Typography, { style: styles.votesCount, color: votes > 0
                        ? theme.palette.positive.main
                        : votes < 0
                            ? theme.palette.negative.main
                            : theme.palette.neutral.main }, NumberUtils.abbreviate(votes, {
                    abbrFormat: NumberUtils.AbbreviateFormat.INTL,
                    maxValueLength: 2,
                })))),
            actionsEnabled && (React.createElement(VoteButton, { disabled: !onUpVote || hasVoted === 1 || votingInProgress, Icon: props => React.createElement(FeatherIcon, { name: "thumbs-up", ...props }), onPress: onUpVote })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderWidth: 1,
        borderColor: theme.palette.background.dark,
        borderRadius: (VOTES_BUTTON_SIZE + 2) / 2,
        height: VOTES_BUTTON_SIZE,
        minWidth: VOTES_BUTTON_SIZE,
    },
    votesCountContainer: {
        paddingHorizontal: theme.spacing.standard.xs,
        minWidth: 32,
    },
    votesCount: {
        textAlign: 'center',
    },
}));
export default Votes;
