import { useCallback, useState } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
function isAutoCloseData(data) {
    return Boolean(data.id &&
        data.duration);
}
export default function useBottomDrawer() {
    const [state, setState] = useState({
        visible: false,
        component: null,
    });
    const show = useCallback((bottomDrawerData) => {
        if (isAutoCloseData(bottomDrawerData)) {
            setTimeout(() => {
                hide(bottomDrawerData.id);
            }, bottomDrawerData.duration);
        }
        setState({
            ...bottomDrawerData,
            visible: true,
        });
    }, []);
    const hide = useCallbackWithCtx((ctx, id) => {
        if (typeof id === 'string') {
            if (ctx.state.id !== id) {
                return;
            }
        }
        ctx.state.onClose && ctx.state.onClose();
        setState(prevState => ({
            // Retaining prevState, so that the component will be visible
            // during fade & slide animation
            ...prevState,
            visible: false,
            // component: null,
        }));
    }, { state });
    useSubscription('/helper-views/bottom-drawer/show', show);
    useSubscription('/helper-views/bottom-drawer/hide', hide);
    const actions = {
        show,
        hide,
    };
    return [state, actions];
}
export const bottomDrawerHelperActions = {
    show(data) {
        SE.publish('/helper-views/bottom-drawer/show', data);
    },
    hide(id) {
        SE.publish('/helper-views/bottom-drawer/hide', id);
    },
};
