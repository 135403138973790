import { getEventName } from './utils';
import AnalyticsAdaptor from './adaptors';
/**
 * Usage:
 * Analytics.sendEvent(customEvent, props);
 *             OR
 * const PageEvents = Anayltics.event.builder().setPage();
 * PageEvents.visited();
 * const widget = PageEvents.setWidget()
 * widget.setTarget('t1').click();
 * widget.setTarget('t2').select();
 */
const Analytics = {
    login(userID, properties) {
        AnalyticsAdaptor.login(userID, properties);
    },
    logout() {
        AnalyticsAdaptor.logout();
    },
    sendEvent(event, properties) {
        if (__DEV__) {
            console.log(`[AnalyticsEvent] => Event: ${getEventName(event)}${properties ? `Properties: ${JSON.stringify(properties)}` : ''}`);
        }
        else {
            AnalyticsAdaptor.sendEvent(event, properties);
        }
    },
    sendScreenView(screenName) {
        if (__DEV__) {
            console.log(`[AnalyticsEvent] => ScreenView: ${screenName}`);
        }
        else {
            AnalyticsAdaptor.sendScreenView(screenName);
        }
    },
    event: {
        builder() {
            return {
                setPage(page) {
                    return {
                        visited() {
                            Analytics.sendScreenView(page);
                        },
                        setWidget(widget) {
                            return {
                                setTarget(target) {
                                    return {
                                        plain(params) {
                                            Analytics.sendEvent({ page, widget, target, ...params });
                                        },
                                        custom(action, params) {
                                            Analytics.sendEvent({
                                                action,
                                                page,
                                                widget,
                                                target,
                                                ...params,
                                            });
                                        },
                                        success(params) {
                                            this.custom('success', params);
                                        },
                                        failed(params) {
                                            this.custom('failed', params);
                                        },
                                        click(params) {
                                            this.custom('click', params);
                                        },
                                        change(params) {
                                            this.custom('change', params);
                                        },
                                    };
                                },
                            };
                        },
                    };
                },
            };
        },
    },
};
export default Analytics;
