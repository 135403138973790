import React, { useEffect } from 'react';
import WebView from 'react-native-webview';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { WEB_HANDLER } from '../../common/types';
import prettifyHtml from '../../common/utils/prettifyHtml';
import Toolbar from '../Toolbar';
import useData from './useData';
const NativeHTMLEditor = ({ title, html, placeholder, onClose, onSubmit, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const [state, actions] = useData(html, placeholder);
    const handleSubmit = useCallbackWithCtx(async (ctx) => {
        try {
            const content = (await ctx.rn2WebCom.sendAction(WEB_HANDLER.GET_CONTENT, {}));
            onSubmit(prettifyHtml(content));
        }
        catch (error) {
            console.error('Failed to get HTML Editor Content:', error);
        }
    }, { rn2WebCom: actions.rn2WebCom });
    useEffect(() => {
        state.webviewRef.current?.requestFocus();
    }, []);
    return (React.createElement(Column, { flex: true, ...columnProps },
        React.createElement(OverlayLayout.FormHeader, { title: title, onSubmit: handleSubmit, onClose: onClose }),
        React.createElement(Column, { flex: true },
            React.createElement(WebView, { ref: state.webviewRef, style: styles.webview, source: state.source, originWhitelist: ['*'], scalesPageToFit: true, onMessage: actions.rn2WebCom.handleMessage })),
        !state.isMFFocused && (React.createElement(Toolbar, { onActionPress: actions.handleToolbarAction }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    webview: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.main,
    },
}));
export default NativeHTMLEditor;
