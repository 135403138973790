import { RN_HANDLER, WEB_HANDLER } from '../types';
function getScript(theme) {
    return `
const getId = (() => {
  let id = 0;
  return () => id++;
})();

const actionsRegistry = (() => {
  const registry = {};

  return {
    add(id, promise) {
      registry[id] = promise;
    },
    contains(id) {
      return id in registry;
    },
    pop(id) {
      const action = registry[id];
      delete registry[id];
      return action;
    },
  };
})();

const web2RNCom = (() => {
  const sendMessage = (id, message) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        id,
        ...message,
      }),
    );
  };

  const sendAction = (handler, data) => {
    const id = getId();
    sendMessage(id, {
      type: 'ACTION',
      data: {
        handler,
        data,
      },
    });
    return new Promise((resolve, reject) => {
      actionsRegistry.add(id, {
        resolve,
        reject,
      });
    });
  };

  function log(...args) {
    sendAction(${RN_HANDLER.LOG}, {args});
  }

  const sendResponse = (id, response) => {
    sendMessage(id, {
      type: 'RESPONSE',
      data: response,
    });
  };

  const handleMessage = async message => {
    const payload = JSON.parse(message.data);

    if (payload.type === 'ACTION') {
      handleAction(payload);
    } else if (payload.type === 'RESPONSE') {
      handleResponse(payload);
    } else {
      log('Unknown payload.type:', payload);
    }
  };

  const handleAction = async payload => {
    try {
      const result = await onActionMessage(payload);
      sendResponse(payload.id, {data: result});
    } catch (error) {
      sendResponse(payload.id, {error});
    }
  };

  const handleResponse = payload => {
    if (actionsRegistry.contains(payload.id)) {
      const action = actionsRegistry.pop(payload.id);
      if (payload.data.error) {
        action.promise.reject(payload.data.error);
      } else {
        action.promise.resolve(payload.data.data);
      }
    }
  };

  window.addEventListener('message', handleMessage, false);
  document.addEventListener('message', handleMessage, false);

  return {sendAction, log};
})();

function getResolvedNodes(nodeListRef) {
  const result = [];
  for(let i = 0; i < nodeListRef.length; ++i) {
    result.push(nodeListRef[i]);
  }
  return result;
}

const onActionMessage = message => {
  const payload = message.data;

  switch (payload.handler) {
    case ${WEB_HANDLER.EXEC_COMMAND}:
      document.execCommand(...payload.data.args);
      break;

    case ${WEB_HANDLER.INSERT_MATH_FIELD}: {
      document.execCommand('insertHTML', false, '<br>');
      document.execCommand('insertHTML', false, payload.data.node);
      const mathExprNode = document.getElementById(payload.data.containerId);
      mathFieldHandlers.addListeners(mathExprNode);
      const [mf] = mathExprNode.getElementsByTagName('math-field');
      mf.focus();
      break;
    }

    case ${WEB_HANDLER.GET_CONTENT}: {
      const mathExprContainers = getResolvedNodes(document.getElementsByClassName('math-expr-container'));
      mathExprContainers.forEach(containerNode => {
        const [mathExprNode] = containerNode.getElementsByClassName('math-expr');
        mathFieldHandlers.replaceWithExpr(mathExprNode)
        containerNode.replaceWith(...containerNode.childNodes);
      })
      
      return document.getElementById('editor').innerHTML;
    }

    case ${WEB_HANDLER.INSERT_IMG_UPLOAD_PROGRESS}: {
      document.execCommand('insertHTML', false, \`
        <div class="image-loader" id="\${payload.data.containerId}">
          <svg viewbox="0 0 64 64" width="64" height="64">
            <circle cx="28" cy="28" r="24" fill="none" stroke="${theme.palette.background.darker}" stroke-width="4px" />
            <circle cx="28" cy="28" r="24" fill="none" stroke="${theme.palette.primary.main}" stroke-width="4px" stroke-linecap="round" stroke-dasharray="0 150.7964473723" stroke-dashoffset="37.6991118431" />
          </svg>
        </div>
      \`)
      break;
    }
    case ${WEB_HANDLER.UPDATE_IMG_UPLOAD_PROGRESS}: {
      const progressContainer = document.getElementById(payload.data.containerId);
      const circumference = 150.7964473723
      const cover = circumference * (payload.data.progress / 100);
      const gap = circumference - cover;

      web2RNCom.log('Updating Params:', {cover, gap})

      const progressCircle = progressContainer.getElementsByTagName('circle')[1];
      progressCircle.setAttribute('stroke-dasharray', \`\${cover} \${gap}\`);
      break;
    }

    case ${WEB_HANDLER.REPLACE_IMG_UPLOAD_PROGRESS_WITH_IMAGE}: {
      const progressContainer = document.getElementById(payload.data.containerId);
      const div = document.createElement('div');
      const img = document.createElement('img');
      img.src = payload.data.imgSrc;
      div.append(img);
      progressContainer.replaceWith(div);
      break;
    }

    case ${WEB_HANDLER.EXTRACT_TTS_CONTENT}: {
      return extractTTSContent();
    }

    default:
      web2RNCom.log('No Action Handler found:', message);
      break;
  }
}

const mathFieldHandlers = (() => {
  function addListeners(mathExprNode) {
    const [closeBtn] = mathExprNode.getElementsByClassName("math-expr-close");
    closeBtn.addEventListener('click', function (e) {
      mathExprNode.parentElement?.remove();
    })

    const [mf] = mathExprNode.getElementsByTagName('math-field');
    mf.mathModeSpace = '\\\\:';
    mf.keybindings = [];
    mf.inlineShortcuts = [];

    mf.addEventListener('focus', function (e) {
      web2RNCom.sendAction(${RN_HANDLER.MATH_FIELD_FOCUSED}, {});
    })
    mf.addEventListener('blur', function (e) {
      web2RNCom.sendAction(${RN_HANDLER.MATH_FIELD_BLURRED}, {});
    })

    mf.addEventListener(
      'keydown',
      (ev) => {
        switch (ev.key) {
          case 'Backspace': {
            ev.preventDefault();
            mf.executeCommand(['deleteBackward']);
            break;
          }
  
          case 'ArrowLeft': {
            ev.preventDefault();
            mf.executeCommand(['moveToPreviousChar']);
            break;
          }
  
          case 'ArrowRight': {
            ev.preventDefault();
            mf.executeCommand(['moveToNextChar']);
            break;
          }
  
          case 'ArrowDown': {
            ev.preventDefault();
            mf.executeCommand(['moveDown']);
            break;
          }
  
          case 'ArrowUp': {
            ev.preventDefault();
            mf.executeCommand(['moveUp']);
            break;
          }
        }
      },
      {capture: true},
    );
  }

  function replaceWithExpr(mathExprNode) {
    const [mf] = mathExprNode.getElementsByTagName('math-field');
    const expr = mf.value;
    mathExprNode.replaceWith(expr.length ? \`$$\${expr}$$\` : '');
  }

  // Setup Listeners initially on all the existing fields
  const mathExprNodes = document.getElementsByClassName('math-expr');
  for (let i = 0; i < mathExprNodes.length; ++i) {
    addListeners(mathExprNodes[i])
  }

  setTimeout(() => {
    web2RNCom.sendAction(${RN_HANDLER.RENDER_COMPLETED}, {});
  }, 100);

  return {addListeners, replaceWithExpr};
})()

const mathFieldUtils = (() => {
  function removeEmptyUnwantedNodes(node) {
    if (['DIV', 'P'].includes(node.tagName)) {
      for (let childNode of node.children) {
        removeEmptyUnwantedNodes(childNode);
      }
  
      if (node.innerHTML === '') {
        node.remove();
      }
    }
  }
  
  /**
   * Extract HTML Content from the given inputElem node
   */
  function getResolvedNodes(nodeListRef) {
    const result = [];
    for (let i = 0; i < nodeListRef.length; ++i) {
      result.push(nodeListRef[i]);
    }
    return result;
  }
  
  function getMF(mathExprNode) {
    const [mf] = mathExprNode.getElementsByTagName('math-field');
    return mf;
  }
  
  function getMFValue(mathExprNode) {
    const mf = getMF(mathExprNode);
    const expr = mf.value;
    return expr;
  }
  
  function getMFSpokenText(mathExprNode) {
    const mf = getMF(mathExprNode);
    const spokenText = mf.getValue('spoken-text');
    return spokenText;
  }
  
  function isMFEmpty(mathExprNode) {
    const expr = getMFValue(mathExprNode);
    return !expr.length;
  }
  
  function replaceMFWithLatex(mathExprNode) {
    const expr = getMFValue(mathExprNode);
    mathExprNode.replaceWith(expr.length ? \`$$\${expr}$$\` : '');
  }
  
  function replaceMFWithSpokenText(mathExprNode) {
    const spokenText = getMFSpokenText(mathExprNode);
    mathExprNode.replaceWith(spokenText.length ? spokenText : '');
  }

  function mfIterator(inputElem, iteratee) {
    const mathExprContainers = getResolvedNodes(
      inputElem.getElementsByClassName('math-expr-container'),
    );

    mathExprContainers.forEach(containerNode => {
      const [mathExprNode] = containerNode.getElementsByClassName(
        'math-expr',
      );
  
      iteratee({containerNode, mathExprNode});
  
      containerNode.replaceWith(...containerNode.childNodes);
    });
  
    removeEmptyUnwantedNodes(inputElem);
    return inputElem.innerHTML;
  }

  return {mfIterator, replaceMFWithSpokenText, isMFEmpty}
})()

function extractTTSContent() {
  const inputElem = document.getElementById('renderer');

  mathFieldUtils.mfIterator(inputElem, function ({containerNode, mathExprNode}) {
    // If the math-field value is empty, then
    // just delete the container node
    if (!mathExprNode || mathFieldUtils.isMFEmpty(mathExprNode)) {
      containerNode.replaceWith('');
      return;
    }

    mathFieldUtils.replaceMFWithSpokenText(mathExprNode);
  });

  return inputElem.textContent || '';
}


/** Window Resize Observer */
const resizeObserver = new ResizeObserver(entries => {
  web2RNCom.sendAction(${RN_HANDLER.DOCUMENT_HEIGHT}, entries[0].target.clientHeight)
})

resizeObserver.observe(document.body)

/** Add image click listener */
const images = document.getElementsByTagName('img')
for(let i = 0; i < images.length; ++i) {
  const elem = images[i];
  elem.addEventListener('click', function (e) {
    web2RNCom.sendAction(${RN_HANDLER.IMG_CLICK}, e.target.src);
  })
}

/** Add Link Click Listener */
const renderer = document.getElementById('renderer');
if(renderer) {
  renderer.addEventListener('click', function (ev) {
    const elem = ev.target;
    if (elem.nodeName === 'A' && elem.getAttribute('href')) {
      web2RNCom.sendAction(${RN_HANDLER.LINK_CLICK}, elem.getAttribute('href'));
    }
  })
}

/** Focus on Editor on init */
setTimeout(() => {
  const editor = document.getElementById('editor')
  if(editor) {
    editor.focus();
  }
}, 100)
`;
}
export default function getRootScript(theme) {
    return `
  <script>
    window.onload = function () {
      ${getScript(theme)}
    }
  </script>
  `;
}
