import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useI18n from 'src/modules/translations/hooks/useI18n';
const FirstQuestionReadAsAuthorDrawer = ({ onConfirm, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Be a Responsible Author')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(ULItem, null, translate('If any of the Answers worked for you, then please Mark it as Accepted,\nto let others know that it worked for you!')),
            React.createElement(ULItem, null, translate('Not to forget, you can also Like/Dislike Other Answers too'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { enableAfter: 3, flex: true, onPress: onConfirm }, translate('Sure')))));
};
export default FirstQuestionReadAsAuthorDrawer;
