import React from 'react';
import { Animated } from 'react-native';
const SlideIn = ({ dragX, direction, style, ...rest }) => {
    const isLeft = direction === 'left';
    const xPos = dragX.interpolate({
        inputRange: isLeft ? [0, 100] : [-100, 0],
        outputRange: isLeft ? [-50, 0] : [0, 50],
        extrapolate: 'clamp',
    });
    return (React.createElement(Animated.View, { style: [
            style,
            {
                transform: [{ translateX: xPos }],
            },
        ], ...rest }));
};
export default SlideIn;
