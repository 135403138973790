import React from 'react';
import useFormStyles from 'src/components/10-atoms/form/styles';
import Row from 'src/components/10-atoms/layout/Row';
import Pressable from 'src/components/10-atoms/pressables/Pressable';
import TopicTagList from 'src/components/10-atoms/topics/TopicTagList';
import Typography from 'src/components/10-atoms/typography/Typography';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const TopicInput = ({ pickerTitle, placeholder = 'Click to Add Topics for the Question', value, disabled = false, ...topicPickerProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const formStyles = useFormStyles({ focused: false, disabled });
    const showTopicPicker = () => {
        if (!disabled) {
            helperViewActions.topicPicker.show({
                initialValue: value,
                title: pickerTitle,
                ...topicPickerProps,
            });
        }
    };
    return (React.createElement(Pressable, { disabled: disabled, onPress: showTopicPicker },
        React.createElement(Row, { style: [formStyles.inputContainer, styles.container], yAxis: "center" }, value.length > 0 ? (React.createElement(TopicTagList, { topics: value })) : (React.createElement(Typography, { color: theme.palette.typography.placeholder }, placeholder)))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        minHeight: PRESSABLE_SIZE,
        paddingVertical: theme.spacing.standard.sm,
    },
}));
export default TopicInput;
