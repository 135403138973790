import { gql } from '@apollo/client';
import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import AnswerSentForReviewDrawer from '../../screens/Question/components/AnswerSentForReviewDrawer';
import AnswerUpdateSuccessDrawer from '../../screens/Question/components/AnswerUpdateSuccessDrawer';
import { EP_QUESTION_DETAILS_QUERY } from '../question/getQuestionDetails';
const CREATE_ANSWER_MUTATION = gql `
  mutation EP_CreateAnswer($questionID: String!, $data: EPAnswerInput!) {
    ep_createAnswer(questionID: $questionID, data: $data) {
      sentForReview
    }
  }
`;
export function handleAnswerMutationCompleted(data) {
    if (data.sentForReview) {
        helperViewActions.bottomDrawer.show({
            id: 'ep_answer_sent_for_review',
            component: (React.createElement(AnswerSentForReviewDrawer, { onConfirm: helperViewActions.bottomDrawer.hide })),
            duration: 10000,
        });
    }
    else {
        helperViewActions.bottomDrawer.show({
            id: 'ep_answer_update_success',
            component: (React.createElement(AnswerUpdateSuccessDrawer, { onConfirm: helperViewActions.bottomDrawer.hide })),
            duration: 7000,
        });
    }
}
export function useCreateAnswerMutation(options) {
    return useGQLMutation(CREATE_ANSWER_MUTATION, {
        ...options,
        refetchQueries(result) {
            if (!result.data?.ep_createAnswer.sentForReview) {
                return [EP_QUESTION_DETAILS_QUERY];
            }
            return [];
        },
        onCompleted(data) {
            handleAnswerMutationCompleted(data.ep_createAnswer);
        },
    });
}
