import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import ConfirmDeleteDrawer from 'src/components/20-helper-views/bottom-drawer/common-drawers/ConfirmDelete';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import FilterViewView from '../../components/FilterView/View';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const FilterViewContainer = ({ listType, myList = [], filterView, ...filterViewProps }) => {
    const navigation = useNavigation();
    const { translate } = useI18n();
    const [state, actions] = useData();
    const handleDelete = () => {
        helperViewActions.bottomDrawer.show({
            component: (React.createElement(ConfirmDeleteDrawer, { title: translate('Delete Group'), 
                // msg={`Are you sure you want to delete "${filterView.title}" Group ?`}
                msg: translate('Are you sure you want to delete "%filterViewTitle" Group ?', { filterViewTitle: filterView.title }), onCancel: helperViewActions.bottomDrawer.hide, onDelete: () => {
                    helperViewActions.bottomDrawer.hide();
                    actions.deleteMutation.delete({
                        variables: {
                            filterViewID: filterView.id,
                        },
                    });
                } })),
        });
    };
    const handleAdd = useCallback(() => {
        actions.addMutation.submit({
            variables: {
                filterViewID: filterView.id,
            },
            context: {
                filterView,
            },
        });
    }, [actions.addMutation.submit, filterView]);
    const handlePress = useCallback(() => {
        navigation.navigate('Community_TopicsGroupForm', {
            flow: 'edit',
            filterView,
        });
    }, []);
    const isAdded = myList.findIndex(item => item.id === filterView.id) > -1;
    return (React.createElement(FilterViewView, { onDelete: handleDelete, onAdd: handleAdd, added: isAdded, addingInProgress: state.addMutation.loading, filterView: filterView, onPress: listType === 'my-list' ? handlePress : undefined, listType: listType, ...filterViewProps }));
};
export default FilterViewContainer;
