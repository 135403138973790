import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AddAnswerSuccessDrawer = ({ onConfirm, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Body, { yAxis: "center", spacing: theme.spacing.itemSeparation, style: { paddingHorizontal: 48 } },
            React.createElement(Typography, null, translate('Successfully submitted your Answer!')),
            React.createElement(FitImage, { source: {
                    uri: AppAssets.communityAddAnswerSuccess,
                } }),
            React.createElement(Typography, { style: { textAlign: 'center' }, color: theme.palette.typography.secondary }, translate('We Salute your contribution on behalf of Marine Community'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { style: { flex: 1 }, onPress: onConfirm }, translate('OK')))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default AddAnswerSuccessDrawer;
