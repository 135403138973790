import { sessionStorage } from 'src/lib/storage';
import { USER_DETAILS_STORAGE_KEY } from './constants';
const userStorage = {
    setUserDetails(data) {
        sessionStorage.set(USER_DETAILS_STORAGE_KEY, data);
    },
    getUserDetails() {
        return sessionStorage.get(USER_DETAILS_STORAGE_KEY);
    },
};
export default userStorage;
