import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const DELETE_FILTER_VIEW_MUTATION = gql `
  mutation Community_deleteFilterView($filterViewID: String!) {
    community_deleteFilterView(filterViewID: $filterViewID)
  }
`;
export function useDeleteFilterViewMutation(options) {
    return useGQLMutation(DELETE_FILTER_VIEW_MUTATION, {
        ...options,
        update(cache, _data, { variables }) {
            if (variables?.filterViewID) {
                cache.evict({
                    id: cache.identify({
                        id: variables.filterViewID,
                        __typename: 'CommunityFilterView',
                    }),
                });
                cache.gc();
            }
        },
    });
}
