import React from 'react';
import Badge from 'src/components/10-atoms/badge/Badge';
import BadgeIcon from 'src/components/10-atoms/badge/BadgeIcon';
import BadgeText from 'src/components/10-atoms/badge/BadgeText';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AcceptedAnswerBadge = ({ style, ...badgeProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Badge, { style: [styles.container, style], ...badgeProps },
        React.createElement(BadgeIcon
        // @ts-ignore
        , { 
            // @ts-ignore
            Icon: FeatherIcon, name: "check", color: theme.palette.typography.primary }),
        React.createElement(BadgeText, null, translate('Accepted'))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        backgroundColor: theme.palette.positive.main,
    },
}));
export default AcceptedAnswerBadge;
