import React from 'react';
import EditIcon from 'src/components/10-atoms/icons/EditIcon';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
const EditButton = ({ ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xxs, ...rowProps },
        React.createElement(EditIcon, null)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default withPress(EditButton);
