import { useMemo, useRef, useState } from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import { addRendererWrapper } from '../../common/native-web-utils';
import useRN2WebCom from '../../common/useRN2WebCom';
import useActionMessageHandler from './useActionMessageHandler';
export default function useData(html, options, events) {
    const [{ theme, deviceType }] = useTheme();
    const webviewRef = useRef(null);
    const [height, setHeight] = useState(-1);
    const actionMessageHandler = useActionMessageHandler({
        onHeightChange: setHeight,
        onRenderComplete: events?.onRenderComplete,
    });
    const rn2WebCom = useRN2WebCom({
        webviewRef,
        onActionMessage: actionMessageHandler,
    });
    const source = useMemo(() => ({
        html: addRendererWrapper(theme, deviceType, html, options?.variant),
    }), [theme, html, options?.variant]);
    const state = {
        webviewRef,
        source,
        height,
    };
    const actions = {
        rn2WebCom,
    };
    return [state, actions];
}
