import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { isIOS } from 'src/utils/platform';
import AppleSigninBtn from '../components/AppleSinginBtn';
import GoogleSigninBtn from '../components/GoogleSiginBtn';
import SigninTnC from '../components/SigninTnC';
import useAppleSignin from '../hooks/useAppleSignin';
import useGoogleSignin from '../hooks/useGoogleSignin';
const UserRegisterDrawer = ({ title, onCancel, onSuccess, }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    if (!title) {
        title = translate('Login to perform the Action');
    }
    const [appleSigninState, appleSigninActions] = useAppleSignin({
        onComplete: onSuccess,
    });
    const [googleSigninState, googleSigninActions] = useGoogleSignin({
        onComplete: onSuccess,
    });
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, { spacing: theme.spacing.standard.md },
            React.createElement(SigninTnC, null),
            isIOS && (React.createElement(AppleSigninBtn, { loading: appleSigninState.appleSigninAPI.loading, onPress: appleSigninActions.signinWithApple })),
            React.createElement(GoogleSigninBtn, { loading: googleSigninState.googleSigninAPI.loading, onPress: googleSigninActions.signinWithGoogle })),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { style: { flex: 1 }, variant: "text", onPress: onCancel }, translate('Cancel')))));
};
export default UserRegisterDrawer;
