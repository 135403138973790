import { useCallback, useState } from 'react';
import useSubscription from 'src/hooks/useSubscription';
import SE from 'src/lib/supervisedEmitter';
const getInitialState = () => ({
    visible: false,
    component: null,
    onClose() { },
});
export default function useOverlay() {
    const [state, setState] = useState(getInitialState());
    const hide = useCallback(() => {
        setState(prevState => ({
            ...getInitialState(),
            component: prevState.component,
        }));
    }, []);
    const show = useCallback((data) => {
        setState({
            visible: true,
            onClose: hide,
            component: data.component,
        });
    }, [hide]);
    useSubscription('/helper-views/overlay/show', show);
    useSubscription('/helper-views/overlay/hide', hide);
    return [state];
}
export const overlayHelperViewActions = {
    show(data) {
        SE.publish('/helper-views/overlay/show', data);
    },
    hide() {
        SE.publish('/helper-views/overlay/hide');
    },
};
