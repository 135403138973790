import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { QuestionFormFlow } from '.';
import { useCommunityCreateQuestionMutation } from '../../graphql/question/create';
import { useCommunityEditQuestionMutation } from '../../graphql/question/edit';
import { ValidationConfig } from './config';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function useData(flow, question) {
    const { translate } = useI18n();
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            title: getFieldState(question?.data.title ?? ''),
            description: getFieldState(question?.data.description ?? ''),
            tags: getFieldState(question?.data.tags ?? []),
            imo: getFieldState(question?.data.IMO ?? ''),
        },
    }, {
        fieldValidations: {
            title({ value: title }) {
                if (!title) {
                    return translate('Please enter Title for the Question');
                }
                return title.length > ValidationConfig.MAX_TITLE_LENGTH
                    ? translate('Title cannot contain more than %maxLimit chars', {
                        maxLimit: ValidationConfig.MAX_TITLE_LENGTH,
                    })
                    : true;
            },
            description({ value: description }) {
                if (!description) {
                    return translate('Please enter a Description for the Question');
                }
                return description.length > ValidationConfig.MAX_DESCRIPTION_LENGTH
                    ? translate('Description cannot contain more than %maxLimit chars', {
                        maxLimit: ValidationConfig.MAX_DESCRIPTION_LENGTH,
                    })
                    : true;
            },
            tags({ value }) {
                if (value.length > ValidationConfig.MAX_TAGS) {
                    return translate('You cannot add more than %maxLimit Topics', {
                        maxLimit: ValidationConfig.MAX_TAGS,
                    });
                }
                if (value.length === 0) {
                    return translate('Please add atleast one Topic');
                }
                return true;
            },
        },
    });
    const [createQuestionMutationState, createQuestionMutationActions] = useCommunityCreateQuestionMutation();
    const [editQuestionMutationState, editQuestionMutationActions] = useCommunityEditQuestionMutation();
    const validateForm = () => {
        const hasErrors = formHelpers.hasErrors();
        if (hasErrors || !actions.form.validateForm()) {
            helperViewActions.snackbar.show({
                variant: 'NEGATIVE',
                duration: 'MEDIUM',
                message: translate('Please check the errors in the form'),
            });
            return false;
        }
        return true;
    };
    const getQuestionForm = () => {
        return {
            id: question?.id,
            title: state.form.fields.title.value,
            format: 'HTML_V1',
            description: state.form.fields.description.value,
            tags: state.form.fields.tags.value,
            IMO: state.form.fields.imo.value ?? undefined,
        };
    };
    const handleSubmit = () => {
        const isValid = validateForm();
        if (!isValid) {
            return;
        }
        const formQuestion = getQuestionForm();
        switch (flow) {
            case QuestionFormFlow.NEW: {
                actions.createQuestionMutation.submit({
                    variables: {
                        data: {
                            title: formQuestion.title,
                            description: formQuestion.description,
                            format: formQuestion.format,
                            tags: formQuestion.tags,
                            IMO: formQuestion.IMO,
                        },
                    },
                });
                break;
            }
            case QuestionFormFlow.EDIT: {
                actions.editQuestionMutation.update({
                    variables: {
                        id: question.id,
                        title: formQuestion.title,
                        description: formQuestion.description,
                        format: formQuestion.format,
                        tags: formQuestion.tags,
                        IMO: formQuestion.IMO,
                    },
                });
                break;
            }
            default:
                if (__DEV__) {
                    throw new Error(`handleSubmit not defined for QuestionFlow: ${flow}`);
                }
        }
    };
    const state = {
        form: formState,
        createQuestionMutation: createQuestionMutationState,
        editQuestionMutation: editQuestionMutationState,
    };
    const actions = {
        form: {
            ...formActions,
            handleSubmit,
        },
        createQuestionMutation: createQuestionMutationActions,
        editQuestionMutation: editQuestionMutationActions,
    };
    return [state, actions];
}
