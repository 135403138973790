import pluralize from 'pluralize';
import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Column from 'src/components/10-atoms/layout/Column';
import SectionHeader from 'src/components/10-atoms/SectionHeader';
import Typography from 'src/components/10-atoms/typography/Typography';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import EmptyList from 'src/components/templates/EmptyList.template';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import FTUEKeys from 'src/lib/ftue/keys';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import AnswerGuidelinesDrawer from 'src/modules/community/tutorials/guidelines/AnswerGuidelines';
import { showGuidelinesDrawer } from 'src/modules/community/tutorials/utils';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import AnswerContainer from '../container';
import useData from './useData';
const AnswerList = ({ question, onSharePress }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData();
    const { translate } = useI18n();
    const handleAnswerSubmit = (newAnswer) => {
        actions.addAnswerMutation.create({
            variables: {
                data: {
                    questionID: question.id,
                    format: 'HTML_V1',
                    answer: newAnswer,
                },
            },
            context: {
                questionID: question.id,
            },
        });
    };
    const [userDetails] = useUserStorage();
    const showAnswerInput = () => {
        helperViewActions.htmlEditor.show({
            title: translate('Add your Answer'),
            placeholder: translate('Add your Answer'),
            html: '',
            onSubmit: handleAnswerSubmit,
        });
    };
    const handleAddAnswerPress = async () => {
        await showGuidelinesDrawer(AnswerGuidelinesDrawer, FTUEKeys.COMMUNITY_ANSWER_GUIDELINES);
        const hasAlreadyAnswered = question.answers.findIndex(answer => answer.author.id === userDetails?.id) > -1;
        if (hasAlreadyAnswered) {
            helperViewActions.snackbar.show({
                variant: 'NEUTRAL',
                message: translate('You have already Answered this Question, please update the same if necessary'),
            });
            return;
        }
        showAnswerInput();
    };
    return (React.createElement(Column, null,
        question.answers.length > 0 && (React.createElement(SectionHeader, null, pluralize(translate('ANSWERS'), question.answers.length, true))),
        React.createElement(Column, { spacing: theme.spacing.standard.sm, style: styles.container },
            question.answers.map(answer => (React.createElement(AnswerContainer, { answer: answer, question: question, key: answer.id }))),
            question.answers.length === 0 ? (React.createElement(EmptyList, { image: {
                    type: 'image',
                    source: {
                        uri: AppAssets.noAnswersYet,
                    },
                }, message: translate('Marine Community is waiting for an Answer that you might know') },
                React.createElement(Button, { loading: state.addAnswerMutation.loading, onPress: handleAddAnswerPress }, translate('ADD YOUR ANSWER')),
                !state.addAnswerMutation.loading && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, null, translate('OR')),
                    React.createElement(Button, { variant: "text", onPress: onSharePress }, translate('Share this Question')))))) : (React.createElement(Button, { loading: state.addAnswerMutation.loading, style: styleUtils.pagePadding, onPress: handleAddAnswerPress }, translate('ADD YOUR ANSWER'))))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingHorizontal: theme.spacing.standard.sm,
        paddingVertical: theme.spacing.standard.sm,
        paddingBottom: theme.spacing.standard.xl,
    },
}));
export default AnswerList;
