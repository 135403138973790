import { gql } from '@apollo/client';
import { graphqlClient } from 'src/lib/graphql';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
export const COMMUNITY_QUESTION_DETAILS_QUERY = gql `
  query Community_questionDetails($questionID: String!) {
    community_questionDetails(questionID: $questionID) {
      id
      data {
        format
        title
        description
        tags
        votes
        hasVoted
        IMO
      }
      blockedBy
      ctime
      utime
      isFollowing
      translationInfo {
        sourceLang
        targetLang
      }
      replies {
        id
        data {
          reply
        }
        translationInfo {
          sourceLang
          targetLang
        }
        blockedBy
        utime
        ctime
        author {
          details {
            userName
          }
          id
        }
      }
      answers {
        id
        ctime
        blockedBy
        translationInfo {
          sourceLang
          targetLang
        }
        repliesCount
        replies {
          id
          data {
            reply
          }
          blockedBy
          ctime
          utime
          author {
            id
            details {
              firstName
              lastName
              userName
            }
          }
        }
        author {
          id
          details {
            avatarURL
            userName
            lastName
            firstName
          }
        }
        utime
        data {
          format
          answer
          accepted
          votes
          hasVoted
        }
      }
      hasAcceptedAnswer
      author {
        id
        details {
          avatarURL
          firstName
          lastName
          userName
        }
      }
      answersCount
    }
  }
`;
export function useCommunityQuestionDetailsQuery(options) {
    return useGQLQuery(COMMUNITY_QUESTION_DETAILS_QUERY, options);
}
export function getQuestionDetailsQuery(questionID, options) {
    return graphqlClient.query({
        query: COMMUNITY_QUESTION_DETAILS_QUERY,
        variables: {
            questionID,
        },
        ...options,
    });
}
