import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
const ReplyEditor = ({ variant, onCancel, onSubmit, initialValue, submitInProgress, style, ...columnProps }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            reply: getFieldState(initialValue ?? ''),
        },
    }, {
        fieldValidations: {
            reply({ value }) {
                return value.trim().length > 0
                    ? true
                    : translate('Please enter a reply');
            },
        },
    });
    const handleSubmit = () => {
        onSubmit(formState.fields.reply.value);
    };
    return (React.createElement(Column, { style: style, spacing: theme.spacing.standard.xs, ...columnProps },
        React.createElement(Field, { error: formState.fields.reply.errors[0]?.message },
            React.createElement(TextInput, { autoFocus: true, placeholder: translate('Enter your Reply'), multiline: true, numberOfLines: 5, value: formState.fields.reply.value, onChange: formActions.fields.reply.setValue })),
        React.createElement(Row, { xAxis: "space-between" },
            React.createElement(Button, { variant: "text", noIconSpacing: true, onPress: onCancel }, translate('Cancel')),
            React.createElement(Button, { noIconSpacing: true, loading: submitInProgress, disabled: formHelpers.hasErrors(), onPress: handleSubmit }, variant === 'new' ? translate('ADD') : translate('UPDATE')))));
};
export default ReplyEditor;
