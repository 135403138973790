import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, ScrollView } from 'react-native';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Column from 'src/components/10-atoms/layout/Column';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import Typography from 'src/components/10-atoms/typography/Typography';
import AppAssets from 'src/constants/appAssets';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import TutorialNavigation from '../../components/Navigation';
const steps = [
    {
        description: 'If you face a problem and looking for help, then Post your Question in the Community',
        img: AppAssets.communityHowItWorks1,
    },
    {
        description: 'We will notify the Mariners(Community) all around the World',
        img: AppAssets.communityHowItWorks2,
    },
    {
        description: 'Community Answers your Question and we will notify you about it',
        img: AppAssets.communityHowItWorks3,
    },
    {
        description: 'When others face the same issue, they can easily Search for the Solution',
        img: AppAssets.communityHowItWorks4,
    },
];
const HowItWorks = ({ height, onGetStarted, onPrev, style, ...columnProps }) => {
    const [{ theme, dimensions: { safeAreaInsets }, },] = useTheme(stylesGenerator);
    const scrollViewRef = useRef(null);
    const [currStep, setCurrStep] = useState(1);
    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({
                x: (currStep - 1) * Dimensions.get('window').width,
                animated: true,
            });
        }
    }, [currStep]);
    const handlePrev = () => {
        setCurrStep(prevState => {
            if (prevState === 1) {
                onPrev();
                return prevState;
            }
            return prevState - 1;
        });
    };
    const handleNext = () => {
        setCurrStep(prevState => prevState + 1);
    };
    return (React.createElement(Column, { style: [
            { height, paddingBottom: safeAreaInsets.bottom },
            styleUtils.deviceWidth,
            style,
        ], ...columnProps },
        React.createElement(RowCenter, { pv: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.SUB_HEADING }, "How it Works ?")),
        React.createElement(ScrollView, { ref: scrollViewRef, horizontal: true, scrollEnabled: false, showsHorizontalScrollIndicator: false, bounces: false, style: { flex: 1 } }, steps.map((step, i) => (React.createElement(Column, { key: i, ph: theme.spacing.page, pv: theme.spacing.standard.md, style: [styleUtils.deviceWidth], spacing: theme.spacing.standard.md },
            React.createElement(FitImage, { source: { uri: step.img } }),
            React.createElement(Typography, { align: "center" }, step.description))))),
        React.createElement(TutorialNavigation, { isFirst: false, isLast: currStep === steps.length, onSkip: () => { }, onPrev: handlePrev, onNext: handleNext, onGetStarted: onGetStarted })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HowItWorks;
