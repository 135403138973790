import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { RN_HANDLER } from '../../common/types';
export default function useActionMessageHandler(options) {
    return useCallbackWithCtx(async (ctx, message) => {
        const handlers = {
            [RN_HANDLER.MATH_FIELD_FOCUSED]() {
                options.onMathFieldFocused();
            },
            [RN_HANDLER.MATH_FIELD_BLURRED]() {
                options.onMathFieldBlurred();
            },
        };
        const handler = handlers[message.data.handler];
        if (handler) {
            return handler(message.data);
        }
    }, {});
}
