import React, { useRef } from 'react';
import { RectButton } from 'react-native-gesture-handler';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Row from '../layout/Row';
import FadeInBackground from './FadeIn';
import SlideIn from './SlideIn';
import { isWeb } from 'src/utils/platform';
import Pressable from '../pressables/Pressable';
const SwipeableCard = ({ onCardPress, leftActionColor, renderLeftAction, onLeftAction, rightActionColor, renderRightAction, onRightAction, equilizeActions = false, children, style, ...swipeableProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    leftActionColor = leftActionColor ?? theme.palette.negative.main;
    rightActionColor = rightActionColor ?? theme.palette.positive.main;
    if (equilizeActions) {
        if (renderLeftAction) {
            renderRightAction = renderLeftAction;
            rightActionColor = leftActionColor;
            onRightAction = onLeftAction;
        }
        else {
            renderLeftAction = renderRightAction;
            leftActionColor = rightActionColor;
            onLeftAction = onRightAction;
        }
    }
    const swipeableRef = useRef(null);
    const renderLeftActions = (_, dragX) => (React.createElement(Row, { xAxis: "flex-start", style: [styles.actionContainer, { backgroundColor: leftActionColor }] },
        React.createElement(SlideIn, { direction: "left", dragX: dragX, style: styles.action }, renderLeftAction ? renderLeftAction() : null),
        React.createElement(FadeInBackground, { dragX: dragX })));
    const renderRightActions = (_, dragX) => (React.createElement(Row, { xAxis: "flex-end", style: [
            styles.actionContainer,
            {
                backgroundColor: rightActionColor,
            },
        ] },
        React.createElement(SlideIn, { direction: "right", dragX: dragX, style: styles.action }, renderRightAction ? renderRightAction() : null),
        React.createElement(FadeInBackground, { dragX: dragX })));
    return (React.createElement(Swipeable, { activeOffsetX: [-15, 15], failOffsetY: [-15, 15], friction: 1, overshootLeft: false, overshootRight: false, overshootFriction: 5, ref: swipeableRef, leftThreshold: 100, renderLeftActions: renderLeftAction && renderLeftActions, onSwipeableOpen: direction => {
            swipeableRef.current?.close();
            if (direction === 'left') {
                onLeftAction && onLeftAction();
            }
            else {
                onRightAction && onRightAction();
            }
        }, rightThreshold: 100, renderRightActions: renderRightAction && renderRightActions, containerStyle: style, ...swipeableProps }, isWeb ? (React.createElement(Pressable, { style: styles.container, onPress: onCardPress }, children)) : (React.createElement(RectButton, { style: styles.container, onPress: onCardPress }, children))));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {
        flex: 1,
    },
    actionContainer: {
        width: '100%',
    },
    action: {
        width: 100,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default SwipeableCard;
