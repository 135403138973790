import React from 'react';
import Typography from './Typography';
const TypographyBuilder = ({ builder, ...parentTypographyProps }) => {
    return (React.createElement(Typography, { ...parentTypographyProps }, (Array.isArray(builder) ? builder : [builder])
        .filter(isNonNull)
        .map((item, i) => {
        if (typeof item === 'string') {
            return (React.createElement(Typography, { key: i, ...parentTypographyProps }, `${item} `));
        }
        const { text, noSpace, ...typographyProps } = item;
        return (React.createElement(Typography, { key: i, ...Object.assign({}, parentTypographyProps, typographyProps) }, `${text}${noSpace ? '' : ' '}`));
    })));
};
function isNonNull(item) {
    return !!item;
}
export default TypographyBuilder;
