import SE from 'src/lib/supervisedEmitter';
import useEffectWithCtx from './useEffectUtils/useEffectWithCtx';
export default function useSubscription(topic, onData) {
    useEffectWithCtx(ctx => {
        const subscription = SE.subscribe(topic, ({ data }) => {
            ctx.onData(data);
        });
        return subscription.unsubscribe;
    }, { onData }, [topic]);
}
