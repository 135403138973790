import { useAllMyFilterViewsQuery } from '../../graphql/allMyList';
import { useRecommendedFilterViewsListQuery } from '../../graphql/recommendedList';
export default function useData() {
    const [recommendedListQueryState, recommendedListQueryActions] = useRecommendedFilterViewsListQuery();
    const [myListQueryState] = useAllMyFilterViewsQuery({
        fetchPolicy: 'cache-first',
    });
    const state = {
        recommendedListQuery: recommendedListQueryState,
        myListQuery: myListQueryState,
    };
    const actions = {
        recommendedistQuery: recommendedListQueryActions,
    };
    return [state, actions];
}
