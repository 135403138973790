import { useNavigation } from '@react-navigation/native';
import React from 'react';
import Button, { FLOATING_BTN_BOTTOM_OFFSET, } from 'src/components/10-atoms/button/Button';
import Column from 'src/components/10-atoms/layout/Column';
import EmptyList from 'src/components/templates/EmptyList.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import GQLList from 'src/lib/graphql/components/GQLList';
import FilterViewLoader from '../../components/FilterView/Loader';
import CreateNewGroupBtn from '../CreateNewGroupBtn';
import FilterViewContainer from '../FilterView';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const variables = {};
const MyList = ({ ...listProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    const navigation = useNavigation();
    const gotoNewTopicsGroupForm = () => {
        navigation.navigate('Community_TopicsGroupForm', {
            flow: 'new',
        });
    };
    return (React.createElement(Column, { flex: true },
        React.createElement(GQLList, { queryState: state.listQuery, queryActions: actions.listQuery, variables: variables, LoaderComponent: FilterViewLoader, field: "community_getAllMyFilterViews", contentContainerStyle: {
                paddingBottom: FLOATING_BTN_BOTTOM_OFFSET,
            }, ListEmptyComponent: React.createElement(EmptyList, { message: 'No Groups Found' },
                React.createElement(Button, { onPress: gotoNewTopicsGroupForm }, translate('Create New Group'))), renderItem: ({ item }) => (React.createElement(FilterViewContainer, { flex: true, style: styles.item, filterView: item, listType: "my-list" })), ...listProps }),
        state.listQuery.data?.community_getAllMyFilterViews.length !== 0 && (React.createElement(CreateNewGroupBtn, { myList: state.listQuery.data?.community_getAllMyFilterViews }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    item: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border.main,
    },
}));
export default MyList;
