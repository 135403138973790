import React, { useMemo } from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { getDiffHTML } from './utils';
import HTMLRenderer from '../HTMLRenderer';
const HTMLDiffRenderer = ({ oldHtml, newHtml, showOnlyDiff, ...htmlRendererProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const diffHtml = useMemo(() => getDiffHTML(oldHtml ?? '', newHtml, {
        showOnlyDiff,
    }), [oldHtml, newHtml, showOnlyDiff]);
    return React.createElement(HTMLRenderer, { html: diffHtml, ...htmlRendererProps });
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HTMLDiffRenderer;
