import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
const QuestionVotingGuidelinesDrawer = ({ onConfirm, }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Question Like/Dislike Guidelines')),
        React.createElement(BottomDrawerLayout.Body, { spacing: theme.spacing.itemSeparation },
            React.createElement(Column, null,
                React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, translate('Like the Question if:')),
                React.createElement(ULItem, null, translate('You came here looking for the same Question')),
                React.createElement(ULItem, null, translate('Author has explained the Question very well'))),
            React.createElement(Column, null,
                React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, translate('Dislike the Question if:')),
                React.createElement(ULItem, null, translate("Author hasn't explained the Question correctly")),
                React.createElement(ULItem, null, translate('Author has added irrelevant Topics, to grab unnecessary attention')))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { enableAfter: 3, flex: true, onPress: onConfirm }, translate('I Agree')))));
};
export default QuestionVotingGuidelinesDrawer;
