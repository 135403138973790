import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import FitImage from 'src/components/10-atoms/image/FitImage';
import ColumnCenter from 'src/components/10-atoms/layout/ColumnCenter';
import Typography from 'src/components/10-atoms/typography/Typography';
import AppAssets from 'src/constants/appAssets';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const Welcome = ({ scrollPos, height, style, ...colCenterProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const animation = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        Animated.timing(animation, {
            toValue: 1,
            duration: 2000,
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
        }).start();
    }, []);
    const scrollEffect = scrollPos.interpolate({
        inputRange: [0, height / 2],
        outputRange: [0, -1],
        extrapolate: 'clamp',
    });
    const imgAnimationInRange = [0, 0.5];
    const imageOpacity = Animated.add(scrollEffect, animation.interpolate({
        inputRange: imgAnimationInRange,
        outputRange: [0, 1],
        extrapolate: 'clamp',
    }));
    const imagePos = animation.interpolate({
        inputRange: imgAnimationInRange,
        outputRange: [10, 0],
        extrapolate: 'clamp',
    });
    const textAnimationInRange = [0.5, 1];
    const textOpacity = Animated.add(scrollEffect, animation.interpolate({
        inputRange: textAnimationInRange,
        outputRange: [0, 1],
        extrapolate: 'clamp',
    }));
    const textPos = animation.interpolate({
        inputRange: textAnimationInRange,
        outputRange: [10, 0],
        extrapolate: 'clamp',
    });
    return (React.createElement(ColumnCenter, { ph: theme.spacing.standard.lg, spacing: theme.spacing.standard.lg, style: [{ height }, style], ...colCenterProps },
        React.createElement(Animated.View, { style: {
                alignSelf: 'stretch',
                opacity: imageOpacity,
                transform: [
                    {
                        translateY: imagePos,
                    },
                ],
            } },
            React.createElement(FitImage, { source: {
                    uri: AppAssets.communityConnection,
                } })),
        React.createElement(Animated.View, { style: {
                alignSelf: 'stretch',
                opacity: textOpacity,
                transform: [
                    {
                        translateY: textPos,
                    },
                ],
            } },
            React.createElement(Typography, { variant: TypographyVariant.HEADING, align: "center" },
                'Welcome to\n',
                React.createElement(Typography, { color: theme.palette.primary.dark, variant: TypographyVariant.HEADING, fontWeight: TypographyFontWeight.BOLD }, "MARINE COMMUNITY")))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default Welcome;
