import { useCallback, useEffect } from 'react';
import StorageKeys from 'src/constants/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import useAppState from '../useAppState';
import useUsageProfile from '../useUsageProfile';
import { UsageTrackingParams } from './constants';
import useDateUtils from 'src/utils/date/useDateUtils';
import { isWeb } from 'src/utils/platform';
export default function useTrackOpenedDaysCount() {
    const [lastOpenedDate, setLastOpenedDate] = usePersistedState('device', StorageKeys.trackerLastOpenedDate);
    const [, usageProfileModifiers] = useUsageProfile();
    const dateUtils = useDateUtils();
    const updateCount = useCallback(() => {
        const currDate = dateUtils.formatDate(new Date(), 'ISO');
        if (currDate !== lastOpenedDate) {
            setLastOpenedDate(currDate);
            usageProfileModifiers.increment(UsageTrackingParams.openedDaysCount);
        }
    }, [lastOpenedDate, usageProfileModifiers.increment, dateUtils.formatDate]);
    useEffect(() => {
        updateCount();
    }, []);
    useAppState((ctx, state) => {
        if (!isWeb) {
            if (state === 'foreground') {
                ctx.updateCount();
            }
        }
    }, { updateCount });
}
