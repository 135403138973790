import { ApolloClient, ApolloLink, createHttpLink, from, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import appEvents from 'src/modules/10-app/events';
import tokenStorage from 'src/modules/authentication/storage/token/storage';
import { getServerURL } from 'src/modules/debug/utils';
import userPreferencesStorage from 'src/modules/preferences/storage';
import { isWeb } from 'src/utils/platform';
import { getCachedDeviceInfo } from '../device';
import graphQLCache from './cache';
const httpLink = createHttpLink({
    uri: `${getServerURL()}/graphql`,
});
const authLink = setContext((_, { headers }) => {
    const token = tokenStorage.getToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});
const studioClientIdentificationLink = setContext(async (_, context) => {
    const deviceInfo = await getCachedDeviceInfo();
    return {
        ...context,
        headers: {
            ...context.headers,
            'apollographql-client-name': deviceInfo.osType,
            'apollographql-client-version': [
                deviceInfo.appVersion,
                deviceInfo.codePushVersion,
            ]
                .filter(Boolean)
                .join('_'),
        },
    };
});
const handleInvalidTokenLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError && networkError.statusCode === 401) {
        console.log('[LOGOUT] Received Response Status 401');
        appEvents.onLogout();
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        const unauthenticated = graphQLErrors.some(gqlError => gqlError.extensions.code === 'UNAUTHENTICATED');
        if (unauthenticated) {
            console.log('[LOGOUT] Error: UNAUTHENTICATED');
            appEvents.onLogout();
        }
    }
});
const updateTokenLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext();
        const { response: { headers }, } = context;
        if (headers) {
            const newToken = headers.get('new-token');
            if (newToken) {
                tokenStorage.setToken(newToken);
            }
        }
        return response;
    });
});
const resLoggerLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(result => {
        if (isWeb) {
            return result;
        }
        const operationType = operation.query.definitions[0].operation;
        console.log('-'.repeat(20));
        console.log(`[GraphQL] INIT: ${operationType} ${operation.operationName}`);
        console.log(`[GraphQL] VARIABLES: ${JSON.stringify(operation.variables)}`);
        // console.log(`[GraphQL] RESULT: ${JSON.stringify(result)}`);
        console.log('-'.repeat(20));
        return result;
    });
});
const errLoggerLink = onError(({ networkError, graphQLErrors }) => {
    if (isWeb) {
        return;
    }
    if (networkError) {
        console.log(`[GraphQL Error] Network Error: ${networkError.name}, ${networkError.message}`);
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        graphQLErrors.forEach(error => {
            console.log(`[GraphQL Error] GraphQL Error: ${JSON.stringify(error, null, 2)}`);
        });
    }
});
const acceptLanguageLink = setContext((_, context) => {
    const lang = userPreferencesStorage.getPrefs().lang;
    if (!lang) {
        return context;
    }
    return {
        ...context,
        headers: {
            ...context.headers,
            'accept-language': lang,
        },
    };
});
const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: 2000,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf(error) {
            return (error?.statusCode ?? 500) >= 500;
        },
    },
});
export const graphqlClient = new ApolloClient({
    link: from([
        __DEV__ && resLoggerLink,
        __DEV__ && errLoggerLink,
        authLink,
        studioClientIdentificationLink,
        updateTokenLink,
        handleInvalidTokenLink,
        acceptLanguageLink,
        retryLink,
        httpLink,
    ].filter(Boolean)),
    cache: graphQLCache,
});
