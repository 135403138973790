import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import { useCommunityAddAnswerMutation } from 'src/modules/community/graphql/answer/add';
import AddAnswerSuccessDrawer from '../AddSuccessDrawer';
export default function useData() {
    const [addAnswerMutationState, addAnswerMutationActions] = useCommunityAddAnswerMutation({
        onCompleted() {
            helperViewActions.bottomDrawer.show({
                id: 'add_answer_success',
                duration: 5000,
                component: (React.createElement(AddAnswerSuccessDrawer, { onConfirm: helperViewActions.bottomDrawer.hide })),
            });
        },
    });
    const state = {
        addAnswerMutation: addAnswerMutationState,
    };
    const actions = {
        addAnswerMutation: addAnswerMutationActions,
    };
    return [state, actions];
}
