import React from 'react';
import helperViewActions from 'src/components/20-helper-views/helperViewActions';
import BlockUserDrawer from '../components/BlockUserDrawer';
import { useBlockUserMutation } from '../graphql/blockUser';
export default function useBlockUser() {
    const [blockUserMutationState, blockUserMutationActions] = useBlockUserMutation();
    const block = (userID) => {
        helperViewActions.bottomDrawer.show({
            component: (React.createElement(BlockUserDrawer, { onConfirm: () => {
                    helperViewActions.bottomDrawer.hide();
                    blockUserMutationActions.submit({
                        variables: {
                            userID,
                        },
                    });
                } })),
        });
    };
    return [blockUserMutationState, { block }];
}
