import { appleAuth } from '@invertase/react-native-apple-authentication';
import { useCallback, useEffect } from 'react';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { getDeviceInfo } from 'src/lib/device';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { useAppleSigninAPI } from '../apis/signin';
export default function useAppleSignin(events = {}) {
    const { translate } = useI18n();
    const [appleSigninAPIState, appleSigninAPIActions] = useAppleSigninAPI(events);
    useEffect(() => {
        if (appleSigninAPIState.error) {
            snackbarHelperActions.show({
                message: appleSigninAPIState.error.code === 'ERR_BAD_RESPONSE'
                    ? translate('Network connection failure. Please try later')
                    : appleSigninAPIState.error.message ||
                        translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        }
    }, [appleSigninAPIState.error]);
    const handleAppleLogin = useCallback(async () => {
        try {
            // performs login request
            const appleAuthRes = await appleAuth.performRequest({
                requestedOperation: appleAuth.Operation.LOGIN,
                requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
            });
            // /!\ This method must be tested on a real device. On the iOS simulator it always throws an error.
            const credentialState = await appleAuth.getCredentialStateForUser(appleAuthRes.user);
            // use credentialState response to ensure the user is authenticated
            if (credentialState === appleAuth.State.AUTHORIZED) {
                // user is authenticated
                console.log('User is Authenticated');
                appleSigninAPIActions.submit({
                    idToken: appleAuthRes.identityToken,
                    nonce: appleAuthRes.nonce,
                }, {
                    firstName: appleAuthRes.fullName?.givenName || 'Mariner',
                    lastName: appleAuthRes.fullName?.familyName || '',
                    email: appleAuthRes.email,
                }, await getDeviceInfo());
            }
            else {
                snackbarHelperActions.show({
                    message: translate('User was not authenticated by Apple. Please try other Sigin options'),
                    variant: 'NEGATIVE',
                });
            }
        }
        catch (error) {
            console.log('error:', error);
            snackbarHelperActions.show({
                message: error?.message || translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        }
    }, [appleSigninAPIActions]);
    const state = {
        appleSigninAPI: appleSigninAPIState,
    };
    const actions = {
        signinWithApple: handleAppleLogin,
    };
    return [state, actions];
}
