import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import AnswerLoader from '../../components/answer/Loader';
import QuestionListLoader from '../../components/question/list/Loader';
const QuestionDetailsLoader = ({ ...columnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Column, { spacing: theme.spacing.standard.lg, ...columnProps },
        React.createElement(QuestionListLoader, { count: 1, loaderItemHeight: 150 }),
        React.createElement(AnswerLoader, null)));
};
export default QuestionDetailsLoader;
