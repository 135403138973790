// @ts-ignore
import iconFont from 'react-native-vector-icons/Fonts/Feather.ttf';
export default function setupBaseStyle() {
    const iconFontStyles = `
@font-face {
  src: url(${iconFont});
  font-family: Feather;
}
`;
    const mobileOrientationLockScreen = `
@media screen and (orientation: landscape) {
  .mobile-landscape-orientation {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
    padding-top: 20%;
  }

  #root {
    display: none;
  }
}
`;
    // Create a stylesheet
    const style = document.createElement('style');
    style.type = 'text/css';
    // Append the iconFontStyles to the stylesheet
    if (style.styleSheet) {
        style.styleSheet.cssText = iconFontStyles;
        if (window.innerWidth < 600) {
            style.styleSheet.cssText += mobileOrientationLockScreen;
        }
    }
    else {
        style.appendChild(document.createTextNode(iconFontStyles));
        if (window.innerWidth < 600) {
            style.appendChild(document.createTextNode(mobileOrientationLockScreen));
        }
    }
    // Inject the stylesheet into the document head
    document.head.appendChild(style);
}
