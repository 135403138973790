import { isWeb } from 'src/utils/platform';
import debugSettingsStorage from './storage/settings';
import { IDebugEnv } from './types';
import config from 'react-native-ultimate-config';
const debugSettings = debugSettingsStorage.getSettings();
export const getServerURL = (() => {
    const serverURLs = {
        [IDebugEnv.DEV]: 'DEV_SERVER_URL',
        [IDebugEnv.PRE_PROD]: 'PRE_PROD_SERVER_URL',
        [IDebugEnv.PROD]: 'PROD_SERVER_URL',
    };
    return () => {
        if (isWeb) {
            return '';
        }
        const env = __DEV__ ? debugSettings.env : IDebugEnv.PROD;
        return config[serverURLs[env]];
    };
})();
