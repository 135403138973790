import React, { useMemo } from 'react';
import { highlightContext } from '.';
const HighlightProvider = ({ entityType = 'unknown', entityID = 'unknown', onHighlightRef = () => { }, children, }) => {
    const value = useMemo(() => ({
        entityID,
        entityType,
        onHighlightRef,
    }), [entityID, entityType, onHighlightRef]);
    return (React.createElement(highlightContext.Provider, { value: value }, children));
};
export default HighlightProvider;
